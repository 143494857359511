import React from "react";
import generatedGitInfo from "../../generatedGitInfo.json";

const Debug = () => {
  return (
    <div className="git-info">
      <p>Git Branch: {generatedGitInfo.gitBranch}</p>
      <p>Git Commit Hash: {generatedGitInfo.gitCommitHash}</p>
    </div>
  );
};

export default Debug;
