import React, { useRef, useEffect, useState } from "react";
import { distanceInWords } from "date-fns";
import { sendMessage } from "../../services/messages";
import { useForm } from "react-hook-form";

import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";

const DirectMessageReply = ({ m, refreshMessages = () => {} }) => {
  const [disabled, setDisabled] = useState(true);

  const { handleSubmit, watch, register, unregister, errors, reset } = useForm({
    mode: "onBlur"
  });

  const directMessageInput = watch("message");

  useEffect(() => {
    setDisabled(true);
    if (directMessageInput !== undefined && directMessageInput.length > 0) {
      setDisabled(false);
    }
  }, [directMessageInput]);

  const submitMessage = async data => {
    setDisabled(true);
    const { message } = data;

    await sendMessage(m.packetId, message);
    reset();
    refreshMessages();
  };

  return (
    <form
      className="DirectMessageFormInputContainer"
      onSubmit={handleSubmit(submitMessage)}
    >
      <div className="DirectMessageHeaderInputWrapper">
        <FormInput
          name="message"
          type="textarea"
          register={register}
          unregister={unregister}
          required
          error={errors.message}
          placeholder="Reply directly"
          style={{ resize: "none", width: "19em", height: "2.5em" }}
        />
        <div className="SendMessageHeaderButton">
          <Button disabled={disabled} size="small">
            Send
          </Button>
        </div>
      </div>
    </form>
  );
};

const DirectMessagesMenu = ({
  setShowMessages = () => {},
  messages,
  redirectToPacket = () => {},
  refreshMessages = () => {}
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = e => {
      if (!ref || !ref.current || ref.current.contains(e.target)) {
        return;
      }

      setShowMessages(false);
    };

    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [setShowMessages]);

  const onPacketClick = m => () => {
    if (m.seenOn || m.userId) {
      redirectToPacket(m.packetId)();
    }
  };

  return (
    <div
      className="HeaderMessageWrapper"
      ref={ref}
      data-testid="DirectMessageMenu"
    >
      {messages.length === 0 && (
        <div className="HeaderMessageNoMessages">
          You do not have any recent direct messages.
        </div>
      )}
      {messages.map(m => (
        <div
          className={`HeaderMessageContainer${
            (m.seenOn && m.workerId) || m.userId
              ? " HeaderMessageSeen"
              : " HeaderMessageUnseen"
          }${m.userId ? " HeaderMessageReplied" : ""}`}
          onClick={onPacketClick(m)}
          key={m.directMessageId}
        >
          {!m.seenOn && m.workerId && (
            <div
              className="HeaderMessageTitle"
              onClick={redirectToPacket(m.packetId)}
            >
              #{m.claimNumber}
            </div>
          )}
          {(m.seenOn || m.userId) && (
            <div className="HeaderMessageTitle">#{m.claimNumber}</div>
          )}

          <div className="HeaderMessageTime">
            {distanceInWords(new Date(), new Date(m.createdAt), {
              addSuffix: true
            })}
          </div>

          <div
            className={`HeaderMessage ${
              !m.workerId ? "HeaderMessageUserReply" : ""
            }`}
          >
            <span>
              {m.workerId && ""}
              {m.name && `${m.name}:`}
            </span>{" "}
            {m.message}
          </div>
          {m.userId && <i className="fas fa-reply" />}
          {!m.seenOn && m.workerId && (
            <i className="fas fa-exclamation">
              <div className="NewMessageAlert">NEW</div>
            </i>
          )}

          {!m.seenOn && m.workerId && (
            <DirectMessageReply m={m} refreshMessages={refreshMessages} />
          )}
        </div>
      ))}
    </div>
  );
};

export default DirectMessagesMenu;
