import React, { useState, useEffect, useRef } from "react";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import { useForm } from "react-hook-form";

import format from "date-fns/format";

import "./DirectMessageForm.css";

import {
  sendMessage,
  setMessageAsSeen,
  getClaimMessages,
  sendMessageXapi,
  getClaimMessagesXapi
} from "../../services/messages";

const moveScrollToBottom = ({ element }) => {
  element.current.scrollTop = element.current.scrollHeight;
};

function DirectMessageForm({ packet = {}, token = null }) {
  const [disabled, setDisabled] = useState(true);
  const [dms, setDMs] = useState([]);
  const [lastLength, setLastLength] = useState(0);
  const [sending, setSending] = useState(false);
  const scrollRef = useRef(null);

  const { watch, register, unregister, errors, reset } = useForm({
    mode: "onBlur"
  });

  const directMessageInput = watch("message");

  useEffect(() => {
    let time = null;

    const getMessages = async date => {
      let data;

      if (token) {
        data = await getClaimMessagesXapi(packet.claimId, token);
      } else {
        data = await getClaimMessages(packet.packetId, date);
      }

      time = new Date();

      for (let m of data.messages) {
        if (!m.seenOn && m.workerId) {
          await setMessageAsSeen(m.directMessageId);
        }
      }

      return data.messages;
    };

    (async function() {
      const messages = await getMessages();
      setDMs(messages);
    })();

    const interval = setInterval(() => {
      // if the tab is currently active, otherwise don't bother
      if (document && !document.hidden) {
        (async function() {
          const messages = await getMessages(time);
          setDMs(m => m.concat(messages));
        })();
      }
    }, 5 * 60 * 1000); // once every five minutes

    return () => {
      clearInterval(interval);
    };
  }, [packet.claimId, packet.packetId, token]);

  useEffect(() => {
    if (dms.length !== lastLength) {
      moveScrollToBottom({ element: scrollRef });
      setLastLength(dms.length);
    }
  }, [dms, lastLength]);

  useEffect(() => {
    setDisabled(true);
    if (directMessageInput !== undefined && directMessageInput.length > 0) {
      setDisabled(false);
    }
  }, [directMessageInput]);

  const submitMessage = async () => {
    setDisabled(true);
    setSending(true);

    if (token) {
      await sendMessageXapi(packet.claimId, directMessageInput, token);
    } else {
      await sendMessage(packet.packetId, directMessageInput);
    }

    let updatedMessages;

    if (token) {
      updatedMessages = await getClaimMessagesXapi(packet.claimId, token);
    } else {
      updatedMessages = await getClaimMessages(packet.packetId);
    }
    setDMs(updatedMessages.messages);
    reset();
    setSending(false);
  };

  return (
    <div className="DirectMessageFormContainer">
      {sending && <div className="DirectMessageSaving">Sending message...</div>}

      <div className="DirectMessageContainer" ref={scrollRef}>
        <div className="DirectMessageWrapper DirectMessageWorker">
          <p className="DirectMessage">
            Have a question related to this claim? Send us a message!
            <br />
            <br />
            Don’t forget about the event log, as we frequently update the log
            with order statuses.
          </p>
          <div className="DirectMessageInfo">
            <div className="DirectMessageSent">
              {format(new Date(packet.createdAt), "MM/DD/YY hh:mma")}
            </div>
          </div>
        </div>
        {dms.map((message, i, arr) => (
          <div
            className={`DirectMessageWrapper${
              message.workerId ? " DirectMessageWorker" : " DirectMessageUser"
            }`}
            key={i}
          >
            {message.userId && i === arr.length - 1 && (
              <div className="DirectMessageInfo">
                <div className="ReadReceipt DirectMessageResponseTime">
                  Estimated response time: 5 business hours
                </div>
              </div>
            )}
            <p className="DirectMessage">{message.message}</p>
            {message.documents && (
              <div className="DirectMessageDocumentsSent">Documents sent!</div>
            )}

            <div className="DirectMessageInfo">
              <div className="DirectMessageSentAndRead">
                <div className="DirectMessageSent">
                  Delivered{" "}
                  {format(new Date(message.createdAt), "MM/DD/YY hh:mma")}
                </div>
                {message.userId && message.seenOn && (
                  <div className="ReadReceipt">
                    Seen {format(new Date(message.seenOn), "MM/DD/YY hh:mma")}
                  </div>
                )}
              </div>
              <div className="DirectMessageName">
                {message.workerId ? "LossExpress" : message.name}
              </div>
            </div>
          </div>
        ))}
      </div>

      {packet && !packet.archived && (
        <form className="DirectMessageFormInputContainer">
          <FormInput
            name="message"
            type="textarea"
            register={register}
            unregister={unregister}
            required
            error={errors.message}
            placeholder="Input text"
            style={{ resize: "vertical" }}
          />

          <div className="DMButtons">
            <Button size="small" disabled={disabled} onClick={submitMessage}>
              Send
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

export default DirectMessageForm;
