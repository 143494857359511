import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";

const CornerSupport = () => {
  const history = useHistory();

  useEffect(() => {
    // This check is what prevents Intercom from appearing on the iframe page.
    if (!window.location.href.includes("direct-messages")) {
      window.Intercom("boot", {
        app_id: "qvbqfp8s"
      });
    }
  }, []);

  useEffect(() => {
    window.Intercom("update");
  }, [history]);

  return <div />;
};

export default withRouter(CornerSupport);
