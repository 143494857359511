import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Sentry from "@sentry/browser";
import queryString from "query-string";

import "./Login.css";

import { FormInput, Button } from "@fastlane-llc/lossexpress-ui-kit";
import Error from "../Error";
import Success from "../Success";

import { login, validSessionCheck } from "../../services/auth-request";
import { getVendorsAuthInfo } from "../../services/vendors";
import { persistRoleToLS } from "../../services/local-storage";
import Footer from "../Footer/Footer";
import XLienSideWrapper from "../XLienSideWrapper";
import { formatSubdomain } from "../Layout/Layout";

const Login = ({ location }) => {
  const [error, setError] = useState(false);
  const [loginDisabled, setLoginDisabled] = useState(true);
  const [redirect, setRedirect] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [invalidSessionError, setInvalidSessionError] = useState(false);
  const [displayName, setDisplayName] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const { handleSubmit, register, watch, errors } = useForm({
    mode: "onBlur"
  });

  const { username, password } = watch();

  useEffect(() => {
    if (location.state && location.state.successMessage) {
      setSuccessMessage(location.state.successMessage);
    }

    if (location.search) {
      const query = queryString.parse(location.search);

      if (query.issue && query.issue === "invalid_session") {
        setInvalidSessionError(true);
      }
    }

    (async function() {
      try {
        await validSessionCheck();

        setRedirect("/home");
      } catch (ex) {
        const subDomain = formatSubdomain();
        const auth = await getVendorsAuthInfo(subDomain);
        if (auth.displayName) {
          setDisplayName(auth.displayName);
        }

        if (auth.auth === "sso-saml") {
          window.location.href = auth.redirectUrl;
        }
      }
    })();
  }, [location]);

  useEffect(() => {
    if (
      username !== null &&
      username !== "" &&
      !errors.username &&
      password !== null &&
      password !== "" &&
      !errors.password
    ) {
      setLoginDisabled(false);
    } else {
      setLoginDisabled(true);
    }
  }, [username, password, errors]);

  useEffect(() => {
    if (submitting) {
      setLoginDisabled(true);
    }
  }, [submitting]);

  const submitLogin = async (data, e) => {
    try {
      setSubmitting(true);
      const token = await login(data.username, data.password);
      persistRoleToLS(token.scope[0]);

      if (token.passwordResetRequired) {
        window.location.href = `${token.domain}/password-change-required`;
      } else {
        window.location.href = `${token.domain}${
          location.state && location.state.redirectPath
            ? location.state.redirectPath
            : "/home"
        }`;
      }
    } catch (ex) {
      Sentry.captureException(ex);
      setError(true);
    }
    setSubmitting(false);
  };

  return (
    <XLienSideWrapper>
      <div>
        {redirect && <Redirect to={redirect} />}

        <div className="LoginBox">
          {displayName && <div className="LoginDisplayName">{displayName}</div>}

          {error && (
            <Error>
              Username and password combination does not match our records.
            </Error>
          )}
          {invalidSessionError && (
            <Error>
              Your session appears to currently be invalid. Please sign in again
              to continue.
            </Error>
          )}

          {successMessage && <Success>{successMessage}</Success>}
          <div className="LoginTitle">{"Sign In"}</div>
          <form
            onSubmit={handleSubmit(submitLogin)}
            data-testid={"LoginUserForm"}
          >
            <FormInput
              name="username"
              register={register}
              placeholder="Username"
            />

            <FormInput
              name="password"
              type="password"
              register={register}
              placeholder="Password"
              error={errors.password}
            />

            <Button size="full-width" disabled={loginDisabled}>
              Submit
            </Button>
          </form>

          <div className="LoginSupport">
            <div className="LoginSupportLeft">
              <Link to="/forgot-password">Forgot Password?</Link>{" "}
            </div>

            <div className="LoginSupportRight">
              <Link to="/forgot-username">Forgot Username?</Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </XLienSideWrapper>
  );
};

export default Login;
