import React, { useState } from "react";
import Layout from "../Layout";
import { Redirect } from "react-router-dom";
import OrdersTable from "./OrdersTable";
import "./Dashboard.css";

const Dashboard = () => {
  const [packetRedirect, setPacketRedirect] = useState(null);

  const redirectToPacket = packetId => {
    setPacketRedirect(packetId);
  };

  return (
    <Layout>
      <div className="Dashboard__Wrapper">
        {packetRedirect && <Redirect push to={`/claims/${packetRedirect}`} />}
        <OrdersTable redirectToPacket={redirectToPacket} />
      </div>
    </Layout>
  );
};

export default Dashboard;
