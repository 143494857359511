import React, { useState, useEffect } from "react";

import FileReaderInput from "react-file-reader-input";
import Papa from "papaparse";

import { Button } from "@fastlane-llc/lossexpress-ui-kit";
import Modal from "../../../Modal";

import { addBatchUsers, getAccountInfo } from "../../../../services/users";
import { formatPhoneNumber } from "../../../../services/formatting";

import "./AddBatchUserModal.css";

function AddBatchUserModal({ onClose, setError, setSuccess, carrier }) {
  const [file, setFile] = useState(null);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [requestingId, setRequestingId] = useState(null);

  const handleFile = (e, results) => {
    setFile(results[0][1]);

    Papa.parse(results[0][1], {
      complete: ({ data }) => {
        setUsersToAdd(data.filter(d => d[0] !== ""));
      }
    });
  };

  const addUsers = async () => {
    setProcessing(true);
    try {
      await addBatchUsers(usersToAdd, requestingId);
      setError("");
      setSuccess("Successfully added users!");
    } catch (ex) {
      setSuccess("");
      setError("There was an error adding multiple users.");
    }

    setUsersToAdd([]);
    setFile(null);

    onClose();
  };

  useEffect(() => {
    (async function() {
      const r = await getAccountInfo();
      setRequestingId(r.requestingCompanyId);
    })();
  }, []);

  const modalStyle = usersToAdd.length > 0 ? { width: "60em" } : null;

  return (
    <Modal style={modalStyle} onClose={onClose}>
      {!file && (
        <div>
          <h3>Create Users</h3>
          <p>
            To upload a collection of users, create a CSV that contains the
            columns in the order below. You can optionally add a specific
            remittance address for each user if your process requires it.
          </p>
          <p></p>
          <p>
            Name, Email Address, Phone Number, User Role (set as User or
            Administrator), Remittance Street Address, City, State, Zip Code,
            Additional Remittance Info (Suite, etc)
          </p>
          <p>This system assumes there are no headers in the CSV.</p>

          <div data-testid="FileReaderInput">
            <FileReaderInput onChange={handleFile}>
              <Button type="inverse">Select a User CSV File</Button>
            </FileReaderInput>
          </div>
        </div>
      )}

      {usersToAdd.length > 0 && (
        <div>
          <h3>Review Users to Add</h3>
          <div className="ModalOverflow">
            <table className="ReviewUsersTable" cellSpacing="0">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email Address</th>
                  <th>Phone Number</th>
                  <th>User Role</th>
                  <th>Street Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip Code</th>
                  <th>Add'l Remittance Info (Suite, etc)</th>
                </tr>
              </thead>
              <tbody>
                {usersToAdd.map((user, i) => (
                  <tr key={i}>
                    <td>{user[0]}</td>
                    <td>{user[1]}</td>
                    <td>{formatPhoneNumber(`${user[2]}`)}</td>
                    <td>{user[3]}</td>
                    <td>{user[4]}</td>
                    <td>{user[5]}</td>
                    <td>{user[6]}</td>
                    <td>{user[7]}</td>
                    <td>{user[8]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <Button onClick={addUsers} disabled={processing}>
            Add Users
          </Button>
        </div>
      )}
    </Modal>
  );
}

export default AddBatchUserModal;
