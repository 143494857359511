import React, { useState } from "react";
import OtpInput from "react-otp-input";

import "./OTPForm.css";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";
import { resendOTPEmail, submitOtp } from "../../../services/otp";
import { Redirect } from "react-router-dom";
import PayoffPortal from "../../PayoffPortal";

const OTPForm = ({ packetId }) => {
  const [packet, setPacket] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [otp, setOtp] = useState("");
  const handleResendEmail = async () => {
    setButtonDisabled(true);
    await resendOTPEmail(packetId)
      .then(() => {
        setEmailSent(true);
      })
      .catch(() => {
        setEmailSent(true);
        setButtonDisabled(false);
      });
  };

  const handleOtpSubmit = async () => {
    setButtonDisabled(true);
    await submitOtp(otp, packetId)
      .then(packet => {
        setPacket(packet);
      })
      .catch(() => {
        setInvalidOtp(true);
        setButtonDisabled(false);
      });
  };

  return (
    <>
      {!emailSent ? (
        !invalidOtp ? (
          <div className={"otpFormContainer"}>
            {!packet ? (
              <div>
                <h1 className={"otpHeader"}>Lienholder Verification</h1>
                <p className={"otpInstructions"}>
                  Please enter the code that was sent to your email
                </p>
                {packet && (
                  <Redirect to={`/otp-payoff-form/${packet.packetId}`}>
                    {" "}
                  </Redirect>
                )}
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={props => <input {...props} />}
                  inputStyle={"otpInputStyle"}
                  shouldAutoFocus
                  inputType={"number"}
                />
                <Button
                  className={"otpSubmit"}
                  onClick={handleOtpSubmit}
                  disabled={buttonDisabled}
                >
                  Verify
                </Button>
                <div className={"resendOTPEmail"} onClick={handleResendEmail}>
                  Resend Email
                </div>
              </div>
            ) : (
              <PayoffPortal packet={packet} />
            )}{" "}
          </div>
        ) : (
          <>
            <div className={"otpInstructions"}>
              {" "}
              This Code Appears to have expired, or was incorrect. Please
              request another code by pressing the button below.
            </div>
            <Button
              className={"otpSubmit"}
              onClick={handleResendEmail}
              disabled={buttonDisabled}
            >
              Resend Email
            </Button>
          </>
        )
      ) : (
        <div>
          <h1>Thank you.</h1>
          <span>
            If an email can be sent to the lender, then one will be sent. You
            can now close this page.
          </span>
        </div>
      )}
    </>
  );
};

export default OTPForm;
