import React, { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import { validate } from "uuid";
import "./OTPLogin.css";
import XLienSideWrapper from "../XLienSideWrapper";
import Footer from "../Footer";
import OTPForm from "./OTPForm";

const OTPLogin = () => {
  // const [packet, setPacket] = useState({});
  const [badGUIDRedirect, setBadGUIDRedirect] = useState(false);
  const { packetId } = useParams();

  useEffect(() => {
    if (!validate(packetId)) {
      setBadGUIDRedirect(true);
    }
  }, [packetId]);

  return (
    <XLienSideWrapper>
      {badGUIDRedirect && <Redirect to={"/"} />}
      <OTPForm packetId={packetId}></OTPForm>
      <Footer />
    </XLienSideWrapper>
  );
};

export default OTPLogin;
