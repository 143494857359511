import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FormInput, SearchSelect } from "@fastlane-llc/lossexpress-ui-kit";
import { distanceInWords, format } from "date-fns";
import { useForm } from "react-hook-form";
import * as Sentry from "@sentry/browser";

import { styles } from "../../ClaimView/IconStyles";
import "./OrdersTable.css";

import useDebounce from "../../../hooks/use-debounce";
import ModifyOrderModal from "../../ClaimView/ClaimOrdersTable/ModifyOrderModal";

import { formatDateGMT, formatTimeGMT } from "../../../services/dates";
import { fetchCarriers } from "../../../services/local-storage";
import {
  createLoGRequest,
  createOrderRequest,
  fetchPacket,
  runOrdersSearch,
  createPayoffQuote
} from "../../../services/packets";
import { fetchCurrentUsersList, getAccountInfo } from "../../../services/users";
import { formatStringFirstUpperRestLower } from "../../../services/formatting";

const getAttemptLimit = order => {
  return order?.attemptLimit ?? order?.globalAttemptLimit;
};
const isAtLimit = order => {
  const attemptLimit = getAttemptLimit(order);
  return order?.attemptCount === attemptLimit;
};

const formatOrderStatus = (order, inactiveOrder = false) => {
  if (isAtLimit(order) && order?.type !== "Payoff (Loss Initiation)") {
    if (!inactiveOrder) {
      return "Re-Order";
    }

    return "Cancelled";
  }

  if (order?.attemptCount > 0 && order?.status !== "cancelled") {
    return `Attempted (${getAttemptLimit(order) - order.attemptCount} Left)`;
  }

  switch (order.status) {
    case "pending":
      return "Processing";
    case "fulfilled":
      return "Received";
    case "cancelled":
      if (inactiveOrder) {
        return "Cancelled";
      }

      return "Re-Order";
    default:
      return order.status;
  }
};

const findUserCarrier = (carriers, userRequestingCompanyId) => {
  const FIRST_CARRIER = 0;

  // If only one carrier is provided & requestingCompanyId is not present
  // populate the correc userRequestingCompanyId
  if (carriers?.length === 1 && !carriers[FIRST_CARRIER]?.requestingCompanyId) {
    return {
      ...carriers[FIRST_CARRIER],
      requestingCompanyId: userRequestingCompanyId
    };
  }

  // Find the correct user's carrier by matching requestingCompanyId
  const candidate = carriers.find(
    c => c.requestingCompanyId === userRequestingCompanyId
  );

  // Could not find a matching carrier for the user.
  // Better solution?
  if (
    !candidate &&
    process.env.REACT_APP_ENV_NAME !== "production" &&
    process.env.REACT_APP_ENV_NAME !== "canada"
  ) {
    return {
      inactiveOrders: [],
      requestingCompanyId: userRequestingCompanyId
    };
  }

  // correct carrier information
  return candidate;
};

const statusOptions = [
  { label: "Pending", value: "pending" },
  { label: "Fulfilled", value: "fulfilled" },
  { label: "Cancelled", value: "cancelled" },
  { label: "Archived", value: "archived" }
].sort((a, b) => a.value.localeCompare(b.value));

const immutablySetState = (setterFunc, props) => {
  setterFunc(prevState => {
    return { ...prevState, ...props };
  });
};

const typeOptions = [
  { label: "Payoff (Loss Initiation)", value: "Payoff (Loss Initiation)" },
  { label: "Letter of Guarantee", value: "Letter of Guarantee" },
  { label: "Title Image", value: "Copy of Title" },
  { label: "Title Status", value: "Title Status" },
  { label: "Lien Release Letter", value: "Lien Release Letter" },
  { label: "Installment Contract", value: "Installment Contract" },
  { label: "Bill of Sale", value: "Bill of Sale" },
  { label: "Payment History", value: "Payment History" },
  { label: "Repo Affidavit", value: "Repo Affidavit" },
  { label: "One and the Same Letter", value: "One and the Same Letter" },
  { label: "Payment Status", value: "Payment Status" },
  { label: "Contact DMV", value: "Contact DMV" },
  { label: "Missing Options", value: "Missing Options" }
];

export const formatColumn = (value, column) => {
  if (column === "updatedAt") {
    return distanceInWords(new Date(), new Date(value), {
      addSuffix: true
    });
  }

  if (column === "createdAt") {
    return format(value, "MM/DD/YYYY");
  }

  if (column === "status") {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  return value;
};

const OrdersTable = ({ redirectToPacket }) => {
  const [activeColumns] = useState([
    {
      name: "Order",
      key: "type",
      value: true
    },
    {
      name: "VIN",
      key: "vin",
      value: true
    },
    {
      name: "Status",
      key: "status",
      value: true
    },
    {
      name: "Customer Name",
      key: "ownersName",
      value: true
    },
    {
      name: "Year/Make/Model",
      key: "yearMakeModel",
      value: true
    },
    {
      name: "Lien Holder",
      key: "lenderName",
      value: true
    },
    {
      name: "Assigned To",
      key: "assignedTo",
      value: true
    }
  ]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [reorder, setReorder] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [showModifyOrderModal, setShowModifyOrderModal] = useState(false);
  const [modifyOrderComplete, setModifyOrderComplete] = useState(false);
  const [pagination, setPagination] = useState({
    pageNumber: 0,
    pageSize: 10,
    sortBy: "updatedat",
    sortDirection: "desc"
  });
  const [searchResults, setSearchResults] = useState({
    content: [],
    pageNumber: 0,
    pageSize: 10,
    totalPages: 0,
    totalElements: 0
  });
  const [processedOrders, setProcessedOrders] = useState([]);
  const [searchParams, setSearchParams] = useState({
    searchTerm: "",
    userId: null,
    orderStatus: null,
    orderType: null
  });
  const [userFilter, setUserFilter] = useState(null);
  const [users, setUsers] = useState([]);
  const [typeFilter, setTypeFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [packetsWithOrderAttempts, setPacketsWithOrderAttempts] = useState([]);
  const [inactiveOrders, setInactiveOrders] = useState([]);
  const [currentUser, setCurrentUser] = useState({
    userId: ""
  });
  const [currentCarrier, setCurrentCarrier] = useState({
    requestingCompanyId: ""
  });
  const [xlogManualAuth, setXlogManualAuth] = useState(false);

  const { register, watch, errors } = useForm({
    mode: "onChange"
  });

  const searchInput = watch("OrdersSearchInput");
  const debouncedSearchTerm = useDebounce(searchInput?.trim(), 300);

  //******************************************* */
  //
  // handles fetching orders from the api
  //
  //******************************************* */
  const refreshOrders = useCallback(
    async (currentUserId, requestingCompanyId) => {
      try {
        setLoading(true);

        const page = await runOrdersSearch(
          {
            searchTerm: encodeURIComponent(searchParams.searchTerm),
            userId: searchParams.userId || currentUserId,
            orderStatus: searchParams.orderStatus,
            orderType: searchParams.orderType,
            requestingCompanyId
          },
          pagination
        );

        setSearchResults(page);
        setProcessedOrders(page.content);
      } catch (ex) {
        Sentry.captureException(ex);
      }

      setLoading(false);
    },
    [searchParams, pagination]
  );

  //******************************************* */
  //
  // "main" useEffect handles mounting OrdersTable
  // initializing the user + the carrier & only fetches
  // orders if a user exists.
  //
  //******************************************* */
  useEffect(() => {
    (async function main() {
      // Initialize the currently active user &
      // the carrier ingfo
      if (
        currentUser.userId === "" &&
        currentCarrier.requestingCompanyId === ""
      ) {
        const userInfo = await getAccountInfo();
        setCurrentUser(userInfo);

        const carriers = fetchCarriers();

        if (carriers) {
          const userCarrierInfo = findUserCarrier(
            carriers,
            userInfo.requestingCompanyId
          );

          setCurrentCarrier(userCarrierInfo);
          setInactiveOrders(userCarrierInfo.inactiveOrders);
        }
      }

      // Only fetch orders if both a user &
      // a carrier is initialized
      if (
        currentUser.userId !== "" &&
        currentCarrier.requestingCompanyId !== ""
      ) {
        await refreshOrders(
          currentUser.userId,
          currentCarrier.requestingCompanyId
        );
      }
    })();
  }, [refreshOrders, currentUser, currentCarrier]);

  //******************************************* */
  //
  // handles hitting the api by keywork, by updating searchParams
  //
  //******************************************* */
  useEffect(() => {
    const updateSearchParams = () => {
      if (debouncedSearchTerm !== undefined) {
        if (debouncedSearchTerm.length > 2 || debouncedSearchTerm === "") {
          immutablySetState(setSearchParams, {
            searchTerm: debouncedSearchTerm
          });
          immutablySetState(setPagination, {
            pageNumber: 0
          });
        }
      }
    };

    if (searchParams.searchTerm !== debouncedSearchTerm) {
      updateSearchParams();
    }
  }, [searchParams, debouncedSearchTerm]);

  //******************************************* */
  //
  // get user options and set default user
  //
  //******************************************* */
  useEffect(() => {
    (async function() {
      // inject an 'All Users' object at the beginning of the users list
      let newUs = [{ value: "all", label: "All Users" }];
      const us = await fetchCurrentUsersList();
      us.forEach(u => newUs.push({ value: u.userId, label: u.name }));
      setUsers(newUs);

      setUserFilter({ value: currentUser.userId, label: currentUser.name });
    })();
  }, [currentUser]);

  //******************************************* */
  //
  // onClick pagination handler
  //
  //******************************************* */
  const onChangePageNumber = pageNumber => () => {
    immutablySetState(setPagination, {
      pageNumber
    });
  };

  //******************************************* */
  //
  // onClick triggers refreshOrders by updating searchParams state
  // and resets pagination to page 0
  //
  //******************************************* */
  const onUserSelectChange = () => (d, e) => {
    if (e.action === "select-option") {
      setUserFilter(d);
      immutablySetState(setSearchParams, {
        userId: d.value
      });
      immutablySetState(setPagination, {
        pageNumber: 0
      });
    } else if (e.action === "clear") {
      immutablySetState(setSearchParams, {
        userId: currentUser.userId
      });
      immutablySetState(setPagination, {
        pageNumber: 0
      });
    }
  };

  //******************************************* */
  //
  // onClick triggers refreshOrders by updating searchParams state
  // and resets pagination to page 0
  //
  //******************************************* */
  const onTypeSelectChange = () => (d, e) => {
    if (e.action === "select-option") {
      setTypeFilter(d);
      immutablySetState(setSearchParams, {
        orderType: d.value
      });
      immutablySetState(setPagination, {
        pageNumber: 0
      });
    } else if (e.action === "clear") {
      setTypeFilter("reset");
      immutablySetState(setSearchParams, {
        orderType: null
      });
      immutablySetState(setPagination, {
        pageNumber: 0
      });
    }
  };

  //******************************************* */
  //
  // onClick triggers refreshOrders by updating searchParams state
  // and resets pagination to page 0
  //
  //******************************************* */
  const onStatusSelectChange = () => (d, e) => {
    if (e.action === "select-option") {
      setStatusFilter(d);
      immutablySetState(setSearchParams, {
        orderStatus: d.value
      });
      immutablySetState(setPagination, {
        pageNumber: 0
      });
    } else if (e.action === "clear") {
      setStatusFilter(null);
      immutablySetState(setSearchParams, {
        orderStatus: null
      });
      immutablySetState(setPagination, {
        pageNumber: 0
      });
    }
  };

  const onReorder = async (type, packetId) => {
    if (packetId) {
      try {
        await createOrderRequest(packetId, [type]);
        setModifyOrderComplete(true);
        setReorder(false);
        refreshOrders();
      } catch (ex) {
        setError(true);
        Sentry.captureException(ex);
      }
    }
  };

  const requestLoG = async packetId => {
    try {
      await createLoGRequest(packetId);
      setModifyOrderComplete(true);
      setReorder(false);
      refreshOrders();
    } catch (ex) {
      setError(
        "There was an error creating a letter of guarantee request on this claim."
      );
    }
  };

  const refreshPayoffQuote = async packetId => {
    try {
      await createPayoffQuote(packetId, null, true);
      setModifyOrderComplete(true);
      setReorder(false);
      refreshOrders();
    } catch (ex) {
      Sentry.captureException(ex);
      setError(true);
    }
  };

  const openModifyOrderModal = (order, reorder = false) => {
    if (reorder) {
      setReorder(true);
    } else {
      setCancelOrder(true);
    }
    setShowModifyOrderModal(true);
    setSelectedOrder(order);
  };

  const closeModifyOrderModal = () => {
    setShowModifyOrderModal(false);
    setSelectedOrder(null);
    setModifyOrderComplete(false);
    setReorder(false);
    setCancelOrder(false);
  };

  const addPacketWithEventsIfMissing = async packetId => {
    const packet = packetsWithOrderAttempts.find(p => p.packetId === packetId);

    if (!packet) {
      const packet = await fetchPacket(packetId);
      setPacketsWithOrderAttempts(packetsWithOrderAttempts.concat(packet));
    }
  };

  const parseAttemptEvents = order => {
    const packet = packetsWithOrderAttempts.find(
      p => p.packetId === order.packetId
    );
    const unfulfillmentReasons = [];

    if (packet) {
      for (const event of packet?.events) {
        if (order.orderId === event.orderId) {
          const parsedMessage = event.message.split(": ")[1];
          let finalParsedMessage = parsedMessage.split(" encountered")[0];
          if (
            finalParsedMessage ===
            "Exceedingly Long Hold Time (more than 1 hour)"
          ) {
            finalParsedMessage = "Exceedingly Long Hold Time";
          }
          unfulfillmentReasons.push(finalParsedMessage);
        }
      }
    }

    return unfulfillmentReasons.map((reason, i) => (
      <div key={i} className="ClaimOrders__UnfulfillmentReasons">
        {reason}
      </div>
    ));
  };

  const getUnfulfillmentReasons = order => {
    const attemptLimitReached =
      order.globalAttemptLimit &&
      order.globalAttemptLimit === order.attemptCount;
    addPacketWithEventsIfMissing(order.packetId);

    return (
      <>
        {order.status === "cancelled" ? (
          <td>
            <span className="Bold">Cancelled At:</span>
            <div>{formatDateGMT(order.updatedAt)}</div>
            <div>{formatTimeGMT(order.updatedAt)} CST</div>
          </td>
        ) : (
          <td></td>
        )}
        {order.attemptCount > 0 && order.requestedFromLenderAt ? (
          <td>
            <span className="Bold">Last Attempted:</span>
            <div>{formatDateGMT(order.requestedFromLenderAt)}</div>
            <div>{formatTimeGMT(order.requestedFromLenderAt)} CST</div>
          </td>
        ) : (
          <td></td>
        )}
        <td colSpan="75%" className="ClaimOrders__Unfulfillment">
          Unfulfillment Reasons:
          {order.status === "cancelled" && !attemptLimitReached ? (
            <div className="ClaimOrders__UnfulfillmentReasons">
              User cancelled order
            </div>
          ) : (
            parseAttemptEvents(order)
          )}
        </td>
      </>
    );
  };

  const handleRowClick = rowId => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);

    setExpandedRows(newExpandedRows);
  };

  const renderOrder = (order, inactiveOrder = false) => {
    const clickCallback = () => handleRowClick(order.createdAt);
    const orderRows = [
      <tr key={"row-data-" + order.updatedAt + order.type}>
        <td className="OrdersTable__ClaimNumber">
          <span onClick={() => redirectToPacket(order.packetId)}>
            {order.claimNumber}
          </span>
        </td>
        <td>{order.type === "Copy of Title" ? "Title Image" : order.type}</td>
        <td>{order.vin}</td>
        <td
          data-testid={`OrdersStatus-${order.orderId}`}
          className={`OrdersTable__Status_Column${
            order.status === "cancelled" ? " OrdersTable__Cancelled" : ""
          }`}
        >
          {(isAtLimit(order) &&
            order?.type !== "Payoff (Loss Initiation)" &&
            !inactiveOrder) ||
          order?.status === "cancelled" ? (
            <span
              className="ClaimOrders__Reorder"
              onClick={() => openModifyOrderModal(order, true)}
            >
              {order.statusLabel}
            </span>
          ) : order.attemptCount &&
            order.globalAttemptLimit &&
            order.attemptCount > 0 ? (
            <span className="OrdersTable__Attempted">{order.statusLabel}</span>
          ) : (
            order.statusLabel
          )}
        </td>
        <td>{order.ownersName}</td>
        <td>
          {order.year ? `${order.year}/` : ""}
          {order.make ? `${formatStringFirstUpperRestLower(order.make)}/` : ""}
          {order.model ? `${order.model}` : ""}
        </td>
        <td>{order.lenderName}</td>
        <td>{order.assignedTo}</td>
        <td>
          <FontAwesomeIcon
            style={styles.tableChevron}
            icon={faChevronDown}
            onClick={clickCallback}
          />
        </td>
      </tr>
    ];

    if (expandedRows.includes(order.createdAt)) {
      orderRows.push(
        <tr
          className={"ClaimOrders__ExpandedRow"}
          key={"row-expanded-" + order.createdAt}
          data-testid={order.orderId}
        >
          <td>
            <div className="Bold">Created At:</div>{" "}
            <div>{formatDateGMT(order.createdAt)}</div>
            <div>{formatTimeGMT(order.createdAt)} CST</div>
          </td>
          {order.status !== "cancelled" &&
            (!order.attemptCount || order.attemptCount === 0) && (
              <>
                <td>
                  <div>
                    <div className="Bold">Request Date:</div>
                    {order.requestedFromLenderAt ||
                    (order.type === "Payoff (Loss Initiation)" &&
                      order.updatedAt !== order.createdAt) ? (
                      <>
                        <div>
                          {formatDateGMT(
                            order.type === "Payoff (Loss Initiation)" &&
                              order.updatedAt !== order.createdAt
                              ? order.updatedAt
                              : order.requestedFromLenderAt
                          )}
                        </div>
                        <div>
                          {formatTimeGMT(
                            order.type === "Payoff (Loss Initiation)" &&
                              order.updatedAt !== order.createdAt
                              ? order.updatedAt
                              : order.requestedFromLenderAt
                          )}{" "}
                          CST
                        </div>
                      </>
                    ) : (
                      <div>--</div>
                    )}
                  </div>
                </td>
                <td>
                  <div>
                    <div className="Bold">Spoke With:</div>
                    <div>
                      {order.spokeWith
                        ? order.spokeWith === "Automated Request"
                          ? "Representative"
                          : `${order.spokeWith}`
                        : "--"}
                    </div>
                  </div>
                </td>
                {order.status === "fulfilled" ? (
                  <td>
                    <div className="Bold">Received At:</div>
                    <div>
                      {formatDateGMT(
                        order.type === "Payoff (Loss Initiation)"
                          ? order.updatedAt
                          : order.fulfilledAt
                      )}
                    </div>
                    <div>
                      {formatTimeGMT(
                        order.type === "Payoff (Loss Initiation)"
                          ? order.updatedAt
                          : order.fulfilledAt
                      )}{" "}
                      CST
                    </div>
                  </td>
                ) : (
                  <td>
                    <div className="Bold">Est. Delivery Date:</div>{" "}
                    {order.expectedDeliveryDate ? (
                      <>
                        <div>{formatDateGMT(order.expectedDeliveryDate)}</div>
                        <div>
                          {formatTimeGMT(order.expectedDeliveryDate)} CST
                        </div>
                      </>
                    ) : (
                      <div>--</div>
                    )}
                  </td>
                )}
              </>
            )}
          {(order.status === "cancelled" || order.attemptCount > 0) && (
            <>{getUnfulfillmentReasons(order)}</>
          )}
        </tr>
      );
    }

    return orderRows;
  };

  let allOrderRows = [];

  processedOrders
    .map(order => ({
      ...order,
      statusLabel: formatOrderStatus(order, inactiveOrders.includes(order.type))
    }))
    .sort((a, b) => {
      if (a.statusLabel === "Re-Order") {
        return -1;
      }
      return a.statusLabel < b.statusLabel ? -1 : 1;
    })
    .forEach(order => {
      const perOrderRows = renderOrder(
        order,
        inactiveOrders.includes(order.type)
      );
      allOrderRows = allOrderRows.concat(perOrderRows);
    });

  return (
    <div
      className={`OrdersTable__Container ${
        !loading && processedOrders.length === 0 ? "EmptyContainer" : ""
      }`}
    >
      {showModifyOrderModal && (
        <ModifyOrderModal
          order={selectedOrder}
          cancelOrder={cancelOrder}
          reorder={reorder}
          onReorder={onReorder}
          modifyOrderComplete={modifyOrderComplete}
          onClose={closeModifyOrderModal}
          refreshPayoffQuote={refreshPayoffQuote}
          requestLoG={requestLoG}
          packet={fetchPacket(selectedOrder.packetId)}
          xlogManualAuth={xlogManualAuth}
          setXlogManualAuth={setXlogManualAuth}
        />
      )}

      {error && (
        <div className="ClaimOrders__ErrorMessage">
          There was an issue processing your request. Please try again later or
          contact support using the button on the bottom left-hand corner of
          your screen.
        </div>
      )}

      <h1>{`Order Dashboard (${searchResults.totalElements})`}</h1>
      <div className="OrdersTable__SearchWrapper">
        <div className="OrdersTable__SearchInput">
          <FormInput
            name="OrdersSearchInput"
            placeholder="Search Orders"
            register={register}
            type="search"
            error={useDebounce(errors.OrdersSearchInput, 500)}
            validationMessage="Please enter 3 characters!"
            validationRegex={new RegExp(/^.{3,}$/)}
          />
        </div>
        <div className="TypeSelect">
          <SearchSelect
            defaultValue={null}
            placeholder="Filter by Type"
            name="searchUsers"
            onSelect={onTypeSelectChange}
            options={typeOptions}
            value={typeFilter}
            testId={"TypeSelect"}
            clearable
          />
        </div>
        <div className="StatusSelect">
          <SearchSelect
            defaultValue={null}
            placeholder="Filter by Status"
            name="searchUsers"
            onSelect={onStatusSelectChange}
            options={statusOptions}
            value={statusFilter}
            testId={"StatusSelect"}
            clearable
          />
        </div>
        <div className="UserSearch">
          <SearchSelect
            defaultValue={null}
            placeholder="Filter by User"
            name="searchUsers"
            onSelect={onUserSelectChange}
            options={users}
            value={userFilter}
            testId={"UserSearch"}
          />
        </div>
      </div>
      <div className="OrdersTable__Wrapper">
        {processedOrders.length > 0 && !loading && (
          <table cellSpacing={0} className="OrdersTable__Table">
            <thead>
              <tr>
                <th className="OrdersTable__ClaimHeading">Claim Number</th>
                {activeColumns.map((col, i) => (
                  <th key={i}>{col.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>{allOrderRows}</tbody>
          </table>
        )}

        {loading && (
          <div>
            <i className="fas fa-spinner fa-5x" />
          </div>
        )}

        {!loading && searchResults.numberOfElements === 0 && (
          <div className="NoResultsMessage">There are no matching orders.</div>
        )}
        <div className="OrdersTable__PaginationWrapper">
          {searchResults.pageNumber > 0 ? (
            <div
              className="OrdersTable__PaginationLink"
              onClick={onChangePageNumber(searchResults.pageNumber - 1)}
            >
              Previous Orders
            </div>
          ) : (
            <div />
          )}

          {!loading && searchResults.totalPages - searchResults.pageNumber > 1 && (
            <div
              className="OrdersTable__PaginationLink"
              onClick={onChangePageNumber(searchResults.pageNumber + 1)}
            >
              Next Orders
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrdersTable;
