import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/browser";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";
import { orderTypes } from "../OrderingMenu/OrderingMenu";
import {
  updateActiveOrderStatus,
  getCurrentInactiveOrders
} from "../../services/orders";
import "./OrderManagement.css";
import Layout from "../Layout";

import { useLocation } from "react-router-dom";

const OrderManagement = () => {
  const [inactiveOrders, setInactiveOrders] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const { user } = location?.state;

  useEffect(() => {
    (async function() {
      const { inactiveOrders } = await getCurrentInactiveOrders(
        user.requestingCompanyId
      );
      setInactiveOrders(inactiveOrders);
    })();
  }, [user.requestingCompanyId]);

  useEffect(() => {
    let allOrderTypes = orderTypes?.map(order => order.label);
    setActiveOrders(allOrderTypes);

    if (inactiveOrders?.length > 0) {
      for (const item of inactiveOrders) {
        allOrderTypes = allOrderTypes.filter(order => order !== item);
      }
      setActiveOrders(allOrderTypes);
    }
  }, [user, inactiveOrders]);

  const handleActiveOrderStatus = async order => {
    setDisabled(true);
    try {
      const { updatedInactiveOrders } = await updateActiveOrderStatus(
        user.requestingCompanyId,
        order
      );
      setInactiveOrders(updatedInactiveOrders);
      let allOrderTypes = orderTypes?.map(order => order.label);
      for (const item of updatedInactiveOrders) {
        allOrderTypes = allOrderTypes.filter(order => order !== item);
      }
      setActiveOrders(allOrderTypes);
      setDisabled(false);
    } catch (ex) {
      Sentry.captureException(ex);
      setError(
        "An error has occurred. Please contact IT support through the chat window in the bottom left corner of your screen."
      );
      setDisabled(false);
    }
  };

  return (
    <Layout>
      {user.userRole === "System Administrator" && (
        <>
          <h1>Order Management</h1>
          <div className="Red">{error}</div>
          <p>
            Active orders are currently selectable from the orders menu,
            <br /> making an order inactive will disable it from appearing in
            the orders menu.
          </p>
          <h2>Active Orders</h2>
          <div className="OrdersContainer">
            {(activeOrders.length === 0 || activeOrders === null) && (
              <div>All order types are currently inactive</div>
            )}
            {activeOrders.map(order => (
              <div key={order}>
                {order}
                <Button
                  disabled={disabled}
                  size="small"
                  onClick={() => handleActiveOrderStatus(order)}
                >
                  Deactivate order
                </Button>
              </div>
            ))}
          </div>

          <h2>Inactive Orders</h2>
          <div className="OrdersContainer">
            {inactiveOrders?.map(order => (
              <div key={order}>
                {order}
                <Button
                  disabled={disabled}
                  size="small"
                  onClick={() => handleActiveOrderStatus(order)}
                >
                  Reactivate Order
                </Button>
              </div>
            ))}
            {(inactiveOrders?.length === 0 || inactiveOrders === null) && (
              <div>All order types are currently active.</div>
            )}
          </div>
        </>
      )}
      {user.userRole !== "System Administrator" && (
        <p>You do not have permission to view this page.</p>
      )}
    </Layout>
  );
};

export default OrderManagement;
