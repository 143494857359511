import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { removeItemFromLS } from "../../services/local-storage";
import XLienSideWrapper from "../XLienSideWrapper";
import Footer from "../Footer/Footer";

const logoutGifs = [
  "https://media.giphy.com/media/kaBU6pgv0OsPHz2yxy/giphy.gif",
  "https://media.giphy.com/media/12xvz9NssSkaS4/giphy.gif",
  "https://media.giphy.com/media/yidUzqLoT6SxgKzv20/giphy.gif",
  "https://media.giphy.com/media/l2Jhzd3C1nVEoPHb2/giphy.gif",
  "https://media.giphy.com/media/GB0lKzzxIv1te/giphy.gif"
];

const LogoutPage = () => {
  useEffect(() => {
    removeItemFromLS("user");
  }, []);

  return (
    <XLienSideWrapper>
      <div className="LoginTitle">Bye for now!</div>
      <img
        src={logoutGifs[Math.floor(Math.random() * 5 + 1) - 1]}
        className="LogoutGif"
        alt="Goodbye"
      />

      <p>You've been logged out. Have a great day!</p>

      <Link to="/">Click here to log back in.</Link>
      <Footer />
    </XLienSideWrapper>
  );
};

export default LogoutPage;
