import { flFetch as fetch, validateStatus } from "./fetch";

let swRegistration = null;

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

export const registerServiceWorker = async () => {
  if ("serviceWorker" in navigator) {
    swRegistration = await navigator.serviceWorker.register(
      `${process.env.PUBLIC_URL}/assets/push-notifications-sw.js`
    );
  }
};

export const getServiceWorkerRegistration = () => swRegistration;

export const getSubscription = async () => {
  if (swRegistration) {
    return swRegistration.pushManager.getSubscription();
  }
};

export const unsubscribeUser = async () => {
  const subscription = await getSubscription();
  if (!subscription) {
    return;
  }

  await subscription.unsubscribe();

  const resp = await fetch(
    `${process.env.REACT_APP_API_URL}/payoff/push-notifications`,
    {
      method: "DELETE"
    }
  );

  return validateStatus(resp);
};

export const subscribeUser = async () => {
  if ("serviceWorker" in navigator) {
    const serverKey = urlBase64ToUint8Array(
      process.env.REACT_APP_VAPID_PUBLIC_KEY
    );
    const subscription = await getSubscription();
    if (subscription) {
      return;
    }

    try {
      const sub = await swRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: serverKey
      });

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/payoff/push-notifications`,
        {
          method: "POST",
          body: JSON.stringify(sub)
        }
      );

      return validateStatus(res);
    } catch (ex) {
      if (Notification.permission === "denied") {
        console.warn("Permission for notifications was denied");
      } else {
        console.error("Unable to subscribe to push notifications", ex);
      }
    }
  }
};
