import React, { useState, useEffect } from "react";
import { Link, Redirect } from "react-router-dom";

import "./Header.css";

import { Button, Logo } from "@fastlane-llc/lossexpress-ui-kit";

import UserMenu from "../UserMenu";
import DirectMessagesMenu from "./DirectMessagesMenu";
import SearchResultsMenu from "./SearchResultsMenu";
import { getRecentMessages } from "../../services/messages";
import { styles } from "../ClaimView/IconStyles";
import { FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import { searchClaims } from "../../services/packets";
import { useForm } from "react-hook-form";
import useDebounce from "../../hooks/use-debounce";
import * as Sentry from "@sentry/browser";

const formatMessages = messages => {
  const packets = messages.reduce((p, m) => {
    if (p[m.packetId]) {
      if (p[m.packetId].createdAt < m.createdAt) {
        p[m.packetId] = m;
      }
    } else {
      p[m.packetId] = m;
    }

    return p;
  }, {});

  const arr = Object.keys(packets).map(k => packets[k]);
  arr.sort((a, b) => {
    if (a.createdAt < b.createdAt) {
      return 1;
    }

    return -1;
  });

  return arr;
};

const Header = ({
  toggleUserMenu,
  userMenuOpen,
  signOut,
  carrier,
  currentUser = {}
}) => {
  const [messages, setRecentMessages] = useState([]);
  const [lastLength, setLastLength] = useState(0);
  const [redirect, setRedirect] = useState(null);
  const [showMessages, setShowMessages] = useState(false);
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [redirectToDisclaimer, setRedirectToDisclaimer] = useState(false);
  const [redirectToReporting, setRedirectToReporting] = useState(false);
  const [claimsSearch, setClaimsSearch] = useState(null);
  const [populatedClaims, setPopulatedClaims] = useState([]);

  const { register, watch, reset } = useForm({
    mode: "onBlur"
  });

  const searchInput = watch("claimSearch");
  const debouncedSearchTerm = useDebounce(searchInput, 300);

  // Google Translate
  useEffect(() => {
    if (carrier?.showTranslate) {
      const translateElement = document.getElementById(
        "google_translate_element"
      );
      translateElement.style.display = "block";
    }
  }, [carrier]);

  useEffect(() => {
    if (currentUser) {
      (async function() {
        try {
          const data = currentUser;
          if (!data.disclaimerAcknowledged) {
            setRedirectToDisclaimer(true);
          }

          const m = await getRecentMessages();
          setRecentMessages(formatMessages(m.messages));

          let time = new Date();
          let immediateGratificationOnReentry = false;

          const interval = setInterval(() => {
            // if the tab is currently active, otherwise don't bother
            if (
              document &&
              !document.hidden &&
              !immediateGratificationOnReentry
            ) {
              (async function() {
                const messages = await getRecentMessages(time);
                setRecentMessages(ms =>
                  formatMessages(ms.concat(messages.messages))
                );
                time = new Date();
              })();
            } else {
              immediateGratificationOnReentry = true;
            }
          }, 10 * 60 * 1000); // once every ten minutes

          const secondInterval = setInterval(() => {
            if (
              immediateGratificationOnReentry &&
              document &&
              !document.hidden
            ) {
              (async function() {
                const messages = await getRecentMessages(time);
                setRecentMessages(ms =>
                  formatMessages(ms.concat(messages.messages))
                );
                time = new Date();
                immediateGratificationOnReentry = false;
              })();
            }
          }, 1000); // once a second

          return () => {
            clearInterval(interval);
            clearInterval(secondInterval);
          };
        } catch (ex) {
          setRedirectToLogin(true);
        }
      })();
    }
  }, [currentUser]);

  useEffect(() => {
    const len = messages.filter(me => (me.seenOn && !me.userId) || me.userId);
    setLastLength(messages.length - (messages.length - len.length));
  }, [messages]);

  const refreshMessages = async () => {
    const messages = await getRecentMessages(new Date());
    setRecentMessages(ms => formatMessages(ms.concat(messages.messages)));
  };

  const redirectToPacket = packetId => async () => {
    setRedirect(packetId);
    setShowMessages(false);
  };

  const showMessageDiv = () => {
    setShowMessages(!showMessages);
  };

  const redirectToReportingPage = () => {
    setRedirectToReporting(true);
  };

  useEffect(() => {
    const updateClaimsSearch = debouncedSearchTerm => {
      setClaimsSearch(debouncedSearchTerm?.trim());
    };

    if (claimsSearch !== debouncedSearchTerm) {
      let slicedTerm;
      if (debouncedSearchTerm?.startsWith("#")) {
        slicedTerm = debouncedSearchTerm.substring(1);
        updateClaimsSearch(slicedTerm);
      } else {
        updateClaimsSearch(debouncedSearchTerm);
      }
    }

    if (debouncedSearchTerm === "") {
      setPopulatedClaims([]);
    }
  }, [claimsSearch, debouncedSearchTerm]);

  useEffect(() => {
    if (
      claimsSearch !== null &&
      claimsSearch !== undefined &&
      claimsSearch !== "" &&
      claimsSearch?.length > 3
    ) {
      (async function() {
        try {
          const claims = await searchClaims(claimsSearch);
          setPopulatedClaims(claims);
        } catch (ex) {
          Sentry.captureException(ex);
        }
      })();
    }
  }, [claimsSearch]);

  return (
    <div className="HeaderContainer">
      {redirectToLogin && <Redirect to="/login" />}
      {redirectToDisclaimer && <Redirect to="/disclaimer" />}

      {redirect && <Redirect to={`/packet/${redirect}`} push />}
      {redirectToReporting && <Redirect to="/admin/data" />}
      {carrier?.hybridDisplay && (
        <div className="HeaderLogoContainer">
          <Logo type="xlien" />
        </div>
      )}
      {!carrier?.hybridDisplay && (
        <div className="HeaderLogoContainer">
          <Link to="/home">
            <Logo type="xlien" />
          </Link>
        </div>
      )}

      <div className="HeaderCarrier">
        <h3>{currentUser?.carrierDisplayName}</h3>
      </div>
      {!carrier?.hybridDisplay && (
        <div className="SearchContainer">
          <FormInput
            placeholder="Claim search"
            name="claimSearch"
            type="search"
            register={register}
          />
        </div>
      )}
      <div className="HeaderSignout">
        {showMessages && (
          <DirectMessagesMenu
            setShowMessages={setShowMessages}
            messages={messages}
            redirectToPacket={redirectToPacket}
            refreshMessages={refreshMessages}
          />
        )}

        {populatedClaims.length > 0 && (
          <SearchResultsMenu search={populatedClaims} reset={reset} />
        )}

        <div className="HeaderReportingIconWrapper">
          {!carrier?.routeOneOnly && !carrier?.hybridDisplay && (
            <Button
              color="gray"
              invert
              type="icon"
              onClick={showMessageDiv}
              active={showMessages}
              style={showMessages ? styles.directMessageIconActive : null}
            >
              <i
                className="fa fa-comments HeaderUserIcon"
                data-testid="DirectMessageIcon"
              />

              {lastLength !== messages.length && (
                <span className="HeaderNotificationBlob" />
              )}
            </Button>
          )}
        </div>
        {!carrier?.hybridDisplay &&
        (currentUser?.userRole === "Administrator" ||
          currentUser?.userRole === "System Administrator") ? (
          <Button
            color="gray"
            invert
            type="icon"
            onClick={redirectToReportingPage}
          >
            <i
              className="fas fa-chart-bar HeaderUserIcon"
              data-testid="ReportingIcon"
            />
          </Button>
        ) : (
          ""
        )}
        {!carrier?.hybridDisplay && (
          <Button
            color="gray"
            invert
            type="icon"
            onClick={toggleUserMenu}
            active={userMenuOpen}
            style={userMenuOpen ? styles.directMessageIconActive : null}
          >
            <i
              className="fas fa-users-cog HeaderUserIcon"
              data-testid="SettingsIcon"
            />
          </Button>
        )}
        {userMenuOpen && (
          <UserMenu
            open={userMenuOpen}
            signOut={signOut}
            closeMenu={toggleUserMenu}
            currentUser={currentUser}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
