import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCarCrash,
  faCircleNotch,
  faComments,
  faExclamationTriangle,
  faFileDownload,
  faFolderOpen,
  faMoneyCheckAlt
} from "@fortawesome/free-solid-svg-icons";
import { styles } from "../IconStyles";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import CollapsibleInfoBar from "../CollapsibleInfoBar";
import DirectMessageForm from "../../DirectMessageForm";
import { formatDateGMT, formatDateTimeGMT } from "../../../services/dates";
import {
  formatPhoneNumber,
  getDayFromDate
} from "../../../services/formatting";
import {
  fetchAccountNumberOrSSN,
  setNotificationPreferenceForPacket
} from "../../../services/packets";
import format from "date-fns/format";
import { formatDocType, formatMoney } from "../../../utils";

import "./CollapsibleInfoBarList.css";

const OrderStatusInfo = ({ eventLogId, message, user, createdAt }) => {
  return (
    <div key={eventLogId} className="EventLogInfoWrapper">
      <div className="EventLogMessage">{message}</div>
      <div className="EventLogAdditionalInfo">
        {user && <div className="EventLogUser">{user}</div>}
        <div className="EventLogTime">
          {format(createdAt, "MM/DD/YYYY")} @ {format(createdAt, "h:mma")}
        </div>
      </div>
    </div>
  );
};

const CollapsibleInfoBarList = ({
  packet = {},
  setCounterId = () => {},
  setCloseModal = () => {},
  setShowAccountNumberOrSSN = () => {},
  showAccountNumberOrSSN,
  currentUserId,
  setPacket = () => {},
  refreshPacket = () => {},
  downloadDoc = () => {},
  downloading = [],
  packetId,
  setUploadingDocuments = () => {},
  setShowOrderingMenu = () => {},
  setUpdatingClaim = () => {},
  setDisabled = () => {},
  disabled
}) => {
  const [showCounterOffer, setShowCounterOffer] = useState(false);
  const [showDispute, setShowDispute] = useState(false);
  const [accountNumberOrSSN, setAccountNumberOrSSN] = useState(false);
  const [accountLoading, setAccountLoading] = useState(false);
  const [orderStatusLength] = useState(3);
  const [rawOrdersList, setRawOrdersList] = useState([]);
  const [ordersList, setOrdersList] = useState([]);
  const [arrStart, setArrStart] = useState(0);

  //shows dispute box and sends id to modal component
  useEffect(() => {
    const containsDispute = packet?.receivedDocuments.some(i => i.counterOffer);
    if (packet?.receivedDocuments?.length > 0 && containsDispute) {
      for (const item of packet.receivedDocuments) {
        // initial dispute box that opens counter offer modal
        if (item.counterOffer && !item.counterOfferResolved) {
          setShowCounterOffer(true);
          setShowDispute(false);
          setCounterId(item.receivedFaxId);
        }
        // disputed counter offer
        if (item.disputeDescription && !item.counterOfferResolved) {
          setShowCounterOffer(false);
          setShowDispute(true);
        }
        // accepted counter offer
        if (item.counterOffer && item.counterOfferResolved) {
          setShowCounterOffer(false);
          setShowDispute(false);
        }
      }
    } else {
      setShowCounterOffer(false);
      setShowDispute(false);
    }
  }, [packet, setCounterId]);

  useEffect(() => {
    if (packet?.events) {
      setRawOrdersList(
        packet.events.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
      );
    }
  }, [packet]);

  const showPaymentHistory = packet => {
    const pi = packet?.payoffQuote?.paymentInformation;
    if (
      pi?.minMonthlyPayment ||
      pi?.dueDate ||
      pi?.lastPaymentAmount ||
      pi?.lastPaymentDate
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (
      packet?.payoffQuote ||
      packet?.letterOfGuaranteeRequest ||
      packet?.orders?.length > 0
    ) {
      setDisabled(false);
    }
  }, [packet, setDisabled]);

  useEffect(() => {
    setOrdersList(rawOrdersList.slice(arrStart, arrStart + orderStatusLength));
  }, [rawOrdersList, arrStart, orderStatusLength]);

  const showNextThreeLogs = () => {
    setArrStart(start => start + orderStatusLength);
  };

  const showPreviousThreeLogs = () => {
    setArrStart(start => start - orderStatusLength);
  };

  const getAccountNumberOrSSN = async () => {
    setAccountLoading(true);
    const { accountNumberOrSSN, events } = await fetchAccountNumberOrSSN(
      packetId
    );
    setAccountNumberOrSSN(accountNumberOrSSN);
    setShowAccountNumberOrSSN(true);
    setPacket({ ...packet, events });
    setAccountLoading(false);
  };

  const setMessagesNotificationPreference = async (name, value) => {
    if (value === packet.doNotNotifyUsers.includes(currentUserId)) {
      await setNotificationPreferenceForPacket(
        packet.packetId,
        value,
        currentUserId
      );
      await refreshPacket();
    }
  };

  const showDocumentsSidebar = () => {
    setShowOrderingMenu(false);
    setUpdatingClaim(false);
    setUploadingDocuments(true);
    setDisabled(true);
  };

  return (
    <div>
      {!packet.archived && (
        <>
          {showCounterOffer && (
            <div className="ClaimView__CallOutBox">
              <div className="ClaimView__CounterOfferInfo">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={styles.counterOfferIcon}
                />
                <span>
                  This claim's settlement amount has been disputed and urgently
                  requires a response.
                </span>
              </div>
              <Button onClick={setCloseModal} size="full-width">
                View and Respond to Dispute
              </Button>
            </div>
          )}
          {showDispute && (
            <div className="ClaimView__CallOutBox">
              <div className="ClaimView__DisputeInfo">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  style={styles.counterOfferIcon}
                />
                <span>
                  This claim's settlement amount has been countered by the
                  lender and is currently being disputed by our Claims
                  Specialists.
                </span>
              </div>
            </div>
          )}
        </>
      )}

      {!packet?.carrier?.routeOneOnly && (
        <CollapsibleInfoBar icon={faComments} name={"Direct Messages"} open>
          <DirectMessageForm packet={packet} />
          <div className="ClaimView__DMNotificationsCheckbox">
            <FormInput
              type="checkbox"
              label="Allow DM notifications for this claim"
              value={!packet.doNotNotifyUsers.includes(currentUserId)}
              defaultValue={!packet.doNotNotifyUsers.includes(currentUserId)}
              onChange={setMessagesNotificationPreference}
              name="doNotNotifyUsers"
              size="small"
            />
          </div>
        </CollapsibleInfoBar>
      )}

      <CollapsibleInfoBar icon={faCalendarAlt} name={"Order Status"} open>
        <div className="OrderStatusWrapper">
          {ordersList.map(event => (
            <OrderStatusInfo {...event} key={event.eventLogId} />
          ))}
        </div>
        <div className="EventsPaginationWrapper">
          {arrStart !== 0 ? (
            <div
              className="EventsPaginationNext"
              onClick={showPreviousThreeLogs}
            >
              View Next Events
            </div>
          ) : (
            <div className="EventsPaginationNext"></div>
          )}
          {arrStart - rawOrdersList.length < -3 && (
            <div
              className="EventsPaginationPrevious"
              onClick={showNextThreeLogs}
            >
              View Previous Events
            </div>
          )}
        </div>
      </CollapsibleInfoBar>

      <CollapsibleInfoBar
        icon={faMoneyCheckAlt}
        name={"Loan Information"}
        packet={packet}
      >
        <div className="ClaimView__LoanInfo">
          <div>
            <span className="Bold">Lender: </span>
            {packet?.lenderName}
          </div>
          {packet?.accountNumber && (
            <div>
              <span className="Bold">Account Number: </span>
              {!showAccountNumberOrSSN ? (
                <span
                  onClick={getAccountNumberOrSSN}
                  className={accountLoading ? "Bold" : "AccountNumberSSN Bold"}
                >
                  {accountLoading ? "loading..." : "click to view"}
                </span>
              ) : (
                <span>{accountNumberOrSSN}</span>
              )}
            </div>
          )}
          <div>
            <span className="Bold">
              Owner interested in retaining vehicle:{" "}
            </span>
            {packet?.ownerRetained ? "Yes" : "No"}
          </div>

          {packet?.insurerType && (
            <div>
              <span className="Bold">Insurer Type: </span>
              {packet?.insurerType}
            </div>
          )}
          <div className="Bold Red">
            {packet?.financeType === "Lease" && "This account is a lease."}
          </div>
          <div className="ClaimView__VehicleInfo">
            <div className="Bold">{packet?.vehicleType}</div>
            {packet?.vin && (
              <div>
                <span className="Bold">VIN: </span>
                {packet?.vin}
              </div>
            )}
            {packet?.odometer && (
              <div>
                <span className="Bold">Odometer: </span>
                {packet?.odometer}
              </div>
            )}
            {packet?.vehicleLocation && (
              <div>
                <span className="Bold">Vehicle Location: </span>
                {packet?.vehicleLocation}
              </div>
            )}
          </div>
          {packet?.ownersName && (
            <div>
              <span className="Bold">Owner Name(s): </span>
              {packet?.ownersName}
            </div>
          )}
          {packet?.ownersEmailAddress && (
            <div>
              <span className="Bold">Owner's Email: </span>
              {packet?.ownersEmailAddress}
            </div>
          )}
          {packet?.ownersPhoneNumber && (
            <div>
              <span className="Bold">Owner's Phone Number: </span>
              {formatPhoneNumber(packet?.ownersPhoneNumber)}
            </div>
          )}
          {packet?.ownersStreetAddress && (
            <div>
              <span className="Bold">Owner's Address: </span>
              {packet?.ownersStreetAddress}
            </div>
          )}
          <div className="ClaimView__VehicleInfo">
            {showPaymentHistory(packet) && (
              <div>
                <span className="Bold Underline">Payment History</span>
              </div>
            )}
            {packet?.payoffQuote?.paymentInformation?.minMonthlyPayment && (
              <div>
                <span className="Bold">Minimum Monthly Payment: </span>
                {formatMoney(
                  packet?.payoffQuote?.paymentInformation?.minMonthlyPayment
                )}
              </div>
            )}
            {packet?.payoffQuote?.paymentInformation?.dueDate && (
              <div>
                <span className="Bold">Due Date: </span>
                {getDayFromDate(
                  packet?.payoffQuote?.paymentInformation?.dueDate
                )}{" "}
                of the month
              </div>
            )}
            {packet?.payoffQuote?.paymentInformation?.lastPaymentAmount && (
              <div>
                <span className="Bold">Last Payment Amount: </span>
                {formatMoney(
                  packet?.payoffQuote?.paymentInformation?.lastPaymentAmount
                )}
              </div>
            )}
            {packet?.payoffQuote?.paymentInformation?.lastPaymentDate && (
              <div>
                <span className="Bold">Last Payment Date: </span>
                {formatDateGMT(
                  packet?.payoffQuote?.paymentInformation?.lastPaymentDate
                )}
              </div>
            )}
          </div>
        </div>
      </CollapsibleInfoBar>

      <CollapsibleInfoBar icon={faCarCrash} name={"Claim Information"}>
        <div className="ClaimView__ClaimInfo">
          <div className="ClaimView__ClaimInfo--Top">
            {packet?.dateOfLoss && (
              <div>
                <span className="Bold">Date of Loss: </span>
                {packet?.dateOfLoss && formatDateGMT(packet.dateOfLoss)}
              </div>
            )}
            {packet?.settlementAmount && (
              <div>
                <span className="Bold">Settlement Amount: </span>
                {formatMoney(packet?.settlementAmount)}
              </div>
            )}
            {packet?.deductible && (
              <div>
                <span className="Bold">Deductible: </span>
                {formatMoney(packet?.deductible)}
              </div>
            )}
            {packet?.causeOfLoss && (
              <div>
                <span className="Bold">Cause Of Loss: </span>
                {packet?.causeOfLoss}
              </div>
            )}
          </div>
          <div>
            {packet?.adjusterName && (
              <div>
                <span className="Bold">Adjuster's Name: </span>
                {packet?.adjusterName}
              </div>
            )}
            {packet?.adjusterPhoneNumber && (
              <div>
                <span className="Bold">Adjuster's Phone Number: </span>
                {formatPhoneNumber(packet?.adjusterPhoneNumber)}
              </div>
            )}
            {packet?.adjusterEmailAddress && (
              <div>
                <span className="Bold">Adjuster's E-mail Address: </span>
                {packet?.adjusterEmailAddress}
              </div>
            )}
            {packet?.titleRemittanceAddress && (
              <div>
                <span className="Bold">Title Remittance Address: </span>
                {packet?.titleRemittanceAddress}
              </div>
            )}
          </div>
        </div>
      </CollapsibleInfoBar>

      <CollapsibleInfoBar icon={faFolderOpen} name={"Uploaded Documents"}>
        <div className="ClaimView__DocumentsWrapper">
          {packet?.documents?.length > 0 &&
            packet.documents.map((doc, i) => (
              <div key={doc.documentId}>
                {!packet.documents[i].deleted && (
                  <div className="ClaimView__Document">
                    <div>
                      <div onClick={downloadDoc(doc)}>
                        <FontAwesomeIcon
                          icon={
                            downloading.includes(doc.documentId)
                              ? faCircleNotch
                              : faFileDownload
                          }
                          spin={downloading.includes(doc.documentId)}
                          style={styles.downloadIcon}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="ClaimView__Document--Title">
                        {formatDocType(doc.type)}
                      </div>
                      <div className="ClaimView__Document--Time">
                        <span className="Bold">Uploaded: </span>{" "}
                        {formatDateTimeGMT(doc.createdAt)}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
        <Button
          size="full-width"
          onClick={showDocumentsSidebar}
          disabled={disabled}
        >
          Upload New Document
        </Button>
      </CollapsibleInfoBar>
    </div>
  );
};

export default CollapsibleInfoBarList;
