export function formatDocType(type) {
  switch (type) {
    case "settlement breakdown":
      return "Settlement Breakdown";
    case "valuation report":
      return "Valuation Report";
    case "valuation report & settlement breakdown":
      return "Valuation Report & Settlement Breakdown";
    case "cause of loss":
      return "Cause of Loss Statement";
    case "declaration page":
      return "Declaration Page";
    case "police report":
      return "Police Report";
    case "Customer Auth Form":
      return "Customer Auth Form";
    default:
    case "unspecified":
      return type != null ? type : "Unspecified";
  }
}

export function formatMoney(n, c, d, t) {
  c = isNaN((c = Math.abs(c))) ? 2 : c;
  d = d === undefined ? "." : d;
  t = t === undefined ? "," : t;
  const s = n < 0 ? "-" : "";
  const i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
  let j;
  j = (j = i.length) > 3 ? j % 3 : 0;

  return (
    s +
    "$" +
    (j ? i.substr(0, j) + t : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
    (c
      ? d +
        Math.abs(n - i)
          .toFixed(c)
          .slice(2)
      : "")
  );
}
