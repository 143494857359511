import React, { useState, useEffect } from "react";
import { FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import { getAccountInfo } from "../../../services/users";
import "./AdjusterInformation.css";
import CategoryWrapper from "../CategoryWrapper";

const AdjusterInformation = ({
  register,
  unregister,
  carrier = {},
  errors = {},
  watch = () => {},
  packet = () => {},
  triggerValidation = () => {},
  control,
  user = {}
}) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  const watchedAdjusterInfo = watch("adjusterInfo");
  const adjusterErrors = errors?.adjusterInfo;

  useEffect(() => {
    if (carrier.autoAddAdjusterInfo) {
      (async function() {
        const user = await getAccountInfo();
        const { name, phoneNumber, emailAddress } = user;
        setName(name);
        setPhoneNumber(phoneNumber);
        setEmailAddress(emailAddress);
      })();
    }
  }, [carrier]);

  return (
    <div>
      {carrier.showAdjusterInfoOnForm && (
        <CategoryWrapper
          name="Adjuster Information"
          watch={watchedAdjusterInfo}
          category="adjusterInfo"
          triggerValidation={triggerValidation}
        >
          <div data-testid="AdjusterInformation">
            <FormInput
              defaultValue={
                packet && packet.adjusterName ? packet.adjusterName : name
              }
              type="text"
              error={adjusterErrors?.adjusterName}
              name={"adjusterInfo.adjusterName"}
              placeholder={"Adjuster Name"}
              register={register}
              unregister={unregister}
              required
            />
            <FormInput
              name="adjusterInfo.adjusterPhoneNumber"
              placeholder="Adjuster Phone Number"
              error={adjusterErrors?.adjusterPhoneNumber}
              required
              format="phone"
              defaultValue={
                packet && packet.adjusterPhoneNumber
                  ? packet.adjusterPhoneNumber
                  : user && user.phoneNumber && carrier.autoAddAdjusterInfo
                  ? user.phoneNumber
                  : phoneNumber
              }
              control={control}
              formatOnChange
            />
            <FormInput
              defaultValue={
                packet && packet.adjusterEmailAddress
                  ? packet.adjusterEmailAddress
                  : emailAddress
              }
              type="text"
              error={adjusterErrors?.adjusterEmailAddress}
              name={"adjusterInfo.adjusterEmailAddress"}
              placeholder={"Adjuster Email Address"}
              register={register}
              unregister={unregister}
              required
            />
          </div>
        </CategoryWrapper>
      )}
    </div>
  );
};

export default AdjusterInformation;
