import { useEffect, useState } from "react";

global.appVersion = process.env.REACT_APP_VERSION;

const ENV = process.env.REACT_APP_ENV_NAME || "local";

const CacheBuster = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  useEffect(() => {
    cacheJudge();

    const interval = setInterval(cacheJudge, 600000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const cacheJudge = async () => {
    const response = await fetch("/meta.json");
    const meta = await response.json();

    // only refresh when the versions differ, but never locally (to prevent refresh loops after commits)
    const shouldForceRefresh =
      meta.commitVersion !== global.appVersion && ENV !== "local";
    setIsLatestVersion(!shouldForceRefresh);
    setLoading(false);
  };

  const refreshCacheAndReload = () => {
    if (caches) {
      caches.keys().then(names => {
        for (let name of names) {
          caches.delete(name);
        }
      });
    }

    window.location.reload(true);
  };

  return children({ loading, isLatestVersion, refreshCacheAndReload });
};

export default CacheBuster;
