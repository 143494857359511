import React from "react";
import { FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import "./VehicleInformation.css";
import CategoryWrapper from "../CategoryWrapper";

const VehicleInformation = ({
  register,
  unregister,
  carrier = {},
  packet = {},
  errors,
  watch = () => {},
  setValue = () => {},
  triggerValidation = () => {},
  setIsVehicleLeased = () => {}
}) => {
  const watchedVehicleInfo = watch("vehicleInfo");
  const vehicleErrors = errors?.vehicleInfo;

  const financeType = watch("vehicleInfo.financeType");

  const onCheckboxChange = (name, bool) => {
    setValue(name, bool);
    setIsVehicleLeased(bool);
  };

  return (
    <CategoryWrapper
      name="Vehicle Information"
      watch={watchedVehicleInfo}
      triggerValidation={triggerValidation}
      category="vehicleInfo"
    >
      <div className="VehicleInformationWrapper">
        <FormInput
          type="text"
          error={vehicleErrors?.vin}
          name={"vehicleInfo.vin"}
          placeholder={"VIN (or other IN)"}
          register={register}
          unregister={unregister}
          required
          defaultValue={packet && packet.vin ? packet.vin : ""}
        />
        <FormInput
          type="text"
          error={vehicleErrors?.odometer}
          name={"vehicleInfo.odometer"}
          placeholder={"Mileage (Odometer Reading)"}
          register={register}
          unregister={unregister}
          required
          defaultValue={packet && packet.odometer ? packet.odometer : ""}
        />

        {carrier.requireVehicleLocation && (
          <FormInput
            type="text"
            error={vehicleErrors?.location}
            name={"vehicleInfo.vehicleLocation"}
            placeholder={"Current Vehicle Location"}
            register={register}
            unregister={unregister}
            required
            defaultValue={
              packet && packet.vehicleLocation ? packet.vehicleLocation : ""
            }
          />
        )}

        <FormInput
          label="Vehicle is leased"
          type="checkbox"
          name="vehicleInfo.financeType"
          register={register}
          error={vehicleErrors?.financeType}
          onChange={onCheckboxChange}
          value={financeType}
          defaultValue={packet?.financeType === "Lease"}
        />
      </div>
    </CategoryWrapper>
  );
};

export default VehicleInformation;
