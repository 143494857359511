import { flFetch as fetch, validateStatus } from "./fetch";

export const updateActiveOrderStatus = (carrierId, orderTypeName) => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/active/${carrierId}`, {
    method: "PUT",
    body: JSON.stringify({
      orderTypeName
    })
  }).then(validateStatus);
};

export const getCurrentInactiveOrders = carrierId => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/active/${carrierId}`, {
    method: "GET"
  }).then(validateStatus);
};
