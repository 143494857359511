import React, { useState, useEffect, useRef } from "react";
import { Redirect } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";

import CancelLoGModal from "../CancelLoGModal";
import RequestForm from "../RequestForm";

import {
  cancelLetterOfGuaranteeRequest,
  fetchPacket,
  uploadDocumentChunksToClaim
} from "../../services/packets";
import { getAccountInfo } from "../../services/users";

import { getVendorConnectedCompanies } from "../../services/vendors";
import { websocket } from "../../services/websocket";

import "./SideContainer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faTimes, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import OrderingMenu from "../OrderingMenu";
import { styles } from "../ClaimView/IconStyles";
import UploadDocuments from "./UploadDocuments";

const moveScrollToTop = ({ element }) => {
  element.current.scrollTop = 0;
};

const savingDocuments = {
  "cause of loss": false,
  "valuation report & settlement breakdown": false,
  "settlement breakdown": false,
  "valuation report": false,
  "declaration page": false,
  "police report": false,
  unspecified: false
};

export const FileUploadProgressBar = ({ fileUploadProgress, type = null }) => {
  return (
    <div className="SideContainer__ProgressBarWrapper">
      <FontAwesomeIcon icon={faCloudUploadAlt} style={styles.cloudUpload} />
      {Object.keys(fileUploadProgress).map(upload => (
        <div className="SideContainerFileUpload__Wrapper" key={upload}>
          <div className="SideContainer__UploadContainer">
            <div className="SideContainerFileUpload__Name">
              <span>
                {fileUploadProgress[upload] >= 100 && (
                  <FontAwesomeIcon icon={faCheckCircle} />
                )}{" "}
                Uploading {type}...
              </span>
            </div>
            {fileUploadProgress[upload] < 100 && (
              <div className="SideContainerUpload__Progress">
                <div
                  className="SideContainerUpload__Progress--Done"
                  style={{ width: `${fileUploadProgress[upload]}%` }}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

const SideContainer = ({
  workPacket,
  updatingClaim,
  cancelUpdating,
  refreshPacket,
  uploadingDocuments,
  setUploadingDocuments = () => {},
  showOrderingMenu,
  setShowOrderingMenu = () => {},
  setDisabled = () => {},
  loading
}) => {
  const [carrier, setCarrier] = useState({});
  const [error, setError] = useState(null);
  const [lender, setLender] = useState(null);
  const [packet, setPacket] = useState(null);
  const [saving, setSaving] = useState(false);
  const [showCancelLoGModal, setShowCancelLoGModal] = useState(false);
  const scrollRef = useRef(null);
  const [redirect, setRedirect] = useState(false);
  const [user, setUser] = useState({});
  const [fileUploadProgress, setFileUploadProgress] = useState({});
  const [savingDocs, setSavingDocs] = useState(savingDocuments);
  const [secondStep, setSecondStep] = useState(false);
  const [thirdStep, setThirdStep] = useState(false);
  const [requestCompleted, setRequestCompleted] = useState(false);
  const [autoPayoffCompleted, setAutoPayoffCompleted] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState(null);
  const [isVehicleLeased, setIsVehicleLeased] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [skipRequiredDocs, setSkipRequiredDocs] = useState(false);

  websocket.on("auto-payoff-attempt-completed", packetId =>
    setAutoPayoffCompleted(packetId)
  );

  useEffect(() => {
    (async function() {
      const [carriers, u] = await Promise.all([
        getVendorConnectedCompanies(),
        getAccountInfo()
      ]);

      setUser(u);

      const c = carriers.filter(car => car.carrierId === u.requestingCompanyId);

      if (!c || c.length < 1) {
        setCarrier(carriers[0]);
      } else {
        setCarrier(c[0]);
      }

      setSkipRequiredDocs(carrier?.uploadingDocsOptional);
    })();
  }, [carrier?.uploadingDocsOptional]);

  useEffect(() => {
    if (workPacket) {
      setPacket(workPacket);
      setLender(workPacket.lender);
    }
  }, [workPacket]);

  useEffect(() => {
    if (requestCompleted && packet) {
      const timer = setTimeout(() => setRedirect(packet.packetId), 60000);
      setRedirectTimer(timer);
    }
  }, [packet, requestCompleted]);

  useEffect(() => {
    if (
      autoPayoffCompleted &&
      packet &&
      packet.packetId === autoPayoffCompleted &&
      !saving
    ) {
      setRedirect(packet.packetId);
    }
  }, [autoPayoffCompleted, packet, saving]);

  const hasSettlementBreakdown = workPacket?.documents?.some(
    doc => doc.type === "settlement breakdown"
  );
  const hasValuationReport = workPacket?.documents?.some(
    doc => doc.type === "valuation report"
  );
  const hasCombinedDocs = workPacket?.documents?.some(
    doc => doc.type === "valuation report & settlement breakdown"
  );

  useEffect(() => {
    if (!loading && workPacket) {
      if (!workPacket?.carrier?.combinedDocumentTypes) {
        if (!requestCompleted && !skipRequiredDocs) {
          if (!hasSettlementBreakdown || !hasValuationReport) {
            setSecondStep(true);
            setDisabled(true);
            setShowOrderingMenu(false);
          }
        }
      }

      if (workPacket?.carrier?.combinedDocumentTypes) {
        if (!hasCombinedDocs) {
          setSecondStep(true);
          setDisabled(true);
          setShowOrderingMenu(false);
        }
      }
    }
  }, [
    workPacket,
    setShowOrderingMenu,
    setDisabled,
    loading,
    hasSettlementBreakdown,
    hasValuationReport,
    hasCombinedDocs,
    skipRequiredDocs,
    requestCompleted
  ]);

  const showDocumentation = () => {
    return (
      !saving &&
      !carrier?.disableNewActivity &&
      (secondStep ||
        thirdStep ||
        !workPacket ||
        updatingClaim ||
        (workPacket?.payoffQuote === "retry" && !workPacket?.archived))
    );
  };

  const handleDocuments = (type, claimsSpecialistReviewNeeded = true) => async (
    e,
    results
  ) => {
    // Don't create a Review Document from Adjuster task before orders have been placed
    if (workPacket?.orders?.length === 0 && !workPacket.payoffQuote) {
      claimsSpecialistReviewNeeded = false;
    }

    setSavingDocs(prevState => {
      return { ...prevState, [type]: true };
    });

    const files = results.reduce((acc, result, i) => {
      const [, file] = result;

      acc[`${file.name}-${i}`] = file;
      return acc;
    }, {});

    let fileCheck = Object.keys(files);
    if (fileCheck.length > 0) {
      try {
        await Promise.all(
          fileCheck.map(key => {
            return uploadDocumentChunksToClaim(
              packet.packetId,
              files[key],
              type,
              {
                onProgress: p => {
                  let num = p - 10;
                  if (p < 0) {
                    num = 0;
                  }

                  setFileUploadProgress(progress => ({
                    ...progress,
                    [files[key].name]: num
                  }));
                }
              },
              {
                claimsSpecialistReviewNeeded
              }
            ).then(d => {
              setFileUploadProgress(progress => ({
                ...progress,
                [d]: 100
              }));
            });
          })
        );

        const p = await fetchPacket(packet.packetId);
        setPacket(p);
        setFileUploadProgress({});
        refreshPacket();
      } catch (ex) {
        Sentry.captureException(ex);
      }
      setSavingDocs(prevState => {
        return { ...prevState, [type]: false };
      });
    } else {
      setSavingDocs(prevState => {
        return { ...prevState, [type]: false };
      });
    }
  };

  const onLenderSelect = lender => {
    const l = lender;
    if (
      lender &&
      lender.lenderInformation &&
      lender.lenderInformation.availablePayoffQueries
    ) {
      l.lenderInformation.availablePayoffQueries = l.lenderInformation.availablePayoffQueries.filter(
        q => q !== "socialSecurityNumber"
      );
    }
    setLender(l);
  };

  const onSetSaving = bool => {
    if (bool) {
      moveScrollToTop({ element: scrollRef });
    }

    setSaving(bool);
  };

  const closeCancelModal = () => {
    setShowCancelLoGModal(false);
  };

  const cancelRequest = async data => {
    setSaving(true);
    await cancelLetterOfGuaranteeRequest(packet.packetId, data);

    closeCancelModal();

    const p = await fetchPacket(packet.packetId);
    setPacket(p);
    setSaving(false);
  };

  const revertContainerToOrderingMenu = () => {
    setUploadingDocuments(false);
    cancelUpdating();
    setShowOrderingMenu(true);
    setDisabled(false);
  };

  return (
    <div
      className={`SideContainer ${
        isCollapsed ? "SideContainerCollapsedBar" : ""
      }`}
      ref={scrollRef}
      data-testid="SideContainer"
    >
      {error && <div className="ErrorText">{error}</div>}
      {redirect && <Redirect to={`/packet/${redirect}`} push />}

      {(uploadingDocuments || updatingClaim) && (
        <div
          className="SideContainerCollapse"
          onClick={revertContainerToOrderingMenu}
        >
          <FontAwesomeIcon className="SideContainerTimesIcon" icon={faTimes} />
        </div>
      )}

      {showCancelLoGModal && (
        <CancelLoGModal
          close={closeCancelModal}
          onCancelRequest={cancelRequest}
        />
      )}

      {saving && (
        <div className="RightSideLoading">
          <p>Saving claim...</p>
          <i className="fas fa-spinner fa-5x" />
          <p className="RightSideExplanation">This could take a minute.</p>
          <p className="RightSideExplanation RightSide--DontLeave">
            We promise that we're stepping on the gas, but uploads can take some
            time. <br />
            <br />
            <strong>Please do not leave or refresh this page!</strong>
          </p>
        </div>
      )}

      {requestCompleted && (
        <div className="RequestCompleted">
          <h2>Claim Created</h2>
          <p>
            Your order is currently being processed. Unless you'd like to create
            another claim, you'll be redirected to this claim shortly.
          </p>
          <Button
            size="full-width"
            onClick={() => setRedirect(packet.packetId)}
          >
            View Claim
          </Button>
          <Button
            size="full-width"
            onClick={() => {
              setPacket(null);
              setRequestCompleted(false);
              setUploadingDocuments(false);
              clearTimeout(redirectTimer);
            }}
          >
            Create Another Request
          </Button>
        </div>
      )}

      {!isCollapsed && packet && packet.payoffQuote && (
        <div className="SideContainerTopSpacer" />
      )}
      {showDocumentation() && (
        <div className="SideContainerMarginTop">
          <RequestForm
            carrier={carrier}
            packet={packet}
            lender={lender}
            setError={setError}
            error={error}
            setPacket={setPacket}
            saving={saving}
            setSaving={onSetSaving}
            onLenderSelect={onLenderSelect}
            updatingClaim={updatingClaim}
            cancelUpdating={cancelUpdating}
            refreshPacket={refreshPacket}
            user={user}
            setRedirect={setRedirect}
            setShowOrderingMenu={setShowOrderingMenu}
            setSecondStep={setSecondStep}
            secondStep={secondStep}
            setThirdStep={setThirdStep}
            thirdStep={thirdStep}
            savingDocs={savingDocs}
            handleDocuments={handleDocuments}
            fileUploadProgress={fileUploadProgress}
            workPacket={workPacket}
            setRequestCompleted={setRequestCompleted}
            isVehicleLeased={isVehicleLeased}
            setIsVehicleLeased={setIsVehicleLeased}
          />
        </div>
      )}

      {uploadingDocuments && !saving && (
        <UploadDocuments
          packet={packet}
          savingDocs={savingDocs}
          handleDocuments={handleDocuments}
          fileUploadProgress={fileUploadProgress}
          skipRequiredDocs={skipRequiredDocs}
        />
      )}

      {showOrderingMenu && !saving && (
        <OrderingMenu
          packet={packet}
          setError={setError}
          setPacket={setPacket}
          setSaving={setSaving}
          refreshPacket={refreshPacket}
          workPacket={workPacket}
          updatingClaim={updatingClaim}
          carrier={carrier}
          loading={loading}
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
        />
      )}
    </div>
  );
};

export default SideContainer;
