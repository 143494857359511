import { useState, useEffect } from "react";
import { getStateFarmMetabaseReportUrl } from "../services/users";

const useStateFarmMetabaseReportUrl = () => {
  const [stateFarmReportUrl, setStateFarmReportUrl] = useState("");
  const [isStateFarmReportOk, setIsStateFarmReportOk] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    getStateFarmMetabaseReportUrl().then(data => {
      if (isCancelled) return;

      setStateFarmReportUrl(data?.dashboardUrl);
      setIsStateFarmReportOk(data?.isDashboardHealthy);
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  return [stateFarmReportUrl, isStateFarmReportOk];
};

export default useStateFarmMetabaseReportUrl;
