import React, { forwardRef } from "react";

const Typography = forwardRef((props, ref) => {
  const { variant, children, align } = props;

  const h1_style = {
    fontFamily: "Gilroy-Medium",
    fontWeight: "bold",
    fontSize: "2em"
  };

  const h2_style = {
    fontFamily: "Gilroy-Medium",
    fontWeight: "bold",
    fontSize: "1.4em"
  };

  const h3_style = {
    fontFamily: "Gilroy-Medium",
    fontWeight: "bold",
    fontSize: "1.2em"
  };

  const containerStyle = {};

  const textAlignment = ["center", "inherit", "justify", "left", "right"];

  if (textAlignment.includes(align)) {
    containerStyle.textAlign = align;
  }

  const variantDict = {
    h1: forwardRef(({ children }, _ref) => (
      <h1 style={h1_style} ref={_ref}>
        {children}
      </h1>
    )),
    h2: forwardRef(({ children }, _ref) => (
      <h2 style={h2_style} ref={_ref}>
        {children}
      </h2>
    )),
    h3: forwardRef(({ children }, _ref) => (
      <h3 style={h3_style} ref={_ref}>
        {children}
      </h3>
    ))
  };

  const defaultElement = forwardRef(({ children }, _ref) => (
    <div ref={_ref}>{children}</div>
  ));

  const Element = variantDict[variant] || defaultElement;

  return (
    <div style={containerStyle}>
      <Element ref={ref}>{children}</Element>
    </div>
  );
});

export default Typography;
