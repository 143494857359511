import React, { useState, useRef, useEffect } from "react";
import { FormInput, Button } from "@fastlane-llc/lossexpress-ui-kit";
import { useForm } from "react-hook-form";
import { editUser, resendWelcomeEmail } from "../../../../services/users";
import "./EditEmailForm.css";

const EditEmailForm = ({ user = {}, fetchUsers = () => {} }) => {
  const [showEditEmail, setShowEditEmail] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [error, setError] = useState("");
  const editEmailRef = useRef();

  const { handleSubmit, register, errors } = useForm({
    mode: "onBlur"
  });

  const toggleEditEmail = () => {
    setShowEditEmail(!showEditEmail);
  };

  useEffect(() => {
    const handleClick = e => {
      if (editEmailRef.current && editEmailRef.current.contains(e.target)) {
        return;
      }

      setShowEditEmail(false);
    };

    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [editEmailRef]);

  const editUserEmail = user => async data => {
    const userId = user.userId;
    const currentEmail = user.emailAddress;
    const emailAddress = data.emailAddress;
    if (currentEmail === emailAddress) {
      setError("Please enter a different email address.");
      setTimeout(() => setError(""), 7000);
    } else {
      try {
        await editUser(userId, { emailAddress });
        await fetchUsers();
        setInfoMessage("User email successfully updated.");
        setTimeout(() => setInfoMessage(""), 7000);
      } catch (ex) {
        setError(`User email update failed. Error message: ${ex}`);
        setTimeout(() => setError(""), 7000);
      }
    }
  };

  const resendEmail = async (userId, emailAddress) => {
    try {
      await resendWelcomeEmail(userId, { emailAddress });
      setInfoMessage("Email successfully sent!");
      setTimeout(() => setShowEditEmail(false), 5000);
      setTimeout(() => setInfoMessage(""), 5000);
    } catch (ex) {
      setError(`An error has occured: ${ex}`);
    }
  };

  return (
    <div>
      <span className="EditEmail" onClick={toggleEditEmail}>
        Edit email address
      </span>
      {showEditEmail && (
        <div ref={editEmailRef}>
          {/* eslint-disable-next-line */}
          <form onSubmit={handleSubmit(editUserEmail(user))} role="form">
            <FormInput
              type="text"
              defaultValue={user.emailAddress}
              register={register}
              name="emailAddress"
              errors={errors.emailAddress}
              placeholder="Email Address"
            />
            <span className="UpdateEmailInfoMessage">{infoMessage}</span>
            <span className="UpdateEmailError">{error}</span>
            <Button size="small">Confirm Edit Email</Button>
          </form>
          {!user.lastPasswordChange && (
            <Button
              onClick={() => resendEmail(user.userId, user.emailAddress)}
              size="small"
              className="ResendEmailButton"
            >
              Resend Welcome Email?
            </Button>
          )}
        </div>
      )}
      <br />
    </div>
  );
};

export default EditEmailForm;
