import { flFetch as fetch, validateStatus } from "./fetch";

export const getVendorByDomain = subdomain => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/payoff/vendors/domain?subdomain=${subdomain}`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

// Unused
export const getVendorCompanies = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/info/carriers`, {
    method: "GET"
  }).then(validateStatus);
};

export const getVendorConnectedCompanies = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/info/carriers/connected`, {
    method: "GET"
  }).then(validateStatus);
};

export const getVendorsAuthInfo = subdomain => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/payoff/vendors/auth-info?subdomain=${subdomain}`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};
