import React, { useState } from "react";
import format from "date-fns/format";

import Modal from "../Modal";

import "./QuickQuoteReceived.css";
import { getDayFromDate } from "../../services/formatting";
import { formatMoney } from "../../utils";
import { fetchAccountNumberOrSSN } from "../../services/packets";

const formatDate = date => {
  if (date) {
    const dt = new Date(date);
    const dtDateOnly = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    );

    return format(dtDateOnly, "MM-DD-YYYY");
  }

  return "N/A";
};

const QuickQuoteReceived = ({ packet, refreshPacket = () => {} }) => {
  const [openRemittanceInformation, setOpenRemittanceInformation] = useState(
    false
  );
  const [accountNumber, setAccountNumber] = useState("");

  const paymentInstructions = React.createRef();

  const quote = packet.payoffQuote;

  const openRemittanceInformationModal = async () => {
    setOpenRemittanceInformation(true);
    const { accountNumberOrSSN } = await fetchAccountNumberOrSSN(
      packet.packetId
    );
    setAccountNumber(accountNumberOrSSN);
  };

  const closeRemittanceInformationModal = async () => {
    setOpenRemittanceInformation(false);
    await refreshPacket();
  };

  const vehicleType = `${packet.year} ${packet.make} ${packet.model}`;

  return (
    <>
      <span
        className="RightSideRemittanceInformation"
        onClick={openRemittanceInformationModal}
      >
        View Remittance Information
      </span>

      {openRemittanceInformation && (
        <Modal
          onClose={closeRemittanceInformationModal}
          title={`Remittance Information (#${packet.claimNumber})`}
          style={{ top: "1em", maxHeight: "100vh", minWidth: "40vw" }}
        >
          <div className="RemittanceModal">
            <div className="RemittanceSection">
              <div className="RemittanceSectionTitle">Owner Information</div>
              {packet && packet.ownersName && (
                <>
                  <div>
                    <span className="SemiBold">Owner's Name (Entered): </span>
                    {packet.ownersName}
                  </div>
                  {packet.titledOwnersName && (
                    <div>
                      <span className="SemiBold">
                        Titled Owners Name (Verified):{" "}
                      </span>
                      <nobr>{packet.titledOwnersName}</nobr>
                    </div>
                  )}
                  {packet.ownersStreetAddress && (
                    <div>
                      <span className="SemiBold">Owners Street Address: </span>
                      {packet.ownersStreetAddress}
                    </div>
                  )}
                </>
              )}
            </div>

            <div className="RemittanceSection">
              <div className="RemittanceSectionTitle">Payoff Information</div>
              {packet && packet.payoffQuote.paymentInformation && (
                <>
                  {quote.paymentInformation.netAmount && (
                    <div>
                      <span className="SemiBold">Amount: </span>
                      {formatMoney(quote.paymentInformation.netAmount)}
                    </div>
                  )}
                  {quote.paymentInformation.perDiem && (
                    <div>
                      <span className="SemiBold">Per Diem: </span>
                      {formatMoney(quote.paymentInformation.perDiem)}
                    </div>
                  )}
                  {quote.paymentInformation.backdatedThroughDateOfLoss ? (
                    <div>
                      <span className="SemiBold">
                        This quote is back-dated to the date of loss
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="SemiBold">Valid Through Date: </span>
                      <nobr>
                        {formatDate(quote.paymentInformation.validUntil)}
                      </nobr>
                    </div>
                  )}
                  {packet.financeType === "Lease" && (
                    <div className="SemiBold Red">Account is a lease.</div>
                  )}
                </>
              )}
            </div>

            <div className="RemittanceSection">
              <div className="RemittanceSectionTitle">Vehicle Information</div>
              <div>
                <span className="SemiBold">Vehicle: </span>
                {vehicleType}
              </div>
              <div>
                <span className="SemiBold">VIN: </span>
                {packet.vin}
              </div>
              <div>
                <span className="SemiBold">Account Number: </span>
                {accountNumber}
              </div>
            </div>

            <div className="RemittanceSection">
              <div className="RemittanceSectionTitle">Payoff History</div>

              {packet.payoffQuote.paymentInformation.minMonthlyPayment && (
                <div>
                  <span className="SemiBold">Minimum Monthly Payment: </span>
                  {formatMoney(
                    packet?.payoffQuote?.paymentInformation?.minMonthlyPayment
                  )}
                </div>
              )}

              {packet.payoffQuote.paymentInformation.dueDate && (
                <div>
                  <span className="SemiBold">Payment Due: </span>
                  <nobr>
                    {getDayFromDate(
                      packet.payoffQuote.paymentInformation.dueDate
                    )}{" "}
                    of the month
                  </nobr>
                </div>
              )}

              {packet.payoffQuote.paymentInformation.lastPaymentDate && (
                <div>
                  <span className="SemiBold">Last Loan Payment Date: </span>
                  <nobr>
                    {formatDate(
                      packet.payoffQuote.paymentInformation.lastPaymentDate
                    )}
                  </nobr>
                </div>
              )}

              {packet.payoffQuote.paymentInformation.lastPaymentAmount && (
                <div>
                  <span className="SemiBold">Last Loan Payment Amount: </span>
                  {formatMoney(
                    packet.payoffQuote.paymentInformation.lastPaymentAmount
                  )}
                </div>
              )}

              {!packet.payoffQuote.paymentInformation.minMonthlyPayment &&
                !packet.payoffQuote.paymentInformation.dueDate &&
                !packet.payoffQuote.paymentInformation.lastPaymentDate &&
                !packet.payoffQuote.paymentInformation.lastPaymentAmount && (
                  <div>
                    <span> Payoff History Not Available </span>
                  </div>
                )}
            </div>

            <div className="RemittanceSection FullWidth">
              <div className="RemittanceSectionTitle">
                Remittance Information
              </div>
              {(packet.lenderPhoneNumber ||
                packet.lender?.listedPhoneNumber) && (
                <div>
                  <span className="SemiBold">Lender Phone Number: </span>
                  {packet.lenderPhoneNumber
                    ? packet.lenderPhoneNumber
                    : packet.lender.listedPhoneNumber
                    ? packet.lender.listedPhoneNumber
                    : ""}
                </div>
              )}
              {quote.paymentInstructions &&
                quote.paymentInstructions.length > 0 && (
                  <div
                    className="NewPacketPaymentInstructions"
                    ref={paymentInstructions}
                  >
                    {quote.paymentInstructions.map(instruction => (
                      <div dangerouslySetInnerHTML={{ __html: instruction }} />
                    ))}
                  </div>
                )}
              {quote &&
                quote.remittanceInformation &&
                ((quote.remittanceInformation.overnightMail &&
                  quote.remittanceInformation.overnightMail.streetAddress) ||
                  (quote.remittanceInformation.regularMail &&
                    quote.remittanceInformation.regularMail.streetAddress)) && (
                  <div>
                    <ul className="RemittanceModalList">
                      <li>
                        Note customer's name, account number, and VIN on payoff
                        check.
                      </li>
                      <li>
                        Payoff amount is{" "}
                        {formatMoney(quote.paymentInformation.netAmount)}
                      </li>
                      {quote.remittanceInformation.checkPayableTo && (
                        <li>
                          Please make check payable to{" "}
                          {quote.remittanceInformation.checkPayableTo}
                        </li>
                      )}
                      <li>
                        Title or Lien Release will be sent out 10 - 15 business
                        days after the payoff is posted.
                      </li>
                      <li>
                        Please provide an address with the check where we should
                        send you the title/lien release.
                      </li>
                      <li>To ensure timely release:</li>
                      <ul className="NewPacketPayoffListEmbedded">
                        <li>
                          Use certified funds [note customer's name and account
                          number]
                        </li>
                        <li>Send check prior to payoff expiration date</li>
                      </ul>
                    </ul>
                  </div>
                )}
            </div>

            {/* to make sure the following sections go on the next line */}
            <div className="RemittanceSection"></div>

            <div className="RemittanceAddresses">
              {quote?.remittanceInformation?.regularMail?.streetAddress && (
                <div className="NewPacketPayoffAddress">
                  <h4 className="SemiBold">Regular Mail</h4>
                  {quote.remittanceInformation.regularMail.attn && (
                    <div>
                      ATTN: {quote.remittanceInformation.regularMail.attn}
                    </div>
                  )}
                  <div>{packet.lenderName}</div>
                  <div>
                    {quote.remittanceInformation.regularMail.streetAddress}
                  </div>
                  {quote.remittanceInformation.regularMail.address2 && (
                    <div>
                      {quote.remittanceInformation.regularMail.address2}
                    </div>
                  )}
                  <div>
                    {quote.remittanceInformation.regularMail.city},{" "}
                    {quote.remittanceInformation.regularMail.state}{" "}
                    {quote.remittanceInformation.regularMail.zipCode}
                  </div>
                </div>
              )}
            </div>

            <div className="RemittanceAddresses">
              {quote?.remittanceInformation?.overnightMail?.streetAddress && (
                <div className="NewPacketPayoffAddress">
                  <h4 className="SemiBold">Overnight Mail</h4>
                  {quote.remittanceInformation.overnightMail.attn && (
                    <div>
                      ATTN: {quote.remittanceInformation.overnightMail.attn}
                    </div>
                  )}
                  <div>{packet.lenderName}</div>
                  <div>
                    {quote.remittanceInformation.overnightMail.streetAddress}
                  </div>
                  {quote.remittanceInformation.overnightMail.address2 && (
                    <div>
                      {quote.remittanceInformation.overnightMail.address2}
                    </div>
                  )}
                  <div>
                    {quote.remittanceInformation.overnightMail.city},{" "}
                    {quote.remittanceInformation.overnightMail.state}{" "}
                    {quote.remittanceInformation.overnightMail.zipCode}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default QuickQuoteReceived;
