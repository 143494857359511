import React, { useState } from "react";

import "./ForgotUsername.css";

import { FormInput, Button } from "@fastlane-llc/lossexpress-ui-kit";

import Success from "../Success";
import Error from "../Error";

import { sendForgotUsernameRequest } from "../../services/users";
import { useForm } from "react-hook-form";
import Footer from "../Footer/Footer";
import XLienSideWrapper from "../XLienSideWrapper";

const ForgotUsername = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });

  const sendRequest = async data => {
    try {
      await sendForgotUsernameRequest(data.emailAddress);
      setSuccess(
        "We have sent an email containing the username that matches the provided email address."
      );
    } catch (ex) {
      setError(
        "There was an unexpected error submitting the forgotten username request. Please contact LossExpress Support."
      );
    }
  };

  return (
    <XLienSideWrapper>
      {success && <Success>{success}</Success>}
      {error && <Error>{error}</Error>}

      <div className="LoginTitle">Forgot Username?</div>
      <p>
        Enter your email address below, and we'll send an email with the
        associated LossExpress username.
      </p>

      <form onSubmit={handleSubmit(sendRequest)}>
        <FormInput
          name="emailAddress"
          register={register}
          required
          placeholder="Your Email Address"
          error={errors.emailAddress}
        />

        <Button>Submit Forgotten Username Request</Button>
      </form>
      <Footer />
    </XLienSideWrapper>
  );
};

export default ForgotUsername;
