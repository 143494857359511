import React from "react";
import { FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import CategoryWrapper from "../CategoryWrapper";

import "./LienInformation.css";

const LienInformation = ({
  register,
  unregister,
  setValue,
  errors,
  packet = {},
  watch = () => {},
  onChange = () => {},
  triggerValidation = () => {},
  control,
  carrier = {}
}) => {
  const watchedLienInfo = watch("lienInfo");
  const lienErrors = errors?.lienInfo;
  return (
    <div>
      <CategoryWrapper
        name="Lien Information"
        watch={watchedLienInfo}
        triggerValidation={triggerValidation}
        category="lienInfo"
      >
        <FormInput
          name="lienInfo.accountNumber"
          placeholder={
            carrier?.ssnAllowed ? "Account Number (or SSN)" : "Account Number"
          }
          type="text"
          defaultValue={
            packet?.accountNumber
              ? carrier?.ssnAllowed
                ? "Account/SSN (HIDDEN)"
                : "Account Number (HIDDEN)"
              : ""
          }
          register={register}
          unregister={unregister}
          error={lienErrors?.accountNumber}
          required
        />
        <FormInput
          name="lienInfo.ownersName"
          placeholder="Owner Name"
          type="text"
          defaultValue={packet?.ownersName ? packet.ownersName : ""}
          register={register}
          unregister={unregister}
          error={lienErrors?.ownersName}
          required
        />
        <FormInput
          name="lienInfo.ownersEmailAddress"
          placeholder="Owner Email Address"
          type="text"
          defaultValue={
            packet?.ownersEmailAddress ? packet.ownersEmailAddress : ""
          }
          register={register}
          unregister={unregister}
          error={lienErrors?.ownersEmailAddress}
        />
        <div data-testid="OwnerPhoneNumber">
          <FormInput
            name="lienInfo.ownersPhoneNumber"
            placeholder="Owner Phone Number"
            error={lienErrors?.ownersPhoneNumber}
            required
            format="phone"
            defaultValue={
              packet?.ownersPhoneNumber ? packet.ownersPhoneNumber : ""
            }
            control={control}
            formatOnChange
          />
        </div>
        <FormInput
          name="lienInfo.ownersStreetAddress"
          placeholder="Owner Address"
          type="text"
          defaultValue={
            packet?.ownersStreetAddress ? packet.ownersStreetAddress : null
          }
          register={register}
          unregister={unregister}
          error={lienErrors?.ownersStreetAddress}
          required
        />
      </CategoryWrapper>
    </div>
  );
};

export default LienInformation;
