import { flFetch as fetch, validateStatus, validateBlobStatus } from "./fetch";
import * as UpChunk from "@mux/upchunk";

export const createPacket = payload => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets`, {
    method: "POST",
    body: JSON.stringify(payload)
  }).then(validateStatus);
};

export const createPayoffQuote = (packetId, parameter, refresh) => {
  const body = {};
  if (parameter) {
    body.parameter = parameter;
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/payoff-quote${
      refresh ? "?refresh=true" : ""
    }`,
    {
      method: "POST",
      body: JSON.stringify(body)
    }
  ).then(validateStatus);
};

export const createLoGRequest = (packetId, xlogOptions) => {
  const xlogManualAuth = xlogOptions?.xlogManualAuth;
  const requestBody = {};
  if (xlogManualAuth) {
    requestBody.xlogManualAuth = xlogManualAuth;
  }
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/letter-of-guarantee`,
    {
      method: "POST",
      body: JSON.stringify(requestBody)
    }
  ).then(validateStatus);
};

export const fetchPacket = packetId => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/${packetId}`, {
    method: "GET"
  }).then(validateStatus);
};

export const updatePacket = (packetId, payload) => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/${packetId}`, {
    method: "PUT",
    body: JSON.stringify(payload)
  }).then(validateStatus);
};

export const fetchRecentlyUpdatedPackets = carrierId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/carrier/${carrierId}?pageNumber=0&pageSize=10&sortBy=updatedat&sortDirection=desc`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const fetchCarrierCounts = (
  carrierId,
  searchQuery,
  userId,
  showArchived = false
) => {
  let query = "";
  if (searchQuery) {
    query += `searchQuery=${searchQuery}`;
  }

  if (userId) {
    if (query !== "") {
      query += "&";
    }

    query += `userId=${userId}`;
  }

  if (showArchived) {
    if (query !== "") {
      query += "&";
    }

    query += "showArchived=true";
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/carrier/${carrierId}/counts?${query}`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const archivePacket = packetId => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/${packetId}`, {
    method: "DELETE"
  }).then(validateStatus);
};

export const restorePacket = packetId => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/${packetId}/restore`, {
    method: "POST"
  }).then(validateStatus);
};

export const reassignAllUserPackets = (
  userFromId,
  userToId,
  stopNotifications
) => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/users/${userFromId}`, {
    method: "PUT",
    body: JSON.stringify({
      reassignToUser: userToId,
      stopNotifications: stopNotifications
    })
  }).then(validateStatus);
};

export const reassignPacketToUser = (packetId, userId) => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/${packetId}`, {
    method: "PUT",
    body: JSON.stringify({
      reassignToUser: userId
    })
  }).then(validateStatus);
};

export const fetchLetterOfGuaranteeNeeded = packetId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/letter-of-guarantee/needed`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const runAdvancedPacketSearch = (
  status,
  query,
  userId,
  {
    pageNumber = 0,
    pageSize = 10,
    sortColumn = "createdat",
    sortDirection = "asc"
  },
  showArchived = false
) => {
  let q = `?pageNumber=${pageNumber}&pageSize=${pageSize}&sortBy=${sortColumn}&sortDirection=${sortDirection}`;

  if (userId) {
    q += `&userId=${userId}`;
  }

  if (query) {
    q += `&query=${query}`;
  }

  if (status) {
    q += `&status=${status}`;
  }

  if (showArchived) {
    q += "&showArchived=true";
  }

  return fetch(`${process.env.REACT_APP_API_URL}/packets/advanced-search${q}`, {
    method: "GET"
  }).then(validateStatus);
};

export const deleteDocument = documentId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/documents/${documentId}/delete`,
    {
      method: "POST"
    }
  ).then(validateStatus);
};

export const setNotificationPreferenceForPacket = (
  packetId,
  shouldNotify,
  userId
) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/notification-preference/${shouldNotify}`,
    {
      method: "POST",
      body: JSON.stringify({
        userId
      })
    }
  ).then(validateStatus);
};

export const cancelPayoffQuoteRequest = (packetId, payload) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/payoff-quote`,
    {
      method: "PUT",
      body: JSON.stringify(payload)
    }
  ).then(validateStatus);
};

export const cancelLetterOfGuaranteeRequest = (packetId, payload) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/letter-of-guarantee-request`,
    {
      method: "PUT",
      body: JSON.stringify(payload)
    }
  ).then(validateStatus);
};

export const uploadDocumentChunksToClaim = (
  packetId,
  doc,
  type = "unspecified",
  { onProgress = () => {} },
  { claimsSpecialistReviewNeeded = false, settlementCounterAccepted = false }
) => {
  return new Promise((resolve, reject) => {
    if (!doc || doc.size < 1) {
      resolve(doc.name);
      return;
    }

    const upload = UpChunk.createUpload({
      endpoint: file => {
        return fetch(
          `${process.env.REACT_APP_API_URL}/packets/${packetId}/documents/upload-url`,
          {
            method: "POST",
            body: JSON.stringify({
              type,
              size: file.size,
              name: file.name
            })
          }
        )
          .then(validateStatus)
          .then(d => {
            let url = `${process.env.REACT_APP_API_URL}${d.urlPath}`;

            if (claimsSpecialistReviewNeeded) {
              url += "?claimsSpecialistReviewNeeded=true";
            }

            if (settlementCounterAccepted) {
              url += "?settlementCounterAccepted=true";
            }

            return url;
          });
      },
      file: doc,
      chunkSize: 1280,
      headers: {
        "x-le-file-name": doc.name
      }
    });

    upload.on("error", err => {
      // going to make sure this is an error
      reject(new Error(err.detail.message));
    });

    upload.on("progress", progress => {
      onProgress(progress.detail);
    });

    upload.on("success", () => {
      resolve(doc.name);
    });
  });
};

export const addDocumentsToClaim = (
  packetId,
  documents,
  claimsSpecialistReviewNeeded = false,
  settlementCounterAccepted = false
) => {
  let formData = new FormData();
  if (documents && documents.length > 0) {
    for (let i = 0; i < documents.length; i++) {
      if (documents[i] && documents[i].file && documents[i].type) {
        formData.append("documents[].file", documents[i].file);
        formData.append("documents[].type", documents[i].type);
      } else {
        formData.append("documents[].file", documents[i]);
        formData.append("documents[].type", "unspecified");
      }
    }
  }

  if (claimsSpecialistReviewNeeded) {
    formData.append(
      "claimsSpecialistReviewNeeded",
      claimsSpecialistReviewNeeded
    );
  }

  if (settlementCounterAccepted) {
    formData.append("settlementCounterAccepted", settlementCounterAccepted);
  }

  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/documents`,
    {
      method: "POST",
      body: formData
    }
  ).then(validateStatus);
};

export const getDuplicateClaims = claimNumber => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/duplicates?claimNumber=${claimNumber}`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const downloadReceivedDocument = (packetId, receivedDocumentId) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/received-fax/${receivedDocumentId}`,
    {
      method: "GET"
    }
  ).then(validateBlobStatus);
};

export const downloadUploadedDocument = (packetId, documentId) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/document/${documentId}`,
    {
      method: "GET"
    }
  ).then(validateBlobStatus);
};

export const downloadPayoffPDF = packetId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/payoff.pdf`,
    {
      method: "GET"
    }
  ).then(validateBlobStatus);
};

export const fetchAccountNumberOrSSN = packetId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/account-number/${packetId}`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const createOrderRequest = (packetId, types) => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/${packetId}/orders`, {
    method: "POST",
    body: JSON.stringify({
      types
    })
  }).then(validateStatus);
};

export const cancelOrderRequest = (packetId, orderId, type, status) => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/orders/${orderId}`, {
    method: "PUT",
    body: JSON.stringify({
      packetId,
      type,
      status
    })
  }).then(validateStatus);
};

export const fetchFulfilledOrders = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/orders-fulfilled`, {
    method: "GET"
  }).then(validateStatus);
};

export const fetchFulfilledEvents = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/packets/events-fulfilled`, {
    method: "GET"
  }).then(validateStatus);
};

export const runOrdersSearch = (candidateSearchParams, pagination) => {
  const query = new URLSearchParams();

  if (candidateSearchParams.userId === "all") {
    appendUrlSearchParams(query, candidateSearchParams, {
      ignoreKey: "userId"
    });
  } else {
    appendUrlSearchParams(query, candidateSearchParams, {
      ignoreKey: "requestingCompanyId"
    });
  }

  appendUrlSearchParams(query, pagination);

  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/orders-search?${query}`,
    {
      method: "GET"
    }
  ).then(validateStatus);

  function appendUrlSearchParams(
    urlSearchParams,
    candidateSearchParams,
    options
  ) {
    let ignoreKey = undefined;

    if (
      typeof options === "object" &&
      Array.isArray(options) === false &&
      typeof options.ignoreKey === "string"
    ) {
      ignoreKey = options.ignoreKey;
    }

    for (const key in candidateSearchParams) {
      if (Object.hasOwnProperty.call(candidateSearchParams, key)) {
        const value = candidateSearchParams[key];
        if (key === ignoreKey) continue;
        if (value === null || value === undefined || value === "") continue;
        urlSearchParams.append(key, value);
      }
    }

    return urlSearchParams;
  }
};

export const searchClaims = input => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/search-claims${
      input && input !== "" ? `?query=${input}` : ""
    }`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const fetchxApiPacket = (claimId, token) => {
  return fetch(`${process.env.REACT_APP_X_API_URL}/claims/${claimId}`, {
    method: "GET",
    headers: { authorization: `Bearer ${token}` }
  }).then(validateStatus);
};
