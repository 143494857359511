import React, { useState } from "react";

import "./ForgotPassword.css";

import { FormInput, Button } from "@fastlane-llc/lossexpress-ui-kit";

import Success from "../Success";
import Error from "../Error";

import { sendPasswordReset } from "../../services/users";
import { useForm } from "react-hook-form";
import Footer from "../Footer/Footer";
import XLienSideWrapper from "../XLienSideWrapper";

const ForgotPassword = () => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });

  const sendRequest = async data => {
    try {
      await sendPasswordReset(data.username);
      setSuccess(
        "We have sent the password reset request to any matching account."
      );
    } catch (ex) {
      setError(
        "There was an unexpected error submitting the password reset request. Please contact LossExpress Support."
      );
    }
  };

  return (
    <XLienSideWrapper>
      {success && <Success>{success}</Success>}
      {error && <Error>{error}</Error>}
      <div className="LoginTitle">Forgot Password?</div>
      <p>
        Enter your username below, and we'll send an email with information
        needed to reset your password.
      </p>

      <form onSubmit={handleSubmit(sendRequest)}>
        <FormInput
          name="username"
          register={register}
          required
          placeholder="Username"
          error={errors.username}
        />

        <Button>Submit Password Reset Request</Button>
      </form>
      <Footer />
    </XLienSideWrapper>
  );
};

export default ForgotPassword;
