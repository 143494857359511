import format from "date-fns/format";

export const formatDateGMT = date => {
  if (date !== null && date !== "") {
    const [year, month, day] = date.substr(0, 10).split("-");
    return format(new Date(year, month - 1, day), "MM/DD/YYYY");
  }

  return null;
};

export const formatDateTimeGMT = date => {
  if (date !== null && date !== "") {
    return format(new Date(date), "MM/DD/YYYY h:mma");
  }

  return null;
};

export const formatTimeGMT = date => {
  if (date !== null && date !== "") {
    return format(new Date(date), "h:mma");
  }

  return null;
};
