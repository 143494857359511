import React, { useEffect, useState } from "react";
import queryString from "query-string";

import { performSsoHandshake } from "../../services/auth-request";
import Loading from "../Loading";
import { Redirect } from "react-router-dom";

const SSOSuccess = ({ location }) => {
  const [redirectToClaim, setRedirectToClaim] = useState('');
  const [redirectToHome, setRedirectToHome] = useState(false);

  useEffect(() => {
    (async function() {
      try {
        const values = queryString.parse(location.search);
        await performSsoHandshake(values.sid);
        if (values.claim) {
          return setRedirectToClaim(values.claim);
        }
        setRedirectToHome(true);
      } catch (ex) {
        setRedirectToHome(true);
      }
    })();
  }, [location.search, redirectToClaim]);

  return (
    <div>
      {redirectToHome && <Redirect to="/home" />}

      {!!redirectToClaim && <Redirect to={`/claims/${redirectToClaim}`} />}

      <Loading show />
    </div>
  );
};

export default SSOSuccess;
