export const formatPhoneNumber = number => {
  if (!number) {
    return "";
  }

  let num = number.replace(/[^0-9]/g, "");

  while (num.startsWith(1) || num.startsWith(0)) {
    num = num.slice(1);
  }

  const areaCode = num.substr(0, 3);
  const firstThree = num.substr(3, 3);
  const lastFour = num.substr(6, 4);
  const extension = num.substr(10);

  if (num.length === 0) {
    return "";
  } else if (num.length < 4) {
    return `(${areaCode})`;
  } else if (num.length < 7) {
    return `(${areaCode}) ${firstThree}`;
  } else if (num.length < 11) {
    return `(${areaCode}) ${firstThree}-${lastFour}`;
  }

  return `(${areaCode}) ${firstThree}-${lastFour} e.${extension}`;
};

export const formatStringFirstUpperRestLower = str => {
  if (!str) {
    return "";
  }
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
};

export const getDayFromDate = date => {
  let day = date.match(/(?<=-)\d{1,2}(?=T)/g)[0];
  if (day.startsWith("0")) {
    day = day[1];
  }
  let suffix;
  switch (day) {
    case "1":
    case "21":
    case "31":
      suffix = "st";
      break;
    case "2":
    case "22":
      suffix = "nd";
      break;
    case "3":
    case "23":
      suffix = "rd";
      break;
    default:
      suffix = "th";
  }
  return `${day}${suffix}`;
};
