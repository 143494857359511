import React, { useCallback, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretLeft,
  faCaretRight,
  faTimes,
  faCircle
} from "@fortawesome/free-solid-svg-icons";
import "./Modal.css";
import useOnClickOutside from "../../hooks/use-onclickoutside";

const Modal = ({
  onClose,
  children,
  style,
  noCloseButton = false,
  title,
  tutorial = false,
  numPages = 3,
  page2,
  page3
}) => {
  const [pageNumber, setPageNumber] = useState(1);

  const closeModal = useCallback(() => {
    onClose(false);
  }, [onClose]);

  const ref = useRef();
  useOnClickOutside(ref, closeModal);

  const changePage = offset => {
    setPageNumber(prev => prev + offset);
  };

  const previousPage = useCallback(() => {
    if (pageNumber > 1) {
      changePage(-1);
    }
  }, [pageNumber]);

  const nextPage = useCallback(() => {
    if (pageNumber < numPages) {
      changePage(1);
    }
  }, [pageNumber, numPages]);

  return (
    <div className="ModalWrapper">
      <div className="ModalBackground">
        <div style={style} className="Modal" ref={ref}>
          {!noCloseButton && (
            <div onClick={onClose} className="ModalClose">
              <FontAwesomeIcon
                icon={faTimes}
                style={{ marginRight: ".25em" }}
              />
              close
            </div>
          )}
          <div className="ModalTitle">{title}</div>

          {pageNumber === 1 && children}
          {pageNumber === 2 && page2}
          {pageNumber === 3 && page3}

          {tutorial && (
            <div className="TutorialIconsWrapper">
              <div className="ModalTutorialIcons">
                <span className="CaretIcon" onClick={previousPage}>
                  <FontAwesomeIcon
                    icon={faCaretLeft}
                    style={{ marginRight: ".5em", fontSize: "1.33em" }}
                  />
                </span>
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    marginRight: ".25em",
                    fontSize: ".9em",
                    color:
                      pageNumber === 1
                        ? "rgb(16, 107, 163)"
                        : "rgb(206, 217, 224)",
                    paddingBottom: ".2em"
                  }}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    marginRight: ".25em",
                    fontSize: ".9em",
                    color:
                      pageNumber === 2
                        ? "rgb(16, 107, 163)"
                        : "rgb(206, 217, 224)",
                    paddingBottom: ".2em"
                  }}
                />
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    fontSize: ".9em",
                    color:
                      pageNumber === 3
                        ? "rgb(16, 107, 163)"
                        : "rgb(206, 217, 224)",
                    paddingBottom: ".2em"
                  }}
                />
                <span className="CaretIcon" onClick={nextPage}>
                  <FontAwesomeIcon
                    icon={faCaretRight}
                    style={{ marginLeft: ".5em", fontSize: "1.33em" }}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
