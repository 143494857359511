import React, { useEffect, useState } from "react";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";

import Modal from "../Modal";
import { useForm } from "react-hook-form";

const CancelQuoteModal = ({ close, onCancelRequest }) => {
  const [showPayoffInfoForm, setShowPayoffInfoForm] = useState(false);

  const { errors, handleSubmit, register, setValue, watch } = useForm({
    mode: "onBlur"
  });

  const reason = watch("cancellationReason");
  const date = watch("validThroughDate");

  useEffect(() => {
    if (
      reason === "Lender reached out and gave payoff directly" ||
      reason === "Customer gave payoff information"
    ) {
      setShowPayoffInfoForm(true);
    } else {
      setShowPayoffInfoForm(false);
    }
  }, [reason]);

  useEffect(() => {
    register({ name: "validThroughDate" });
  }, [register]);

  const onChange = e => {
    setValue("cancellationReason", e.target.value);
  };

  const submit = data => {
    const d = Object.keys(data).reduce((obj, k) => {
      if (data[k] === "" || data[k] == null) {
        return obj;
      }

      obj[k] = data[k];
      return obj;
    }, {});

    d.reason = data.cancellationReason;
    delete d.cancellationReason;

    onCancelRequest(d);
  };

  return (
    <Modal onClose={close}>
      <h1 className="ModalSuccessHeader">Cancel Payoff Request</h1>
      <p>
        We're already hard at work on fulfilling this payoff request. Let us
        know why you'd like to cancel this request.
      </p>

      <form onSubmit={handleSubmit(submit)} data-testid="CancelQuoteForm">
        <FormInput
          error={errors.cancellationReason}
          name="cancellationReason"
          onChange={onChange}
          register={register}
          value={reason}
          label="Cancellation Reason"
          options={[
            "Lender reached out and gave payoff directly",
            "Customer gave payoff information",
            "Other"
          ]}
          required
          type="radio"
        />

        {showPayoffInfoForm && (
          <div>
            <p>
              Please provide the payoff information below so we can help with
              any future requests on this claim. (optional)
            </p>

            <FormInput
              error={errors.payoffAmount}
              label="Payoff Amount"
              name="payoffAmount"
              placeholder="Payoff Amount"
              register={register}
            />
            <FormInput
              error={errors.perDiem}
              label="Per Diem"
              name="perDiem"
              placeholder="Per Diem"
              register={register}
            />
            <FormInput
              error={errors.validThroughDate}
              label="Valid Through Date"
              name="validThroughDate"
              onChange={setValue}
              register={register}
              placeholder="Valid Through Date"
              type="date"
              value={date}
            />
          </div>
        )}

        {reason === "Other" && (
          <FormInput
            error={errors.cancellationExplanation}
            name="cancellationExplanation"
            label="Cancellation Explanation"
            type="textarea"
            register={register}
            required
          />
        )}

        <Button>Submit Cancellation Request</Button>
      </form>
    </Modal>
  );
};

export default CancelQuoteModal;
