import React, { useState, useEffect } from "react";
import {
  FileInput,
  useFileInputs,
  Button,
  FormInput
} from "@fastlane-llc/lossexpress-ui-kit";
import { useForm } from "react-hook-form";
import * as Sentry from "@sentry/browser";
import { addDocumentsToClaim } from "../../services/packets";
import Error from "../Error";

import { capitalCase } from "change-case";
import { updateDispute } from "../../services/faxes";

import Modal from "../Modal";
import "./CounterOfferModal.css";

const CounterOfferModal = ({
  packet,
  handleCloseModal,
  downloadDoc,
  downloadReceivedDoc,
  counterId,
  formatMoney,
  refreshPacket
}) => {
  const [fileUploadError, setFileUploadError] = useState(null);
  const [counterOfferDocuments, handleCounterOfferDocuments] = useFileInputs(
    ["image/png", "image/jpg", "image/jpeg", "image/gif", "application/pdf"],
    setFileUploadError
  );
  const [showDisputeBox, setShowDisputeBox] = useState(false);
  const [error, setError] = useState("");

  const { errors, handleSubmit, register } = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    const onAcceptCounterOffer = async () => {
      try {
        await addDocumentsToClaim(
          packet.packetId,
          Object.keys(counterOfferDocuments).map(key => ({
            file: counterOfferDocuments[key],
            type: "settlement breakdown (counter accepted)"
          })),
          false,
          true
        );
        refreshPacket();
        handleCloseModal();
      } catch (ex) {
        Sentry.captureException(ex);
        setFileUploadError("There was an error adding documents to the claim");
      }
    };

    if (Object.keys(counterOfferDocuments).length > 0) {
      onAcceptCounterOffer();
    }
  }, [counterOfferDocuments, handleCloseModal, packet, refreshPacket]);

  const modalStyle = { width: "40em" };

  const toggleDisputeCounter = () => {
    setShowDisputeBox(!showDisputeBox);
  };

  const submitDisputeDescription = data => {
    const packetId = { packetId: packet.packetId };
    data = { ...data, ...packetId };
    try {
      updateDispute(counterId, data);
      refreshPacket();
      handleCloseModal();
    } catch (ex) {
      Sentry.captureException(ex);
      setError("There was an error processing your dispute, please try again.");
    }
  };

  return (
    <div className="CounterOfferModal">
      <Modal onClose={handleCloseModal} style={modalStyle}>
        <h3 className="CounterHeader" data-testid="CounterOfferModal">
          This claim's settlement amount has been countered by the lienholder,
          and a response is urgently needed.
        </h3>
        {fileUploadError && <Error>{fileUploadError}</Error>}
        <div className="ModalCenter">
          <div className="ModalLeft">
            <span>
              <span className="ModalBold">Claim #: </span>
              {packet && packet.claimNumber ? packet.claimNumber : "N/A"}
            </span>
            <span>
              <span className="ModalBold">VIN: </span>
              {packet && packet.vin ? packet.vin : "N/A"}
            </span>
            <span>
              <span className="ModalBold">Payoff Amount: </span>{" "}
              {packet &&
              packet.payoffQuote &&
              packet.payoffQuote.paymentInformation &&
              packet.payoffQuote.paymentInformation.netAmount
                ? formatMoney(packet.payoffQuote.paymentInformation.netAmount)
                : "N/A"}
            </span>
            <div className="ModalDocumentsLeft">
              {packet.documents &&
                packet.documents.length > 0 &&
                packet.documents.map((doc, i) => (
                  <div key={doc.documentId}>
                    {!doc.deleted && (
                      <div className="ClaimViewInfoWrapper" key={i}>
                        <div onClick={downloadDoc(doc)}>
                          <i className="fas fa-file-download" />{" "}
                          {doc.type ===
                          "valuation report & settlement breakdown"
                            ? "Valuation Report & Settlement Breakdown"
                            : doc.type
                            ? capitalCase(doc.type)
                            : "Uncategorized"}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>
          <div className="ModalRight">
            {packet?.receivedDocuments?.map(fax => (
              <div key={fax.receivedFaxId}>
                <span className="ModalBold">Reason for Counter: </span>
                <br />
                <div className="CounterReason">
                  {fax?.faxData?.counterOffer
                    ? fax.faxData.counterOffer
                    : "No description available."}
                </div>
              </div>
            ))}
            <div className="IncomingCounterOffer">
              {packet.receivedDocuments &&
                packet.receivedDocuments.length > 0 &&
                packet.receivedDocuments.map((doc, i) => (
                  <div key={doc.receivedFaxId}>
                    <div className="ClaimViewInfoWrapper" key={i}>
                      <div onClick={downloadReceivedDoc(doc)}>
                        <i className="fas fa-file-download" />{" "}
                        {doc.counterOffer ? "Lender Counter" : doc.description}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        {!showDisputeBox && (
          <>
            <FileInput
              buttonProps={{
                invert: true,
                size: "full-width"
              }}
              name="counterOfferDocument"
              label="Attach New Settlement Breakdown (Accept Counter)"
              dragAndDropSupport
              acceptedExtensions=".pdf,.png,.jpg,.jpeg,.gif"
              onChange={handleCounterOfferDocuments}
            />
            <div className="DisputeOption" onClick={toggleDisputeCounter}>
              We would like to dispute this counter
            </div>
          </>
        )}
        {showDisputeBox && (
          <div className="DisputeForm">
            <form
              onSubmit={handleSubmit(submitDisputeDescription)}
              data-testid="DisputeForm"
            >
              <FormInput
                name="disputeDescription"
                type="text"
                register={register}
                error={errors.disputeDescription}
                required
                placeholder={
                  "Reason for disputing the counter (please be as descriptive as possible)"
                }
              />
              <div className="Disclaimer">
                Please note that disrupting lender counters can drastically
                increase cycle times in ways the LossExpress is not able to
                control.
              </div>
              <div className="DisputeButton">
                <Button color="red" invert size="full-width">
                  File a Dispute
                </Button>
              </div>
              <div className="DisputeOption" onClick={toggleDisputeCounter}>
                We would like to attach a new settlement breakdown
              </div>
              {error && <span className="DisputeError">{error}</span>}
            </form>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default CounterOfferModal;
