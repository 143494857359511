import React, { useState, useEffect, useCallback, isValidElement } from "react";
import propTypes from "prop-types";
import "./CategoryWrapper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

const CategoryWrapper = ({
  children,
  name,
  watch,
  triggerValidation,
  category
}) => {
  const [kids, setKids] = useState([]);
  const [hide, setHide] = useState(false);
  const [complete, setComplete] = useState(false);
  const [firstCompleteDone, setFirstCompleteDone] = useState(false);

  const validateForms = useCallback(
    async (noHide = true) => {
      let valid;
      if (category && watch && Object.keys(watch).length > 0) {
        for (const key of Object.keys(watch)) {
          if (watch[key] !== "" && watch[key] !== undefined) {
            valid = await triggerValidation(`${category}.${key}`);
          }
          if (!valid || watch[key] === "") {
            setComplete(false);
            setHide(false);
            return;
          }
        }

        if (!firstCompleteDone && !noHide && complete) {
          setHide(true);
          setFirstCompleteDone(true);
        }

        setComplete(true);
      }
    },
    [watch, category, complete, triggerValidation, firstCompleteDone]
  );

  useEffect(() => {
    const c = React.Children.map(children, child => {
      if (isValidElement(child)) {
        return React.cloneElement(child, { onBlur: validateForms });
      }
    });
    setKids(c);
  }, [children, validateForms]);

  useEffect(() => {
    validateForms(true);
  }, [validateForms, watch]);

  return (
    <div className="CategoryContainer">
      <div
        className={`CategoryTitle${complete ? " Complete" : ""}`}
        onClick={() => (complete ? setHide(!hide) : "")}
      >
        {name !== "Claim Information" && (
          <h3>
            {name}
            {complete && (
              <div className="CompletedIcon">
                <FontAwesomeIcon icon={faCheckCircle} />
              </div>
            )}
          </h3>
        )}
      </div>
      <div className={`Children ${hide ? "HideChildren" : ""}`}>{kids}</div>
    </div>
  );
};

CategoryWrapper.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.element),
    propTypes.element.isRequired
  ]),
  name: propTypes.string,
  watch: propTypes.object
};

export default CategoryWrapper;
