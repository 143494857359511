export const styles = {
  headerIcon: {
    marginRight: ".3em",
    fontSize: "1.2em"
  },
  infoIcon: {
    fontSize: ".7em",
    paddingBottom: ".1em",
    color: "#979797"
  },
  downloadIcon: {
    fontSize: "1.3em",
    marginRight: ".5em",
    cursor: "pointer",
    color: "#979797"
  },
  orderCheckIcon: {
    fontSize: "1.25em",
    marginRight: ".5em",
    color: "#008CD5"
  },
  orderInfoIcon: {
    fontSize: "1.25em",
    marginRight: ".5em",
    color: "#979797"
  },
  counterOfferIcon: {
    color: "#C23030",
    fontSize: "1.3em",
    marginRight: "1em"
  },
  orderCancelledIcon: {
    fontSize: "1.25em",
    marginRight: ".5em",
    color: "#C23030"
  },
  barIcon: {
    fontSize: "1.2em",
    color: "#6B7A82"
  },
  barIconExpanded: {
    fontSize: "1.2em",
    color: "#008CD5"
  },
  barIconVerified: {
    fontSize: "1.2em",
    paddingRight: ".8em",
    color: "#008CD5"
  },
  cloudUpload: {
    fontSize: "1.2em",
    color: "#9FA6B1",
    marginBottom: ".5em"
  },
  requestFormIcon: {
    fontSize: "1.3em",
    marginRight: ".7em",
    color: "#9FA6B1"
  },
  requestFormIconRight: {
    fontSize: "1.3em",
    color: "#9FA6B1"
  },
  requestFormIconRightActive: {
    fontSize: "1.3em",
    color: "#008CD5"
  },
  requestFormIconActive: {
    fontSize: "1.3em",
    marginRight: ".7em",
    color: "#008CD5"
  },
  directMessageIconActive: {
    border: "2px solid #008CD5"
  },
  tableChevron: {
    float: "right",
    color: "#1E282C",
    cursor: "pointer"
  },
  angleDown: {
    fontSize: "1.5em"
  }
};
