import React, { forwardRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFrown } from "@fortawesome/free-regular-svg-icons";

import "./MetabaseDashboard.css";

const iconStyle = {
  color: "rgba(251, 154, 154, 0.987)",
  marginTop: "0.3em"
};

const MetabaseDashboard = forwardRef((props, ref) => {
  const {
    url,
    title,
    className,
    iframeWidth,
    iframeHeight,
    iframeStyle,
    onLoad,
    isMetabaseOk,
    errorStyle
  } = props;

  return (
    <>
      {isMetabaseOk ? (
        <div className={"metabase-dashboard__container " + className}>
          <iframe
            style={iframeStyle}
            title={title}
            src={url}
            width={iframeWidth ?? "100%"}
            height={iframeHeight ?? "100%"}
            onLoad={onLoad}
            ref={ref}
            scrolling="no"
            allowtransparency="true"
          />
        </div>
      ) : (
        <div className="metabase-dashboard-error__container" style={errorStyle}>
          <div>
            <span className="metabase-dashboard-error__container-header">
              Sorry, something went wrong. Try refreshing the page.
            </span>
          </div>
          <FontAwesomeIcon icon={faFrown} size="5x" style={iconStyle} />
        </div>
      )}
    </>
  );
});

export default MetabaseDashboard;
