import { flFetch as fetch, validateStatus, validateBlobStatus } from "./fetch";

export const fetchCurrentUsersList = () => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/info/current-carrier/users`
  ).then(validateStatus);
};

export const acknowledgeDisclaimer = () => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/payoff/user/acknowledge-disclaimer`,
    {
      method: "POST"
    }
  ).then(validateStatus);
};

export const fetchUsers = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/users`).then(
    validateStatus
  );
};

export const deactivateUser = user => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/user/${user.userId}`, {
    method: "DELETE"
  }).then(validateStatus);
};

export const addUser = (
  username,
  emailAddress,
  userRole,
  name,
  mailingStreetAddress,
  mailingCity,
  mailingState,
  mailingZipCode,
  mailingAdditionalInfo,
  phoneNumber,
  requestingCompanyId
) => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/users`, {
    method: "POST",
    body: JSON.stringify({
      username,
      emailAddress,
      userRole,
      name,
      mailingStreetAddress,
      mailingCity,
      mailingState,
      mailingZipCode,
      mailingAdditionalInfo,
      phoneNumber,
      requestingCompanyId
    })
  }).then(validateStatus);
};

export const addBatchUsers = (users, requestingCompanyId) => {
  const data = {
    users: users.map(
      ([
        name,
        emailAddress,
        phoneNumber,
        userRole,
        mailingStreetAddress,
        mailingCity,
        mailingState,
        mailingZipCode,
        mailingAdditionalInfo
      ]) => ({
        name,
        emailAddress,
        phoneNumber,
        userRole,
        mailingStreetAddress,
        mailingCity,
        mailingState,
        mailingZipCode,
        mailingAdditionalInfo,
        requestingCompanyId
      })
    )
  };

  return fetch(`${process.env.REACT_APP_API_URL}/payoff/users/batch-add`, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(validateStatus);
};

export const getAccountInfo = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/user/current-user`, {
    method: "GET"
  }).then(validateStatus);
};

export const getStateFarmMetabaseReportUrl = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/info/reporting/sf-report`, {
    method: "GET"
  }).then(validateStatus);
};

export const sendPasswordReset = username => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/auth/reset-password`, {
    method: "POST",
    body: JSON.stringify({
      username
    })
  }).then(validateStatus);
};

export const downloadData = data => {
  return fetch(`${process.env.REACT_APP_API_URL}/info/data-download`, {
    method: "POST",
    body: JSON.stringify(data)
  }).then(validateBlobStatus);
};

export const updateUserSettings = ({
  emailNotificationPayoffQuote,
  emailNotificationLoG
}) => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/user/edit-settings`, {
    method: "PUT",
    body: JSON.stringify({
      emailNotificationPayoffQuote,
      emailNotificationLoG
    })
  }).then(validateStatus);
};

export const getReportingMonths = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/info/billables/timeframes`, {
    method: "GET"
  }).then(validateStatus);
};

export const downloadBillingReport = (startDate, endDate) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/info/billables/monthly-report.csv?startDate=${startDate}&endDate=${endDate}`,
    {
      method: "GET"
    }
  ).then(validateBlobStatus);
};

export const editUser = (userId, { userRole, emailAddress }) => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/user/${userId}`, {
    method: "PUT",
    body: JSON.stringify({
      userRole,
      emailAddress
    })
  }).then(validateStatus);
};

export const resendWelcomeEmail = (userId, { emailAddress }) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/payoff/user/${userId}/resend-email`,
    {
      method: "POST",
      body: JSON.stringify({
        emailAddress
      })
    }
  ).then(validateStatus);
};

export const sendForgotUsernameRequest = emailAddress => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/auth/forgot-username`, {
    method: "POST",
    body: JSON.stringify({
      emailAddress
    })
  }).then(validateStatus);
};

export const getGenericMetabaseReportUrl = () => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/info/reporting/general-report`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};
