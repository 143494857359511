import { flFetch as fetch, validateStatus } from "./fetch";

export const sendMessage = (packetId, message) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/messages`,
    {
      method: "POST",
      body: JSON.stringify({ message })
    }
  ).then(validateStatus);
};

export const getClaimMessages = (packetId, date) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/messages${
      date ? `?from=${date}` : ""
    }`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const setMessageAsSeen = messageId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/messages/${messageId}/mark-as-seen`,
    {
      method: "PUT"
    }
  ).then(validateStatus);
};

export const getRecentMessages = date => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/messages${
      date ? `?from=${date}` : ""
    }`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const sendMessageXapi = (claimId, message, token) => {
  return fetch(
    `${process.env.REACT_APP_X_API_URL}/claims/${claimId}/direct-messages`,
    {
      method: "POST",
      headers: { authorization: `Bearer ${token}` },
      body: JSON.stringify({ message })
    }
  ).then(validateStatus);
};

export const getClaimMessagesXapi = (claimId, token) => {
  return fetch(
    `${process.env.REACT_APP_X_API_URL}/claims/${claimId}/direct-messages`,
    {
      method: "GET",
      headers: { authorization: `Bearer ${token}` }
    }
  ).then(validateStatus);
};
