import { useForm } from "react-hook-form";
import React from "react";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";
import * as Sentry from "@sentry/browser";

import ClaimInformation from "./ClaimInformation";
import AdjusterInformation from "./AdjusterInformation";
import VehicleInformation from "./VehicleInformation";
import LienInformation from "./LienInformation";
import Documentation from "./Documentation";
import {
  createPacket,
  createPayoffQuote,
  updatePacket
} from "../../services/packets";
import "./RequestForm.css";
import OrderingMenu from "../OrderingMenu";
import {
  faClipboard,
  faFolderPlus,
  faCheckSquare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { styles } from "../ClaimView/IconStyles";

const RequestForm = ({
  carrier = {},
  packet = {},
  lender = {},
  setError = () => {},
  setPacket = () => {},
  saving,
  setSaving = () => {},
  onLenderSelect = () => {},
  updatingClaim = false,
  cancelUpdating = () => {},
  refreshPacket = () => {},
  user = {},
  error = null,
  setRedirect = () => {},
  setShowOrderingMenu = () => {},
  secondStep,
  setSecondStep = () => {},
  savingDocs,
  handleDocuments = () => {},
  fileUploadProgress,
  thirdStep,
  setThirdStep = () => {},
  workPacket,
  setRequestCompleted = () => {},
  isVehicleLeased = false,
  setIsVehicleLeased = () => {}
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    errors,
    unregister,
    triggerValidation,
    control,
    setError: setFormError,
    clearError: clearFormError
  } = useForm({
    mode: "onBlur"
  });

  const onFormSubmit = async data => {
    setSaving(true);

    let p = packet;

    let payload = {
      lenderId: lender.lenderId ? lender.lenderId : undefined,
      lenderName: lender.lenderName,
      carrierId: carrier.carrierId
    };

    for (const key of Object.keys(data)) {
      payload = Object.assign({}, data[key], payload);
    }

    payload = Object.keys(payload).reduce((o, key) => {
      if (
        payload[key] !== "" &&
        payload[key] !== "Account/SSN (HIDDEN)" &&
        payload[key] !== "Account Number (HIDDEN)" &&
        payload[key] !== null
      ) {
        o[key] = payload[key];
      }

      return o;
    }, {});

    if (payload.settlementAmount) {
      payload.settlementAmount = payload.settlementAmount
        .replace(/,/g, "")
        .replace("$", "");
    }

    if (payload.deductible) {
      payload.deductible = payload.deductible
        .replace(/,/g, "")
        .replace("$", "");
    }

    if (payload.insurerType) {
      payload.insurerType = "Third Party";
    } else {
      payload.insurerType = "First Party";
    }

    if (payload.financeType) {
      payload.financeType = "Lease";
    } else {
      payload.financeType = "Retail";
    }

    if (carrier.onlyAutomatedLenders) {
      if (payload.vin) {
        payload.claimNumber = payload.vin;
      } else {
        payload.claimNumber = payload.accountNumber;
      }
    }

    if (updatingClaim && p && p.packetId) {
      p = await updatePacket(p.packetId, payload);
      setPacket(p);
      cancelUpdating();
      refreshPacket();
      setSaving(false);
      setShowOrderingMenu(true);
      return;
    }
    if (!p || !p.packetId) {
      try {
        p = await createPacket(payload);
        setPacket(p);
        setSaving(false);

        if (carrier.onlyAutomatedLenders) {
          setSaving(true);
          await createPayoffQuote(p.packetId);
          setSaving(false);
          setRequestCompleted(true);
          return;
        }

        setSecondStep(true);

        if (!lender.immediatePayoffAvailable) {
          setRedirect(p.packetId);
        }
      } catch (ex) {
        Sentry.captureException(ex);
        setError(
          "There was an error creating a payoff quote request on that claim. Please refresh the page and try to submit your claim again. If this issue persists, please contact technical support by click the blue box in the bottom left corner."
        );
        setSaving(false);
      }
    }
  };

  const onChange = (name, value) => {
    setValue(name, value);
  };

  return (
    <>
      {((updatingClaim && packet) ||
        (!updatingClaim && !packet && !secondStep && !thirdStep)) && (
        <div className="RequestForm">
          <div className="TitleContainer">
            <h2>
              {!packet
                ? "New Claim Order"
                : !packet.payoffQuote
                ? "Finish Claim Order"
                : updatingClaim
                ? "Update Claim Order"
                : ""}
            </h2>
            {!updatingClaim && !carrier.onlyAutomatedLenders && (
              <div>
                <FontAwesomeIcon
                  icon={faClipboard}
                  style={styles.requestFormIconActive}
                />
                <span className="StepNumber Active">1</span>
                <FontAwesomeIcon
                  icon={faFolderPlus}
                  style={styles.requestFormIcon}
                />
                <span className="StepNumber">2</span>
                <FontAwesomeIcon
                  icon={faCheckSquare}
                  style={styles.requestFormIconRight}
                />
                <span className="StepNumber3">3</span>
              </div>
            )}
          </div>
          <form
            data-testid="RequestForm"
            onSubmit={handleSubmit(data => onFormSubmit(data))}
          >
            <ClaimInformation
              carrier={carrier}
              register={register}
              packet={packet}
              errors={errors}
              watch={watch}
              setValue={setValue}
              onChange={onChange}
              unregister={unregister}
              triggerValidation={triggerValidation}
              updatingClaim={updatingClaim}
              control={control}
              onLenderSelect={onLenderSelect}
              isVehicleLeased={isVehicleLeased}
              setError={setFormError}
              clearError={clearFormError}
            />
            {!carrier.onlyAutomatedLenders && (
              <div>
                <AdjusterInformation
                  carrier={carrier}
                  register={register}
                  packet={packet}
                  errors={errors}
                  watch={watch}
                  unregister={unregister}
                  triggerValidation={triggerValidation}
                  control={control}
                  user={user}
                />
                <VehicleInformation
                  carrier={carrier}
                  register={register}
                  packet={packet}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  unregister={unregister}
                  onChange={onChange}
                  triggerValidation={triggerValidation}
                  setIsVehicleLeased={setIsVehicleLeased}
                />
                <LienInformation
                  name="Lien Information"
                  register={register}
                  packet={packet}
                  errors={errors}
                  watch={watch}
                  setValue={setValue}
                  unregister={unregister}
                  onChange={onChange}
                  triggerValidation={triggerValidation}
                  control={control}
                  carrier={carrier}
                />
              </div>
            )}
            {error && <p className="FormRequiredError">{error}</p>}
            {!carrier.onlyAutomatedLenders && (
              <div className="InitialNextStepButton">
                <Button size="full-width" disabled={saving}>
                  {updatingClaim ? "Update Claim" : "Next Step"}
                </Button>
              </div>
            )}
            {carrier.onlyAutomatedLenders && (
              <div className="InitialNextStepButton">
                <Button size="full-width" disabled={saving}>
                  Submit
                </Button>
              </div>
            )}
          </form>
        </div>
      )}
      {!updatingClaim && secondStep && !thirdStep && (
        <Documentation
          register={register}
          packet={packet}
          errors={errors}
          watch={watch}
          setValue={setValue}
          unregister={unregister}
          savingDocs={savingDocs}
          handleDocuments={handleDocuments}
          fileUploadProgress={fileUploadProgress}
          setSecondStep={setSecondStep}
          setThirdStep={setThirdStep}
        />
      )}
      {!updatingClaim && !secondStep && thirdStep && (
        <OrderingMenu
          packet={packet}
          setError={setError}
          setPacket={setPacket}
          refreshPacket={refreshPacket}
          workPacket={workPacket}
          updatingClaim={updatingClaim}
          carrier={carrier}
          setSaving={setSaving}
          setThirdStep={setThirdStep}
          setShowOrderingMenu={setShowOrderingMenu}
          fromRequestForm
          setRequestCompleted={setRequestCompleted}
        />
      )}
    </>
  );
};

export default RequestForm;
