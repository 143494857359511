import React from "react";

import "./Loading.css";

const Loading = function(props) {
  return (
    props.show === true && (
      <div className="LoadingOverlay">
        <div className="wrapper">
          <h2>Loading...</h2>
          <p>{props.text}</p>
          <div className="LoadingBackground">
            <div className="Divider" />
            <div className="Divider" />
            <div className="Divider" />
            <div className="Divider" />
            <div className="LoadingColor" />
          </div>
        </div>
      </div>
    )
  );
};

export default Loading;
