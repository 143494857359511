import React from "react";
import "./Documentation.css";
import { Button, FileInput } from "@fastlane-llc/lossexpress-ui-kit";
import { FileUploadProgressBar } from "../../SideContainer/SideContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCheckSquare,
  faClipboard,
  faFolderPlus
} from "@fortawesome/free-solid-svg-icons";
import { styles } from "../../ClaimView/IconStyles";

const Documentation = ({
  packet = {},
  savingDocs,
  handleDocuments = () => {},
  fileUploadProgress,
  setSecondStep = () => {},
  setThirdStep = () => {}
}) => {
  const isFirstPartyClaim = packet?.insurerType === "First Party";

  const hasExistingDocumentType = type => {
    return packet?.documents?.some(document => document.type === type);
  };

  const hasRequiredDocuments =
    (packet?.documents?.some(
      document => document.type === "settlement breakdown"
    ) &&
      packet?.documents?.some(
        document => document.type === "valuation report"
      )) ||
    packet?.documents?.some(
      document => document.type === "valuation report & settlement breakdown"
    );

  const skipRequiredDocs = packet?.carrier?.uploadingDocsOptional;

  let disabled = skipRequiredDocs ? false : !hasRequiredDocuments;

  const navigateToOrderingMenu = () => {
    setSecondStep(false);
    setThirdStep(true);
  };

  return (
    <div className="DocumentationWrapper">
      <>
        <div className="TitleContainer">
          <h2>Document Upload</h2>
          <div>
            <FontAwesomeIcon
              icon={faClipboard}
              style={styles.requestFormIcon}
            />
            <span className="StepNumber">1</span>
            <FontAwesomeIcon
              icon={faFolderPlus}
              style={styles.requestFormIconActive}
            />
            <span className="StepNumber Active">2</span>
            <FontAwesomeIcon
              icon={faCheckSquare}
              style={styles.requestFormIconRight}
            />
            <span className="StepNumber3">3</span>
          </div>
        </div>
        <div className="DocumentClaimNumber">Claim #{packet?.claimNumber}</div>
        <p>
          Most lenders require at least the documents below to process a total
          loss claim. Please add them now to reduce unnecessary additions to the
          cycle time.
        </p>
        <div className="DocumentExtension">
          Currently supported file types: .pdf, .png, .jpg, .jpeg, .tiff
        </div>

        {!skipRequiredDocs ? (
          <div className="DocumentsSubtitle">Required Documents</div>
        ) : (
          <div className="DocumentsSubtitle Optional">Optional Documents</div>
        )}

        {!hasExistingDocumentType(
          "valuation report & settlement breakdown"
        ) && (
          <>
            {packet?.carrier?.combinedDocumentTypes && (
              <>
                {!savingDocs["valuation report & settlement breakdown"] ? (
                  <div className="UploadButton">
                    <FileInput
                      dragAndDropSupport
                      dropTypeMessage={
                        "Valuation Report & Settlement Breakdown"
                      }
                      acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                      buttonProps={{
                        disabled:
                          savingDocs["valuation report & settlement breakdown"],
                        invert: true,
                        size: "full-width"
                      }}
                      name="documents"
                      label={"Upload Valuation Report & Settlement Breakdown"}
                      onChange={handleDocuments(
                        "valuation report & settlement breakdown",
                        false
                      )}
                    />
                  </div>
                ) : (
                  <FileUploadProgressBar
                    fileUploadProgress={fileUploadProgress}
                    type={"Valuation Report & Settlement Breakdown"}
                  />
                )}
              </>
            )}
          </>
        )}

        {hasExistingDocumentType("valuation report & settlement breakdown") && (
          <div className="DocumentUploadSuccessMessage">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={styles.orderCheckIcon}
            />
            Uploaded Valuation Report & Settlement Breakdown!
          </div>
        )}

        {!hasExistingDocumentType("settlement breakdown") && (
          <>
            {!packet?.carrier?.combinedDocumentTypes && (
              <>
                {!savingDocs["settlement breakdown"] ? (
                  <div className="UploadButton">
                    <FileInput
                      dragAndDropSupport
                      dropTypeMessage={"Settlement Breakdown"}
                      acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                      buttonProps={{
                        disabled: savingDocs["settlement breakdown"],
                        invert: true,
                        size: "full-width"
                      }}
                      name="documents"
                      label={"Upload Settlement Breakdown"}
                      onChange={handleDocuments("settlement breakdown", false)}
                      testId={"SettlementBreakdownInput"}
                    />
                  </div>
                ) : (
                  <FileUploadProgressBar
                    fileUploadProgress={fileUploadProgress}
                    type={"Settlement Breakdown"}
                  />
                )}
              </>
            )}
          </>
        )}

        {hasExistingDocumentType("settlement breakdown") && (
          <div className="DocumentUploadSuccessMessage">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={styles.orderCheckIcon}
            />
            Uploaded Settlement Breakdown!
          </div>
        )}

        {!hasExistingDocumentType("valuation report") && (
          <>
            {!packet?.carrier?.combinedDocumentTypes && (
              <>
                {!savingDocs["valuation report"] ? (
                  <div className="UploadButton">
                    <FileInput
                      dragAndDropSupport
                      dropTypeMessage={"Valuation Report"}
                      acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                      buttonProps={{
                        disabled: savingDocs["valuation report"],
                        invert: true,
                        size: "full-width"
                      }}
                      name="documents"
                      label={"Upload Valuation Report"}
                      onChange={handleDocuments("valuation report", false)}
                      testId={"ValuationReportInput"}
                    />
                  </div>
                ) : (
                  <FileUploadProgressBar
                    fileUploadProgress={fileUploadProgress}
                    type={"Valuation Report"}
                  />
                )}
              </>
            )}
          </>
        )}

        {hasExistingDocumentType("valuation report") && (
          <div className="DocumentUploadSuccessMessage">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={styles.orderCheckIcon}
            />
            Uploaded Valuation Report!
          </div>
        )}

        {!skipRequiredDocs && (
          <div className="DocumentsSubtitle Optional">Optional Documents</div>
        )}
        {!hasExistingDocumentType("cause of loss") && (
          <>
            {!savingDocs["cause of loss"] ? (
              <div className="UploadButton">
                <FileInput
                  dragAndDropSupport
                  dropTypeMessage={"Cause of Loss"}
                  acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                  buttonProps={{
                    disabled: savingDocs["cause of loss"],
                    invert: true,
                    size: "full-width"
                  }}
                  name="documents"
                  label={"Upload Cause of Loss Statement"}
                  onChange={handleDocuments("cause of loss", false)}
                />
              </div>
            ) : (
              <FileUploadProgressBar
                fileUploadProgress={fileUploadProgress}
                type={"Cause of Loss Statement"}
              />
            )}
          </>
        )}

        {hasExistingDocumentType("cause of loss") && (
          <div className="DocumentUploadSuccessMessage">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={styles.orderCheckIcon}
            />
            Uploaded Cause of Loss Statement!
          </div>
        )}

        {!hasExistingDocumentType("police report") && (
          <>
            {!savingDocs["police report"] ? (
              <div className="UploadButton">
                <FileInput
                  dragAndDropSupport
                  dropTypeMessage={"Police Report"}
                  acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                  buttonProps={{
                    disabled: savingDocs["police report"],
                    invert: true,
                    size: "full-width"
                  }}
                  name="documents"
                  label={"Upload Police Report"}
                  onChange={handleDocuments("police report", false)}
                />
              </div>
            ) : (
              <FileUploadProgressBar
                fileUploadProgress={fileUploadProgress}
                type={"Police Report"}
              />
            )}
          </>
        )}

        {hasExistingDocumentType("police report") && (
          <div className="DocumentUploadSuccessMessage">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={styles.orderCheckIcon}
            />
            Uploaded Police Report!
          </div>
        )}

        {!hasExistingDocumentType("declaration page") && isFirstPartyClaim && (
          <>
            {!savingDocs["declaration page"] ? (
              <div className="UploadButton">
                <FileInput
                  dragAndDropSupport
                  dropTypeMessage={"Declaration Page"}
                  acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                  buttonProps={{
                    disabled: savingDocs["declaration page"],
                    invert: true,
                    size: "full-width"
                  }}
                  name="documents"
                  label={"Upload Declaration Page"}
                  onChange={handleDocuments("declaration page", false)}
                />
              </div>
            ) : (
              <FileUploadProgressBar
                fileUploadProgress={fileUploadProgress}
                type={"Declaration Page"}
              />
            )}
          </>
        )}
        {hasExistingDocumentType("declaration page") && isFirstPartyClaim && (
          <div className="DocumentUploadSuccessMessage">
            <FontAwesomeIcon
              icon={faCheckCircle}
              style={styles.orderCheckIcon}
            />
            Uploaded Declaration Page!
          </div>
        )}
      </>
      <div className="DocumentNextStepButton">
        <Button
          size="full-width"
          disabled={disabled}
          onClick={navigateToOrderingMenu}
        >
          Next Step
        </Button>
      </div>
    </div>
  );
};

export default Documentation;
