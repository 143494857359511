import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";

import { changePassword } from "../../services/auth-request";
import { removeFromLS } from "../../services/local-storage";

import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import XLienSideWrapper from "../XLienSideWrapper";
import Footer from "../Footer/Footer";

import "./PasswordChangeRequired.css";

const PasswordChangeRequired = () => {
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [redirectToIndex, setRedirectToIndex] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(null);

  const { handleSubmit, register, watch, errors } = useForm({
    mode: "onBlur"
  });

  const { newPassword, confirmPassword } = watch();

  useEffect(() => {
    if (confirmPassword === newPassword) {
      setDisabled(false);
      setError("");
    } else {
      setDisabled(true);
      setError("Passwords must match.");
    }
  }, [newPassword, confirmPassword]);

  const submitPasswordChange = async data => {
    try {
      await changePassword(data.currentPassword, data.newPassword);
      setRedirectToHome(true);
    } catch (ex) {
      if (ex.message === "Credentials invalid.") {
        removeFromLS();
        setRedirectToIndex(true);
      }
    }
  };

  return (
    <XLienSideWrapper>
      {redirectToHome && <Redirect to="/home" />}
      {redirectToIndex && <Redirect to="/" />}
      <div className="LoginTitle">Change Your Password</div>
      <p>
        Looks like it's time to change your password! Enter your new password
        below.
      </p>
      <form
        onSubmit={handleSubmit(submitPasswordChange)}
        data-testid="PasswordChangeForm"
      >
        <FormInput
          type="password"
          name="currentPassword"
          register={register}
          required
          placeholder="Current Password"
          error={errors.currentPassword}
        />

        <FormInput
          type="password"
          name="newPassword"
          register={register}
          required
          placeholder="New Password"
          error={errors.newPassword}
        />
        <FormInput
          type="password"
          name="confirmPassword"
          register={register}
          required
          placeholder="Confirm New Password"
          error={errors.confirmPassword}
        />
        {error ? <div className="ErrorMessage">{error}</div> : ""}
        <Button disabled={disabled} size={"full-width"}>
          Change Password
        </Button>
      </form>
      <Footer />
    </XLienSideWrapper>
  );
};

export default PasswordChangeRequired;
