import React, { useState, useEffect } from "react";
import { getAccountInfo } from "../../services/users";
import { Redirect } from "react-router-dom";

const SessionValidation = AnyComponent => {
  const SessionComponent = props => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [userValidated, setUserValidated] = useState(false);

    useEffect(() => {
      async function checkUser() {
        let user;
        try {
          user = await getAccountInfo();
          setUserValidated(true);
        } catch (ex) {
          debugger;

          setRedirectToLogin(true);
        }

        if (user) {
          window.Intercom("boot", {
            app_id: "qvbqfp8s",
            name: user.name,
            email: user.emailAddress,
            user_id: user.userId
          });
        }
      }

      checkUser();

      const interval = setInterval(() => checkUser(), 1000 * 60 * 10);

      return function cleanup() {
        clearInterval(interval);
      };
    }, []);

    return (
      <div>
        {redirectToLogin && (
          <Redirect
            to={{
              pathname: "/login",
              search: "?issue=invalid_session&redirect_back=true",
              state: {
                redirectPath: props.location.pathname
              }
            }}
            push
          />
        )}

        {userValidated && <AnyComponent />}
      </div>
    );
  };

  return SessionComponent;
};

export default SessionValidation;
