import React from "react";

import "./Checkbox.css";

const Checkbox = ({
  isSelected,
  type,
  label,
  id,
  onSelect = () => {},
  value,
  name
}) => {
  const onToggleCheckbox = () => {
    onSelect(!isSelected, { label, value, name });
  };

  return (
    <div className={`checkboxwrap ${type === "small" && " CheckboxSmall"}`}>
      <div className="rowCheckboxWrapper">
        <div>
          <div
            className={`rowCheckbox ${isSelected ? "rowCheckboxSelected" : ""}`}
            onClick={onToggleCheckbox}
          >
            {isSelected && <i className="fa fa-check" />}
          </div>
        </div>
      </div>
      <div className="rowTitle" onClick={onToggleCheckbox}>
        {label}
      </div>
    </div>
  );
};

export default Checkbox;
