import React, { useEffect, useState } from "react";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";

import { persistItemToLS } from "../../services/local-storage";
import { getAccountInfo, updateUserSettings } from "../../services/users";

import { changePassword } from "../../services/auth-request";
import {
  getSubscription,
  registerServiceWorker,
  unsubscribeUser,
  subscribeUser
} from "../../services/push-notifications";

import Layout from "../Layout";

import "./AccountPage.css";
import Error from "../Error";
import Success from "../Success";
import { useForm } from "react-hook-form";

const AccountPage = () => {
  const [accountInfo, setAccountInfo] = useState({});
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [notificationSuccess, setNotificationSuccess] = useState(false);
  const [notificationError, setNotificationError] = useState(false);
  const [pushNotificationsEnabled, setPushNotificationsEnabled] = useState(
    false
  );
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { register, errors, watch, handleSubmit } = useForm({
    mode: "onBlur"
  });

  const notificationForm = useForm({
    mode: "onBlur"
  });

  const form = watch();
  const notificationFormData = notificationForm.watch();
  const notificationSetValue = notificationForm.setValue;

  useEffect(() => {
    (async function() {
      const info = await getAccountInfo();
      setAccountInfo(info);

      const serviceWorker = await registerServiceWorker();
      const sub = await getSubscription(serviceWorker);
      if (sub) {
        setPushNotificationsEnabled(true);
      }
    })();
  }, []);

  useEffect(() => {
    if (
      !errors.newPassword &&
      !errors.currentPassword &&
      !errors.confirmPassword
    ) {
      if (
        form.newPassword !== "" &&
        form.currentPassword !== "" &&
        form.confirmPassword !== ""
      ) {
        setButtonDisabled(false);
        return;
      }
    }

    setButtonDisabled(true);
  }, [form, errors]);

  useEffect(() => {
    notificationSetValue(
      "emailNotificationPayoffQuote",
      accountInfo.emailNotificationPayoffQuote
    );
    notificationSetValue(
      "emailNotificationLoG",
      accountInfo.emailNotificationLoG
    );
  }, [accountInfo, notificationSetValue]);

  const togglePushNotifications = async () => {
    persistItemToLS("push-notifications-enabled", !pushNotificationsEnabled);

    if (!pushNotificationsEnabled) {
      await subscribeUser();
    } else {
      await unsubscribeUser();
    }

    setPushNotificationsEnabled(!pushNotificationsEnabled);
  };

  const submitPasswordChange = async data => {
    if (data.newPassword !== data.confirmPassword) {
      setPasswordError("Confirmation password does not match.");
      setPasswordSuccess(false);
      return;
    }

    if (data.newPassword === data.currentPassword) {
      setPasswordError("New password can not equal current password.");
      setPasswordSuccess(false);
      return;
    }

    try {
      await changePassword(data.currentPassword, data.newPassword);
      setPasswordSuccess("Password changed!");
      setPasswordError(false);
    } catch (ex) {
      setPasswordError(ex.message);
      setPasswordSuccess(false);
    }
  };

  const submitNotificationSettings = async data => {
    try {
      await updateUserSettings({
        emailNotificationPayoffQuote: data.emailNotificationPayoffQuote,
        emailNotificationLoG: data.emailNotificationLoG
      });
      setNotificationSuccess("Notification settings updated!");
      setNotificationError(false);
    } catch (ex) {
      setNotificationError(ex.message);
      setNotificationSuccess(false);
    }
  };

  return (
    <Layout>
      <h1>Your Account</h1>
      <div className="AccountPageInfoWrapper">
        <span className="AccountPageLabel">Username:</span>{" "}
        <span>{accountInfo.username}</span>
      </div>
      <div className="AccountPageInfoWrapper">
        <span className="AccountPageLabel">Email Address:</span>{" "}
        <span>{accountInfo.emailAddress}</span>
      </div>

      <div className="AccountPagePasswordWrapper">
        <h2>Change Password</h2>
        {passwordError && <Error>{passwordError}</Error>}
        {passwordSuccess && <Success>{passwordSuccess}</Success>}
        <form onSubmit={handleSubmit(submitPasswordChange)}>
          <FormInput
            name="currentPassword"
            placeholder="Current password"
            type="password"
            validationRegex={/^.{12,}$/}
            validationMessage="Password must be at least 12 characters long."
            register={register}
          />
          <FormInput
            name="newPassword"
            placeholder="New password"
            type="password"
            validationRegex={/^.{12,}$/}
            validationMessage="Password must be at least 12 characters long."
            register={register}
          />
          <FormInput
            name="confirmPassword"
            placeholder="Confirm new password"
            type="password"
            validationRegex={/^.{12,}$/}
            validationMessage="Password must be at least 12 characters long."
            register={register}
          />
          <Button disabled={buttonDisabled}>Change Password</Button>
        </form>
      </div>

      <h1 className="AccountPageNotificationsHeader">Notifications</h1>
      <div className="AccountPagePasswordWrapper">
        <h2>Push Notifications</h2>
        <p>
          We send you push notifications whenever payoff quotes are fulfilled or
          letters of guarantee are received.
        </p>
        {pushNotificationsEnabled && (
          <Button color="red" invert onClick={togglePushNotifications}>
            Disable Push Notifications
          </Button>
        )}
        {!pushNotificationsEnabled && (
          <Button onClick={togglePushNotifications}>
            Enable Push Notifications
          </Button>
        )}
      </div>
      <div className="AccountPagePasswordWrapper">
        <h2>Email Notifications</h2>
        {notificationError && <Error>{notificationError}</Error>}
        {notificationSuccess && <Success>{notificationSuccess}</Success>}

        <p>
          We send varying email notifications as events happen on packets. You
          can change those settings here.
        </p>

        <form
          onSubmit={notificationForm.handleSubmit(submitNotificationSettings)}
        >
          <FormInput
            type="checkbox"
            label="Send Email on Payoff Quote"
            name="emailNotificationPayoffQuote"
            value={notificationFormData.emailNotificationPayoffQuote}
            onChange={notificationForm.setValue}
            register={notificationForm.register}
          />
          <FormInput
            type="checkbox"
            label="Send Email on Letter of Guarantee Response"
            name="emailNotificationLoG"
            value={notificationFormData.emailNotificationLoG}
            onChange={notificationForm.setValue}
            register={notificationForm.register}
          />
          <br />
          <Button>Update Notification Settings</Button>
        </form>
      </div>
    </Layout>
  );
};

export default AccountPage;
