import React, { useEffect, useState } from "react";
import DirectMessageForm from "../DirectMessageForm";
import { useParams } from "react-router-dom";
import { fetchxApiPacket } from "../../../services/packets";
import "./DirectMessageIframe.css";
import Error from "../../Error";
import * as Sentry from "@sentry/browser";

const DirectMessageIframe = () => {
  const [packet, setPacket] = useState(null);
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);
  const { packetId, oauthToken } = useParams();

  useEffect(() => {
    (async function() {
      if (oauthToken) {
        setToken(oauthToken);
        try {
          const xApiPacket = await fetchxApiPacket(packetId, oauthToken);
          setPacket(xApiPacket);
        } catch (ex) {
          setError(
            `ERROR: There was an error with your authorization token. Refreshing the page may help.`
          );
          Sentry.captureException(ex);
        }
      }
    })();
  }, [packetId, oauthToken]);

  return (
    <div>
      <div className="iFrame__Container">
        {error && <Error>{error}</Error>}
        {packet && token && (
          <>
            <h3>Direct Messages for Claim #{packet?.claimNumber}</h3>
            <DirectMessageForm packet={packet} token={token} error={error} />
          </>
        )}
      </div>
    </div>
  );
};

export default DirectMessageIframe;
