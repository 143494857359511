import React, { Component } from "react";
import * as Sentry from "@sentry/browser";

import "./ErrorBoundary.css";
import Footer from "../Footer/Footer";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    stack: ""
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    Sentry.captureException(error);

    this.setState({
      stack: info.componentStack
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="ErrorBoundaryPage">
          <div className="ErrorBoundary">
            <div className="ErrorBoundaryIcon">
              <i className="fa fa-exclamation-triangle" />
            </div>

            <p>
              Looks like you have encountered a non-recoverable error with our
              system. We have logged the error in our tracker and will be
              reviewing it. Apologies for the inconvenience.
            </p>

            <div className="ErrorBoundaryErrorWrapper">
              <h4>Error Report</h4>

              <div className="ErrorBoundaryErrorReport">
                <div className="ErrorBoundaryError">
                  {this.state.error.message}
                </div>

                <p>{this.state.stack.toString()}</p>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
