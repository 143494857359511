import React from "react";
import { useForm } from "react-hook-form";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";

import Modal from "../Modal";
import FormInput from "../FormInput";

const CancelLoGModal = ({ close, onCancelRequest }) => {
  const { errors, handleSubmit, register, watch, setValue } = useForm({
    mode: "onBlur"
  });

  const reason = watch("logCancellationReason");

  const onChange = e => {
    setValue("logCancellationReason", e.target.value);
  };

  const submit = data => {
    const d = Object.keys(data).reduce((obj, k) => {
      if (data[k] === "" || data[k] == null) {
        return obj;
      }

      obj[k] = data[k];
      return obj;
    }, {});

    d.reason = data.logCancellationReason;
    delete d.logCancellationReason;

    onCancelRequest(d);
  };

  return (
    <Modal onClose={close}>
      <h1 className="ModalSuccessHeader">Cancel Letter of Guarantee Request</h1>
      <p>
        We're already hard at work on fulfilled this letter of guarantee
        request. Let us know why you'd like to cancel this request.
      </p>

      <form onSubmit={handleSubmit(submit)} data-testid="CancelLoGForm">
        <FormInput
          error={errors.logCancellationReason}
          name="logCancellationReason"
          onChange={onChange}
          register={register}
          value={reason}
        />

        {reason === "Other" && (
          <FormInput
            error={errors.cancellationExplanation}
            name="cancellationExplanation"
            register={register}
            required
          />
        )}
        <Button>Submit Cancellation Request</Button>
      </form>
    </Modal>
  );
};

export default CancelLoGModal;
