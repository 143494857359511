import React, { useEffect } from "react";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";

import Modal from "../Modal";
import "./OrderWitoutLoGModal.css";

const OrderWitoutLoGModal = ({
  close,
  setValue,
  register,
  watch,
  getValues,
  onSubmit,
  setHasConfirmedOrderWithoutLoG,
  setCookie,
  hideLoGWarningCookieName
}) => {
  const doNotShowLoGWarningField = watch("doNotShowLoGWarning");
  useEffect(() => {
    register({ name: "doNotShowLoGWarning" });
  }, [register]);

  const onCheckboxChange = (name, bool) => {
    setValue(name, bool);
    //save user preference
    setCookie(hideLoGWarningCookieName, bool, { path: "/" });
  };

  const yesReselectLoG = () => {
    //reselect checkbox long enough for user to notice
    setValue("letterOfGuarantee", true);
    setTimeout(function() {
      //preserve form data
      const data = getValues();
      onSubmit(data);
      close();
    }, 500);
  };

  const submitWithoutLoG = () => {
    setHasConfirmedOrderWithoutLoG(true);
    //preserve form data
    const data = getValues();
    onSubmit(data);
    close();
  };

  return (
    <Modal onClose={close}>
      <p className="disclosure">
        <span className="warning">Warning:</span> Without a Letter of Guarantee,
        received payoff indicators can vary from the payoff amount provided on
        the Letter of Guarantee and are subject to change. For this reason, we
        recommend you order a Letter of Guarantee together with any payoff
        request. Would you like to order a Letter of Guarantee?
      </p>

      <FormInput
        label="Don't remind me again"
        type="checkbox"
        name="doNotShowLoGWarning"
        register={register}
        onChange={onCheckboxChange}
        value={doNotShowLoGWarningField}
      />

      <div className="button-group">
        <div className="button-wrapper">
          <Button onClick={yesReselectLoG}>Yes</Button>
        </div>
        <div className="button-wrapper">
          <Button onClick={submitWithoutLoG}>No</Button>
        </div>
      </div>
    </Modal>
  );
};

export default OrderWitoutLoGModal;
