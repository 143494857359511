import React from "react";
import { FileInput } from "@fastlane-llc/lossexpress-ui-kit";
import { FileUploadProgressBar } from "../SideContainer";

const UploadDocuments = ({
  packet,
  savingDocs,
  handleDocuments = () => {},
  fileUploadProgress,
  skipRequiredDocs
}) => {
  return (
    <div>
      <h2 className="UploadDocumentsTitle">Upload Documents</h2>
      <div className="Subtitle">
        Currently supported extensions: .pdf, .png, .jpg, .tiff
      </div>

      <div className="UploadDocumentButtons">
        {!packet.carrier.combinedDocumentTypes && (
          <>
            {!savingDocs["settlement breakdown"] ? (
              <div className="UploadButton">
                <FileInput
                  dragAndDropSupport
                  dropTypeMessage={"Settlement Breakdown"}
                  acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                  buttonProps={{
                    disabled: savingDocs["settlement breakdown"],
                    invert: true,
                    size: "full-width"
                  }}
                  name="documents"
                  label={"Settlement Breakdown"}
                  onChange={handleDocuments("settlement breakdown")}
                />
              </div>
            ) : (
              <FileUploadProgressBar
                fileUploadProgress={fileUploadProgress}
                type={"Settlement Breakdown"}
              />
            )}

            {!packet.carrier.combinedDocumentTypes &&
            !savingDocs["valuation report"] ? (
              <div className="UploadButton">
                <FileInput
                  dragAndDropSupport
                  dropTypeMessage={"Valuation Report"}
                  acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                  buttonProps={{
                    disabled: savingDocs["valuation report"],
                    invert: true,
                    size: "full-width"
                  }}
                  name="documents"
                  label={"Valuation Report"}
                  onChange={handleDocuments("valuation report")}
                />
              </div>
            ) : (
              <FileUploadProgressBar
                fileUploadProgress={fileUploadProgress}
                type={"Valuation Report"}
              />
            )}
          </>
        )}

        {packet.carrier.combinedDocumentTypes && !packet.carrier.hybridDisplay && (
          <>
            {!savingDocs["valuation report & settlement breakdown"] ? (
              <div className="UploadButton">
                <FileInput
                  dragAndDropSupport
                  dropTypeMessage={"Valuation Report & Settlement Breakdown"}
                  acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                  buttonProps={{
                    disabled:
                      savingDocs["valuation report & settlement breakdown"],
                    invert: true,
                    size: "full-width"
                  }}
                  name="documents"
                  label={"Valuation Report & Settlement Breakdown"}
                  onChange={handleDocuments(
                    "valuation report & settlement breakdown"
                  )}
                />
              </div>
            ) : (
              <FileUploadProgressBar
                fileUploadProgress={fileUploadProgress}
                type={"Valuation Report & Settlement Breakdown"}
              />
            )}
          </>
        )}

        {!savingDocs["cause of loss"] ? (
          <div className="UploadButton">
            <FileInput
              dragAndDropSupport
              dropTypeMessage={"Cause of Loss"}
              acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
              buttonProps={{
                disabled: savingDocs["cause of loss"],
                invert: true,
                size: "full-width"
              }}
              name="documents"
              label={
                skipRequiredDocs ? "Cause of Loss" : "Cause of Loss (Optional)"
              }
              onChange={handleDocuments("cause of loss")}
            />
          </div>
        ) : (
          <FileUploadProgressBar
            fileUploadProgress={fileUploadProgress}
            type={"Cause of Loss"}
          />
        )}

        {!savingDocs["police report"] ? (
          <div className="UploadButton">
            <FileInput
              dragAndDropSupport
              dropTypeMessage={"Police Report"}
              acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
              buttonProps={{
                disabled: savingDocs["police report"],
                invert: true,
                size: "full-width"
              }}
              name="documents"
              label={
                skipRequiredDocs ? "Police Report" : "Police Report (Optional)"
              }
              onChange={handleDocuments("police report")}
            />
          </div>
        ) : (
          <FileUploadProgressBar
            fileUploadProgress={fileUploadProgress}
            type={"Police Report"}
          />
        )}

        {packet.insurerType === "First Party" && (
          <>
            {!savingDocs["declaration page"] ? (
              <div className="UploadButton">
                <FileInput
                  dragAndDropSupport
                  dropTypeMessage={"Declaration Page"}
                  acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
                  buttonProps={{
                    disabled: savingDocs["declaration page"],
                    invert: true,
                    size: "full-width"
                  }}
                  name="documents"
                  label={
                    skipRequiredDocs
                      ? "Declaration Page"
                      : "Declaration Page (Optional)"
                  }
                  onChange={handleDocuments("declaration page")}
                />
              </div>
            ) : (
              <FileUploadProgressBar
                fileUploadProgress={fileUploadProgress}
                type={"Declaration Page"}
              />
            )}
          </>
        )}

        {!savingDocs["unspecified"] ? (
          <div className="UploadButton">
            <FileInput
              dragAndDropSupport
              dropTypeMessage={"Other Document"}
              acceptedExtensions=".png,.jpg,.jpeg,.gif,.pdf,.tif,.tiff"
              buttonProps={{
                disabled: savingDocs["unspecified"],
                invert: true,
                size: "full-width"
              }}
              name="documents"
              label={"Other Document"}
              onChange={handleDocuments("unspecified")}
            />
          </div>
        ) : (
          <FileUploadProgressBar
            fileUploadProgress={fileUploadProgress}
            type={"Other Document"}
          />
        )}
      </div>
    </div>
  );
};

export default UploadDocuments;
