import React, { useState, useEffect } from "react";
import { getDuplicateClaims } from "../../../services/packets";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import "./DuplicateClaims.css";

const iconStyle = {
  color: "#ccaa00",
  marginRight: ".2em",
  marginLeft: ".2em"
};

const DuplicateClaims = ({ debouncedClaimNumber, claimNumber, packet }) => {
  const [duplicateClaims, setDuplicateClaims] = useState([]);
  useEffect(() => {
    if (claimNumber === "" || claimNumber == null) {
      setDuplicateClaims([]);
    }
  }, [claimNumber]);

  useEffect(() => {
    (async function() {
      if (
        debouncedClaimNumber &&
        debouncedClaimNumber !== "" &&
        debouncedClaimNumber != null
      ) {
        const claims = await getDuplicateClaims(debouncedClaimNumber);
        setDuplicateClaims(
          packet
            ? claims.duplicateClaims.filter(
                claim => packet && claim.packetId !== packet.packetId
              )
            : claims.duplicateClaims
        );
      }
    })();
  }, [debouncedClaimNumber, packet]);

  return (
    <>
      {duplicateClaims && duplicateClaims.length > 0 && (
        <div className="DuplicateClaims">
          {duplicateClaims.length > 1 ? (
            <div className="DuplicateStatement">Possible Duplicate Claims</div>
          ) : (
            <div className="DuplicateStatement">Possible Duplicate Claim</div>
          )}
          {duplicateClaims.map(dupe => (
            <div key={dupe.packetId}>
              <Link to={`/claims/${dupe.packetId}`}>
                <span className="ClaimDash">{" - "}</span>
                <span className="ClaimLink">{claimNumber}</span>
                <span className="DuplicateDate">
                  {" "}
                  ({format(new Date(dupe.createdAt), "M/D/YY")})
                </span>{" "}
                {dupe.archived && (
                  <span className="DuplicateArchived">ARCHIVED</span>
                )}
              </Link>
            </div>
          ))}
          <div className="DuplicateWarning">
            <FontAwesomeIcon icon={faExclamationTriangle} style={iconStyle} />
            <span>
              Please refrain from creating duplicate claims caused by techincal
              issues and instead contact LE Support.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default DuplicateClaims;
