import React, { useState, useEffect } from "react";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import { useForm } from "react-hook-form";
import {
  createLoGRequest,
  createOrderRequest,
  createPayoffQuote,
  fetchPacket
} from "../../services/packets";
import * as Sentry from "@sentry/browser";
import "./OrderingMenu.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faClipboard,
  faFolderPlus,
  faCompressAlt,
  faAngleDown
} from "@fortawesome/free-solid-svg-icons";
import { styles } from "../ClaimView/IconStyles";
import OrderWithoutLoGModal from "../OrderWithoutLoGModal";
import { useCookies } from "react-cookie";

const ERROR_MESSAGE =
  "There was an issue processing your request. Please try again later or contact support using the button on the bottom left-hand corner of your screen.";
const hideLoGWarningCookieName = "fastlane_hide-log-warning";

export const orderTypes = [
  { name: "copyOfTitle", label: "Copy of Title" },
  { name: "titleStatus", label: "Title Status" },
  { name: "lienLetter", label: "Lien Release Letter" },
  { name: "installmentContract", label: "Installment Contract" },
  { name: "billOfSale", label: "Bill of Sale" },
  { name: "paymentHistory", label: "Payment History" },
  { name: "repoAffidavit", label: "Repo Affidavit" },
  { name: "oneAndTheSameLetter", label: "One and the Same Letter" },
  { name: "paymentStatus", label: "Payment Status" }
];

const OrderingMenu = ({
  packet,
  setError = () => {},
  setSaving = () => {},
  refreshPacket = () => {},
  updatingClaim,
  workPacket,
  carrier,
  fromRequestForm = false,
  loading,
  isCollapsed = false,
  setThirdStep = () => {},
  setShowOrderingMenu = () => {},
  setRequestCompleted = () => {},
  setIsCollapsed = () => {}
}) => {
  const [cookies, setCookie] = useCookies(["hideLoGWarningCookieName"]);
  const [
    showCreateOrderWithoutLoGModal,
    setShowCreateOrderWithoutLoGModal
  ] = useState(false);
  const [
    hasConfirmedOrderWithoutLoG,
    setHasConfirmedOrderWithoutLoG
  ] = useState(false);

  const { register, handleSubmit, setValue, watch, reset, getValues } = useForm(
    {
      mode: "onBlur"
    }
  );

  useEffect(() => {
    register({ name: "showCreateOrderWithoutLoGModal" });
  }, [showCreateOrderWithoutLoGModal, register]);

  const requestFormData = watch();
  const {
    loanPayoff,
    letterOfGuarantee,
    xlogManualAuth,
    copyOfTitle,
    titleStatus,
    lienLetter,
    installmentContract,
    billOfSale,
    paymentHistory,
    repoAffidavit,
    oneAndTheSameLetter,
    paymentStatus
  } = watch();

  const disabledProcessRequest = Object.values(requestFormData).some(
    item => item === true
  );

  const canBeReordered = type => {
    const hasExistingOrder = packet?.orders?.find(order => order.type === type);
    return (
      hasExistingOrder &&
      ["fulfilled", "cancelled"].includes(hasExistingOrder.status)
    );
  };

  const showOrder = type => {
    const isActive = !packet?.carrier?.inactiveOrders?.includes(type);
    const hasExistingOrder = packet?.orders?.some(order => order.type === type);
    return (
      isActive &&
      (!hasExistingOrder || (type === "Title Status" && canBeReordered(type)))
    );
  };

  const toggleCheckbox = (name, val) => {
    setValue(name, val);
  };

  const skipRequiredDocs = packet?.carrier?.uploadingDocsOptional;

  const onSubmit = async data => {
    const letterOfGuarantee = data?.letterOfGuarantee;
    const loanPayoff = data?.loanPayoff;
    const pendingOrders = [];

    const hideLoGWarningModal = cookies[hideLoGWarningCookieName];
    // Warn user about potential inaccurate payment information
    if (
      loanPayoff &&
      !letterOfGuarantee &&
      !hasConfirmedOrderWithoutLoG &&
      hideLoGWarningModal !== "true"
    ) {
      setShowCreateOrderWithoutLoGModal(true);
      return;
    }

    setSaving(true);

    if (loanPayoff) await refreshPayoffQuote();
    if (letterOfGuarantee) {
      await requestLoG();
    }
    for (const type of orderTypes) {
      if (data == null) {
        break;
      }
      if (data[type.name]) {
        pendingOrders.push(type.label);
      }
    }
    await requestOrders(pendingOrders);
    setThirdStep(false);
    setSaving(false);

    if (
      skipRequiredDocs ||
      (fromRequestForm && packet?.lender?.immediatePayoffAvailable)
    ) {
      setRequestCompleted(true);
    } else {
      setShowOrderingMenu(true);
    }
  };

  const closeCreateOrderWithoutLoG = () => {
    setShowCreateOrderWithoutLoGModal(false);
  };

  const requestLoG = async () => {
    try {
      setSaving(true);
      await createLoGRequest(packet.packetId, { xlogManualAuth });
      refreshPacket();
      reset();

      // TEMPORARY - until order not placing bug is fixed
      // fetch the packet and make sure LoG actually exists
      const updatedPacket = await fetchPacket(packet.packetId);
      const orderTypes = updatedPacket.orders?.map(x => x.type);
      if (!orderTypes.includes("Letter of Guarantee")) {
        Sentry.captureException(
          `LoG selected but not placed ${packet.packetId}`
        );
        setError(ERROR_MESSAGE);
      }
    } catch (ex) {
      setError(
        "There was an error creating a letter of guarantee request on this claim."
      );
      setSaving(false);
    }
  };

  const refreshPayoffQuote = async () => {
    try {
      setSaving(true);
      await createPayoffQuote(
        packet.packetId,
        null,
        packet.payoffQuote && packet.payoffQuote.status !== "pending"
      );
      refreshPacket();
    } catch (ex) {
      Sentry.captureException(ex);
      setError(ERROR_MESSAGE);
      setSaving(false);
    }
    reset();
  };

  const requestOrders = async types => {
    setSaving(true);
    try {
      await createOrderRequest(packet.packetId, types);
      refreshPacket();
      reset();
    } catch (ex) {
      setError("There was an error creating the request on this claim.");
      setSaving(false);
    }
  };

  const verifiedLender = packet?.lender?.verified === true;

  const logIsFulfilledOrMissing =
    packet?.letterOfGuaranteeRequest?.status === "fulfilled" ||
    !packet?.letterOfGuaranteeRequest;

  if (isCollapsed)
    return (
      <div className="OrderingMenuContainerCollapsed">
        <h2>Order Menu</h2>
        <div
          className="CollapseMenu"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <FontAwesomeIcon icon={faAngleDown} style={styles.angleDown} />
        </div>
      </div>
    );

  return (
    <div className="OrderingMenuContainer" data-testid="OrderingMenu">
      {!fromRequestForm && (
        <div
          className="CollapseMenu"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <FontAwesomeIcon icon={faCompressAlt} style={styles.angleDown} />
        </div>
      )}

      {showCreateOrderWithoutLoGModal && (
        <OrderWithoutLoGModal
          close={closeCreateOrderWithoutLoG}
          setHasConfirmedOrderWithoutLoG={setHasConfirmedOrderWithoutLoG}
          setValue={setValue}
          watch={watch}
          getValues={getValues}
          register={register}
          onSubmit={onSubmit}
          setCookie={setCookie}
          hideLoGWarningCookieName={hideLoGWarningCookieName}
        />
      )}

      <div className="TitleContainer">
        {fromRequestForm ? <h2>Select Orders</h2> : <h2>Order Menu</h2>}
        {fromRequestForm && (
          <div>
            <FontAwesomeIcon
              icon={faClipboard}
              style={styles.requestFormIcon}
            />
            <span className="StepNumber">1</span>
            <FontAwesomeIcon
              icon={faFolderPlus}
              style={styles.requestFormIcon}
            />
            <span className="StepNumber">2</span>
            <FontAwesomeIcon
              icon={faCheckSquare}
              style={styles.requestFormIconRightActive}
            />
            <span className="StepNumber3Active">3</span>
          </div>
        )}
      </div>
      {!fromRequestForm && <div className="title">Need something else?</div>}
      {fromRequestForm ? (
        <div className="ClaimNumber">Claim #{packet.claimNumber}</div>
      ) : (
        <div className="Subtitle">
          We’re happy to help in any way we can! Select anything you need from
          the list below and we will begin processing your request.
        </div>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        data-testid="NeedSomethingElseForm"
      >
        <div className="RequestCheckboxesWrapper">
          {(packet?.payoffQuote?.status === "fulfilled" ||
            packet?.payoffQuote?.status === "cancelled" ||
            packet?.payoffQuote === null) &&
            !packet?.archived &&
            !fromRequestForm &&
            !packet?.carrier?.hybridDisplay && (
              <FormInput
                type="checkbox"
                label={
                  packet?.payoffQuote ? "Loan Payoff (Re-Order)" : "Loan Payoff"
                }
                register={register}
                value={loanPayoff}
                onChange={toggleCheckbox}
                name="loanPayoff"
              />
            )}
          {fromRequestForm && (
            <FormInput
              type="checkbox"
              label="Loan Payoff"
              register={register}
              value={loanPayoff}
              onChange={toggleCheckbox}
              name="loanPayoff"
              defaultValue={true}
            />
          )}
          {fromRequestForm && (
            <FormInput
              type="checkbox"
              label="Letter of Guarantee*"
              register={register}
              value={letterOfGuarantee}
              onChange={toggleCheckbox}
              name="letterOfGuarantee"
              defaultValue={true}
            />
          )}
          {!updatingClaim &&
            packet &&
            !fromRequestForm &&
            logIsFulfilledOrMissing &&
            packet.settlementAmount &&
            workPacket &&
            !packet?.archived && (
              <FormInput
                type="checkbox"
                label={
                  packet?.letterOfGuaranteeRequest
                    ? "Letter of Guarantee (Re-Order)"
                    : "Letter of Guarantee"
                }
                register={register}
                value={letterOfGuarantee}
                onChange={toggleCheckbox}
                name="letterOfGuarantee"
              />
            )}
          {letterOfGuarantee &&
            packet.carrier?.xlogEnabled &&
            packet.carrier?.xlogRequiresManualAuth &&
            packet?.lender?.xlogEnabled &&
            packet?.lender?.xlogContractSigned && (
              <div className={"xlogManualAuth"}>
                <FormInput
                  type="checkbox"
                  label="xLoG Manual Auth?"
                  register={register}
                  value={xlogManualAuth}
                  onChange={toggleCheckbox}
                  name="xlogManualAuth"
                  defaultValue={
                    packet?.letterOfGuaranteeRequest?.xlogRequested ?? false
                  }
                />
              </div>
            )}
          {!verifiedLender && !loading && (
            <div className="OrderingMenuAlertMessage">
              We're actively working to verify that lender and add it to our
              database. Once verified, you will be able to continue to request
              orders for this claim.
            </div>
          )}
          {!loading && packet?.archived && (
            <div className="OrderingMenuAlertMessage">
              This claim has been archived. Please restore the claim if you
              would like to create a new order.
            </div>
          )}

          {carrier?.additionalOrderTypes &&
            verifiedLender &&
            !packet?.archived && (
              <>
                {showOrder("Copy of Title") && (
                  <FormInput
                    type="checkbox"
                    label="Title Image"
                    register={register}
                    value={copyOfTitle}
                    onChange={toggleCheckbox}
                    name="copyOfTitle"
                  />
                )}
                {showOrder("Title Status") && (
                  <FormInput
                    type="checkbox"
                    label={`Title Status${
                      canBeReordered("Title Status") ? " (Re-order)" : ""
                    }`}
                    register={register}
                    value={titleStatus}
                    onChange={toggleCheckbox}
                    name="titleStatus"
                  />
                )}
                {showOrder("Lien Release Letter") && (
                  <FormInput
                    type="checkbox"
                    label="Lien Release Letter"
                    register={register}
                    value={lienLetter}
                    onChange={toggleCheckbox}
                    name="lienLetter"
                  />
                )}
                {showOrder("Installment Contract") && (
                  <FormInput
                    type="checkbox"
                    label="Installment Contract"
                    register={register}
                    value={installmentContract}
                    onChange={toggleCheckbox}
                    name="installmentContract"
                  />
                )}
                {showOrder("Bill of Sale") && (
                  <FormInput
                    type="checkbox"
                    label="Bill of Sale"
                    register={register}
                    value={billOfSale}
                    onChange={toggleCheckbox}
                    name="billOfSale"
                  />
                )}
                {showOrder("Payment History") && (
                  <FormInput
                    type="checkbox"
                    label="Payment History"
                    register={register}
                    value={paymentHistory}
                    onChange={toggleCheckbox}
                    name="paymentHistory"
                  />
                )}
                {showOrder("Repo Affidavit") && (
                  <FormInput
                    type="checkbox"
                    label="Repo Affidavit"
                    register={register}
                    value={repoAffidavit}
                    onChange={toggleCheckbox}
                    name="repoAffidavit"
                  />
                )}
                {showOrder("One and the Same Letter") && (
                  <FormInput
                    type="checkbox"
                    label="One and the Same Letter"
                    register={register}
                    value={oneAndTheSameLetter}
                    onChange={toggleCheckbox}
                    name="oneAndTheSameLetter"
                  />
                )}
                {showOrder("Payment Status") && (
                  <FormInput
                    type="checkbox"
                    label="Payment Status"
                    register={register}
                    value={paymentStatus}
                    onChange={toggleCheckbox}
                    name="paymentStatus"
                  />
                )}
              </>
            )}
        </div>

        <div className="ProcessRequestButton">
          <Button disabled={!disabledProcessRequest} size="full-width">
            {fromRequestForm ? "Submit Orders" : "Submit New Orders"}
          </Button>
        </div>
      </form>
      <div className="PoweredByWrapper">
        <div className="PoweredBy">Powered by</div>
        <div className="XData">
          <span className="Blue">x</span>Data
        </div>
      </div>
      {fromRequestForm && (
        <div className="LogDisclaimer">
          *subject to your organization’s configurations related to letters of
          guarantee in positive equity and lease scenarios
        </div>
      )}
    </div>
  );
};

export default OrderingMenu;
