import React from "react";

import "./Footer.css";

const Footer = () => {
  return (
    <footer className="FooterWrapper">
      <div className="Copyright">
        <span className="FooterLogo">LossExpress&trade;</span> &copy;{" "}
        {new Date().getFullYear()}
      </div>

      <ul>
        <li>
          <a
            href="https://lossexpress.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>
        </li>
        <li>
          <a
            href="https://lossexpress.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>
        </li>
      </ul>
      <div className="VersionNumber">version {global.appVersion}</div>
    </footer>
  );
};

export default Footer;
