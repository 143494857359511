import React, { useState, useEffect } from "react";
import "./CollapsibleInfoBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { styles } from "../IconStyles";

const CollapsibleInfoBar = ({ name, icon, children, packet, open = false }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const isVerified =
    (packet?.titledOwnersNameVerified || packet?.titledState) && expanded;

  useEffect(() => {
    if (open) {
      setExpanded(true);
    }
  }, [open]);

  return (
    <div
      className={
        expanded
          ? "CollapsibleInfoBar__Container--Expanded"
          : "CollapsibleInfoBar__Container"
      }
    >
      <div className="CollapsibleInfoBar__Header" onClick={toggleExpanded}>
        <div className="CollapsibleInfoBar__Header--Icon">
          <FontAwesomeIcon
            icon={icon}
            style={
              isVerified
                ? styles.barIconVerified
                : expanded
                ? styles.barIconExpanded
                : styles.barIcon
            }
          />
        </div>
        <div className="CollapsibleInfoBar__Header--Name">{name}</div>
        {isVerified && (
          <div className="CollapsibleInfoBar__Header--Verified">
            Verified by Phone!
          </div>
        )}
        <div className="CollapsibleInfoBar__Header--Chevron">
          <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} />
        </div>
      </div>
      <div className="CollapsibleInfoBar__Children">{expanded && children}</div>
    </div>
  );
};

export default CollapsibleInfoBar;
