import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faCircleNotch,
  faChevronDown,
  faCopy,
  faAsterisk
} from "@fortawesome/free-solid-svg-icons";
import { styles } from "../IconStyles";
import copy from "clipboard-copy";
import format from "date-fns/format";
import QuickQuoteReceived from "../../QuickQuoteReceived";
import { formatDateGMT, formatTimeGMT } from "../../../services/dates";
import CancelQuoteModal from "../../CancelQuoteModal";
import {
  cancelLetterOfGuaranteeRequest,
  cancelOrderRequest,
  cancelPayoffQuoteRequest,
  createLoGRequest,
  createOrderRequest,
  createPayoffQuote,
  downloadPayoffPDF,
  fetchPacket,
  fetchAccountNumberOrSSN
} from "../../../services/packets";
import { setPayoffQuoteAsSeen } from "../../../services/quotes";
import download from "downloadjs";
import * as Sentry from "@sentry/browser";
import "./ClaimOrdersTable.css";
import { formatMoney } from "../../../utils";
import { getDayFromDate } from "../../../services/formatting";
import ModifyOrderModal from "./ModifyOrderModal";
import CancelLoGModal from "../../CancelLoGModal";

const formatOrderStatus = (
  status,
  inactiveOrder = false,
  showXlogNotification = false
) => {
  switch (status) {
    case "pending":
      return "Processing";
    case "fulfilled":
      if (showXlogNotification) {
        return (
          <div>
            Received{" "}
            <FontAwesomeIcon icon={faAsterisk} style={{ color: "#008CD5" }} />
          </div>
        );
      }
      return "Received";
    case "cancelled":
      if (!inactiveOrder) {
        return "Pending Re-Order";
      }

      return "Cancelled";
    default:
      return status;
  }
};

const formatDate = date => {
  if (date) {
    const dt = new Date(date);
    const dtDateOnly = new Date(
      dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000
    );

    return format(dtDateOnly, "MM-DD-YYYY");
  }

  return "N/A";
};

const createPayoffCopyText = (packet, accountNumberOrSSN) => {
  const info = packet.payoffQuote.paymentInformation;
  const remit = packet.payoffQuote.remittanceInformation;

  const remittanceText = `
  - Note customer's name, account number, and VIN on payoff check.
  - Payoff amount is ${formatMoney(info.netAmount)}
  - Title or Lien Release will be sent 10 - 15 business days after the payoff is posted.
  - Please provide an address with the check where we should send you the title/lien release.
  - To ensure timely release: Use certified funds [note customer's name and account number], and send check prior to payoff expiration date.`;

  const buildAddressCopyString = (address, lenderName) => {
    return `
${lenderName}
${address.streetAddress} ${
      address?.address2
        ? `
${address.address2}`
        : ""
    }
${address.city}, ${address.state} ${address.zipCode}`;
  };
  const layout = new Map([
    ["Owner's Name", packet?.ownersName],
    ["Titled Owner's Name", packet?.titledOwnersName],
    ["Owner's Street Address", packet?.ownersStreetAddress],
    ["Account Number", accountNumberOrSSN],
    ["lender", "newLine"],
    ["Lender Phone Number", packet?.lenderPhoneNumber],
    ["Payoff Amount", formatMoney(info?.netAmount)],
    ["Per Diem", formatMoney(info?.perDiem)],
    ["Valid Through Date", formatDate(info?.validUntil)],
    ["Finance Type", packet?.financeType],
    ["vehicle", "newLine"],
    ["Year", packet?.year],
    ["Make", packet?.make],
    ["Model", packet?.model],
    ["VIN", packet?.vin],
    ["paymentHistory", "newLine"],
    [
      "Minimum Monthly Payment",
      info?.minMonthlyPayment ? formatMoney(info?.minMonthlyPayment) : null
    ],
    [
      "Payment Due Date",
      info?.dueDate ? `${getDayFromDate(info?.dueDate)} of the month` : null
    ],
    [
      "lastPaymentDate",
      info?.lastPaymentDate ? formatDate(info?.lastPaymentDate) : null
    ],
    [
      "lastPaymentAmount",
      info?.lastPaymentAmount ? formatMoney(info?.lastPaymentAmount) : null
    ],
    ["remittance", "newLine"],
    ["Remittance Information", remittanceText],
    ["regularMail", "newLine"],
    [
      "Regular Mail",
      buildAddressCopyString(remit.regularMail, packet.lenderName)
    ],
    ["overnightMail", "newLine"],
    [
      "Overnight Mail",
      buildAddressCopyString(remit.overnightMail, packet.lenderName)
    ]
  ]);

  let copyText = ``;
  layout.forEach((value, key) => {
    if (value === "newLine") {
      copyText += `
`;
    } else if (value != null) {
      copyText += `${key}: ${value}
`;
    }
  });
  return copyText;
};

const ClaimOrdersTable = ({
  packet = {},
  setPacket = () => {},
  setLoading = () => {},
  downloading = [],
  setDownloading = () => {},
  refreshPacket = () => {},
  downloadDoc = () => {}
}) => {
  const [copied, setCopied] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
  const [error, setError] = useState(false);
  const [orders, setOrders] = useState([]);
  const [showPayoffWarning, setShowPayoffWarning] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [reorder, setReorder] = useState(false);
  const [cancelOrder, setCancelOrder] = useState(false);
  const [showModifyOrderModal, setShowModifyOrderModal] = useState(false);
  const [modifyOrderComplete, setModifyOrderComplete] = useState(false);
  const [showCancelLoGModal, setShowCancelLoGModal] = useState(false);
  const [isXlogManualAuth, setXlogManualAuth] = useState(
    packet?.letterOfGuaranteeRequest?.xlogRequested ?? false
  );

  const autoPayoffError = packet.payoffQuote?.autoPayoffError;
  const autoOnlyCarrier = packet.carrier.routeOneOnly;
  const isLogFulfilledByXlog = () => {
    const logOrder = packet?.orders?.find(
      o => o.type === "Letter of Guarantee"
    );
    if (!logOrder || logOrder.status !== "fulfilled") return false;

    const logDoc = packet?.documents?.find(
      d => d.documentId === logOrder.documentId
    );
    if (!logDoc) return false;

    const logFax = packet?.receivedDocuments?.find(
      f => f.receivedFaxId === logDoc.receivedFaxId
    );
    if (!logFax) return false;
    return logFax.faxInformation.type === "xlog";
  };

  const showLogAmountNotification =
    (isLogFulfilledByXlog() && packet?.xlogPayoffAmount) ||
    (!isLogFulfilledByXlog() && packet?.docValPayoff);

  const showXlogNotification =
    isLogFulfilledByXlog() && packet?.carrier?.xlogIndicator;

  const isLeaseAndNotPendingOrRetail =
    (packet?.financeType === "Lease" &&
      packet?.payoffQuote?.status !== "pending") ||
    packet?.financeType === "Retail";

  const copyPayoffInformation = async () => {
    const { accountNumberOrSSN } = await fetchAccountNumberOrSSN(
      packet.packetId
    );
    await copy(createPayoffCopyText(packet, accountNumberOrSSN));
    await setPayoffQuoteAsSeen(packet.packetId);
    setCopied(true);
  };

  useEffect(() => {
    const hasExistingOrder = type => {
      return packet?.orders?.some(order => order.type === type);
    };

    if (packet?.orders) {
      let rawOrders = packet.orders;

      if (packet?.payoffQuote) {
        const loanPayoffOrder = {
          type: "Loan Payoff",
          orderId: packet.payoffQuote?.payoffDataId,
          payoffDataId: packet.payoffQuote?.payoffDataId,
          status: packet.payoffQuote?.status,
          createdAt: packet.payoffQuote?.createdAt,
          updatedAt: packet.payoffQuote?.updatedAt,
          payoffAmount: packet.payoffQuote?.paymentInformation?.netAmount,
          perDiem: packet.payoffQuote?.paymentInformation?.perDiem,
          validThroughDate: packet.payoffQuote?.paymentInformation?.validUntil
        };

        rawOrders = [loanPayoffOrder, ...rawOrders];
      }
      // patch for legacy LoG orders only, show old LoG request objects as orders to the adjuster
      if (
        !hasExistingOrder("Letter of Guarantee") &&
        packet.letterOfGuaranteeRequest?.status === "fulfilled" &&
        packet.letterOfGuaranteeRequest?.receivedDocuments?.length > 0
      ) {
        const legacyLoGOrder = {
          type: "Letter of Guarantee",
          orderId: packet.letterOfGuaranteeRequest?.letterOfGuaranteeRequestId,
          status: packet.letterOfGuaranteeRequest?.status,
          createdAt: packet.letterOfGuaranteeRequest?.createdAt,
          updatedAt: packet.letterOfGuaranteeRequest?.updatedAt,
          receivedFaxId:
            packet.letterOfGuaranteeRequest?.receivedDocuments?.[0]
              .receivedFaxId,
          packetId: packet.packetId
        };
        rawOrders = [legacyLoGOrder, ...rawOrders];
      }
      setOrders(rawOrders);
      if (
        rawOrders.length > 0 &&
        rawOrders.find(o => o.type === "Loan Payoff")?.status === "fulfilled" &&
        rawOrders.find(o => o.type === "Letter of Guarantee")?.status ===
          "pending"
      ) {
        setShowPayoffWarning(true);
      } else {
        setShowPayoffWarning(false);
      }
    }
  }, [packet]);

  const getUnfulfillmentReasons = order => {
    const unfulfillmentReasons = [];
    for (const event of packet?.events) {
      if (order.orderId === event.orderId) {
        const parsedMessage = event.message.split(": ")[1];
        const finalParsedMessage = parsedMessage.split(" encountered")[0];
        unfulfillmentReasons.push(finalParsedMessage);
      }
    }
    return (
      <>
        <td>
          <span className="Bold">{`${
            unfulfillmentReasons.length > 0
              ? "Last Attempted:"
              : "Cancelled At:"
          }`}</span>
          <div>{formatDateGMT(order.updatedAt)}</div>
          <div>{formatTimeGMT(order.updatedAt)} CST</div>
        </td>
        <td className="ClaimOrders__Unfulfillment">
          Unfulfillment Reasons:
          {unfulfillmentReasons.length > 0 &&
            unfulfillmentReasons.map((reason, i) => (
              <div key={i} className="ClaimOrders__UnfulfillmentReasons">
                {reason}
              </div>
            ))}
          {unfulfillmentReasons.length === 0 && (
            <div className="ClaimOrders__UnfulfillmentReasons">
              User cancelled order
            </div>
          )}
        </td>
      </>
    );
  };

  const cancelPayoffRequest = () => {
    setShowCancelRequestModal(true);
  };

  const closeCancelRequestModal = () => {
    setShowCancelRequestModal(false);
  };

  const openModifyOrderModal = (order, reorder = false) => {
    if (reorder) {
      setReorder(true);
    } else {
      setCancelOrder(true);
    }
    setShowModifyOrderModal(true);
    setSelectedOrder(order);
  };

  const closeModifyOrderModal = () => {
    setShowModifyOrderModal(false);
    setSelectedOrder(null);
    setModifyOrderComplete(false);
    setReorder(false);
    setCancelOrder(false);
  };

  const cancelLoGRequest = () => {
    setShowCancelLoGModal(true);
  };

  const closeCancelModal = () => {
    setShowCancelLoGModal(false);
  };

  const onCancelRequest = async data => {
    setLoading(true);
    await cancelPayoffQuoteRequest(packet.packetId, data);

    closeCancelRequestModal();

    const p = await fetchPacket(packet.packetId);
    setPacket(p);
    setLoading(false);
  };

  const onCancelLoGRequest = async data => {
    setLoading(true);
    await cancelLetterOfGuaranteeRequest(packet.packetId, data);

    closeCancelModal();

    const p = await fetchPacket(packet.packetId);
    setPacket(p);
    setLoading(false);
  };

  const onCancelOrder = async order => {
    try {
      await cancelOrderRequest(
        packet?.packetId,
        order.orderId,
        order.type,
        "cancelled"
      );
      setModifyOrderComplete(true);
      refreshPacket();
    } catch (ex) {
      setError(true);
      Sentry.captureException(ex);
    }
  };

  const onReorder = async type => {
    try {
      await createOrderRequest(packet?.packetId, [type]);
      setModifyOrderComplete(true);
      setReorder(false);
      refreshPacket();
    } catch (ex) {
      setError(true);
      Sentry.captureException(ex);
    }
  };

  const requestLoG = async () => {
    try {
      await createLoGRequest(
        packet.packetId,
        isXlogManualAuth ? { xlogManualAuth: isXlogManualAuth } : null
      );
      setModifyOrderComplete(true);
      setReorder(false);
      refreshPacket();
    } catch (ex) {
      setError(
        "There was an error creating a letter of guarantee request on this claim."
      );
    }
  };

  const refreshPayoffQuote = async () => {
    try {
      await createPayoffQuote(packet.packetId, null, true);
      setModifyOrderComplete(true);
      setReorder(false);
      refreshPacket();
    } catch (ex) {
      Sentry.captureException(ex);
      setError(true);
    }
  };

  const downloadPayoffQuote = async () => {
    setDownloading(downloading => downloading.concat(packet.packetId));
    const blob = await downloadPayoffPDF(packet.packetId);
    download(blob, `${packet.claimNumber} - LE Payoff.pdf`);
    setDownloading(downloading =>
      downloading.filter(p => p !== packet.packetId)
    );
  };

  const columns = [
    {
      name: "Order Type",
      key: "type"
    },
    {
      name: "Status",
      key: "status"
    },
    {
      name: "Action",
      key: "action"
    }
  ];

  const handleRowClick = rowId => {
    const currentExpandedRows = expandedRows;
    const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);

    const newExpandedRows = isRowCurrentlyExpanded
      ? currentExpandedRows.filter(id => id !== rowId)
      : currentExpandedRows.concat(rowId);

    setExpandedRows(newExpandedRows);
  };

  const renderOrder = (order, inactiveOrders = []) => {
    const clickCallback = () => handleRowClick(order.orderId);

    const isPayoff = order.type === "Loan Payoff";
    const isLoG = order.type === "Letter of Guarantee";

    const showPayoff =
      isPayoff ||
      (isLoG && isLogFulfilledByXlog() && packet?.xlogPayoffAmount) ||
      (isLoG && !isLogFulfilledByXlog() && packet?.docValPayoff);

    const orderDetailsMiddle = () => {
      if (order.status === "cancelled") {
        return null;
      }

      if (!showPayoff) {
        return (
          <>
            <td>
              <div className="ClaimOrders__MiddleExpandedContainer">
                <div>
                  <span className="Bold">Requested At:</span>
                  {order.requestedFromLenderAt ? (
                    <>
                      <div>{formatDateGMT(order.requestedFromLenderAt)}</div>
                      <div>
                        {formatTimeGMT(order.requestedFromLenderAt)} CST
                      </div>
                    </>
                  ) : (
                    <div>---</div>
                  )}
                </div>
                <div>
                  <span className="Bold">Spoke With:</span>
                  <div>
                    {order.spokeWith
                      ? order.spokeWith === "Automated Request"
                        ? "Representative"
                        : `${order.spokeWith}`
                      : "---"}
                  </div>
                </div>
              </div>
            </td>
            {order.fulfilledAt ? (
              <td>
                <span className="Bold">Received At:</span>
                <div>{formatDateGMT(order.fulfilledAt)}</div>
                <div>{formatTimeGMT(order.fulfilledAt)} CST</div>
              </td>
            ) : (
              <td>
                <span className="Bold">Expected Delivery Date:</span>{" "}
                {order.expectedDeliveryDate ? (
                  <>
                    <div>{formatDateGMT(order.expectedDeliveryDate)}</div>
                    <div>{formatTimeGMT(order.expectedDeliveryDate)} CST</div>
                  </>
                ) : (
                  <div>---</div>
                )}
              </td>
            )}
          </>
        );
      }

      let payoff, perDiem, vtd;

      if (isPayoff) {
        payoff = formatMoney(order.payoffAmount);
        perDiem = order.perDiem ? formatMoney(order.perDiem) : "---";
        vtd = order?.validThroughDate
          ? formatDateGMT(order.validThroughDate)
          : "---";
      } else if (isLogFulfilledByXlog()) {
        payoff = formatMoney(packet.xlogPayoffAmount);
        perDiem = "---"; // update this when we start saving xlog perdiem value
        vtd = "---"; // update this when we start saving xlog valid through date;
      } else {
        payoff = formatMoney(packet.docValPayoff);
        perDiem = packet?.docValPerDiem
          ? formatMoney(packet.docValPerDiem)
          : "---";
        vtd = packet?.docValValidThrough
          ? formatDateGMT(packet.docValValidThrough)
          : "---";
      }

      return (
        <>
          <td>
            <div className="ClaimOrders__MiddleExpandedContainer">
              <div style={{ marginTop: ".8em" }}>
                <span className="Bold">Received At:</span>
                <div>{formatDateGMT(order.updatedAt)}</div>
                <div>{formatTimeGMT(order.updatedAt)} CST</div>
              </div>
              <div>
                <span className="Bold">Payoff Amount:</span>
                <div style={{ marginBottom: ".5em" }}>{payoff}</div>
                <span className="Bold">Per Diem:</span>
                <div>{perDiem}</div>
              </div>
            </div>
          </td>
          <td>
            <div>
              <div style={{ marginTop: ".8em" }}>
                <span className="Bold">Valid Through Date:</span>
                <div>{vtd}</div>
              </div>

              <QuickQuoteReceived
                packet={packet}
                refreshPacket={refreshPacket}
              />
            </div>
          </td>
        </>
      );
    };

    const orderRows = [
      <tr key={"row-data-" + order.orderId} data-testid={order.orderId}>
        <td>{order.type === "Copy of Title" ? "Title Image" : order.type}</td>
        <td
          className={
            order.status === "cancelled" ? "ClaimOrders__Cancelled" : ""
          }
          data-testid={`order-status-${order.orderId}`}
        >
          {formatOrderStatus(
            order.status,
            inactiveOrders.includes(order.type),
            order.type === "Letter of Guarantee" && showXlogNotification
          )}
        </td>
        <td>
          {order?.status === "pending" &&
            order?.type !== "Loan Payoff" &&
            order?.type !== "Letter of Guarantee" &&
            !packet.archived && (
              <span
                className="ClaimOrders__Cancel"
                onClick={() => openModifyOrderModal(order)}
              >
                Cancel
              </span>
            )}
          {order?.status === "pending" &&
            order?.type === "Loan Payoff" &&
            !packet.archived &&
            !autoOnlyCarrier && (
              <span
                className="ClaimOrders__Cancel"
                onClick={cancelPayoffRequest}
              >
                Cancel
              </span>
            )}
          {order?.status === "pending" &&
            order?.type === "Letter of Guarantee" &&
            !packet.archived && (
              <span className="ClaimOrders__Cancel" onClick={cancelLoGRequest}>
                Cancel
              </span>
            )}
          {order?.status === "cancelled" &&
            order?.type !== "Loan Payoff" &&
            order?.type !== "Letter of Guarantee" &&
            !packet.archived &&
            !inactiveOrders.includes(order?.type) && (
              <span
                className="ClaimOrders__Reorder"
                onClick={() => openModifyOrderModal(order, true)}
              >
                Re-Order
              </span>
            )}

          {order?.status === "cancelled" &&
            order?.type === "Loan Payoff" &&
            !packet.archived && (
              <span
                className="ClaimOrders__Reorder"
                onClick={() => openModifyOrderModal(order, true)}
              >
                Re-Order
              </span>
            )}

          {order?.status === "cancelled" &&
            order?.type === "Letter of Guarantee" &&
            !packet.archived && (
              <span
                className="ClaimOrders__Reorder"
                onClick={() => openModifyOrderModal(order, true)}
              >
                Re-Order
              </span>
            )}

          {(order?.status === "pending" || order?.status === "cancelled") &&
            order.documentId && (
              <span
                className="ClaimOrders__Download"
                onClick={downloadDoc(order)}
              >
                <br /> Download Previous
                <FontAwesomeIcon
                  style={{ marginLeft: ".33em" }}
                  icon={
                    downloading.includes(order.documentId)
                      ? faCircleNotch
                      : faDownload
                  }
                  spin={downloading.includes(order.documentId)}
                />
              </span>
            )}

          {order?.status === "fulfilled" && order.documentId && (
            <span
              className="ClaimOrders__Download"
              onClick={downloadDoc(order)}
            >
              Download
              <FontAwesomeIcon
                style={{ marginLeft: ".33em" }}
                icon={
                  downloading.includes(order.documentId)
                    ? faCircleNotch
                    : faDownload
                }
                spin={downloading.includes(order.documentId)}
              />
            </span>
          )}

          {order?.status === "fulfilled" && order.type === "Loan Payoff" && (
            <span
              className="ClaimOrders__Download"
              onClick={downloadPayoffQuote}
            >
              Download
              <FontAwesomeIcon
                style={{ marginLeft: ".33em" }}
                icon={
                  downloading.includes(packet.packetId)
                    ? faCircleNotch
                    : faDownload
                }
                spin={downloading.includes(packet.packetId)}
              />
            </span>
          )}
          {order.status === "fulfilled" &&
            order.type === "Loan Payoff" &&
            isLeaseAndNotPendingOrRetail && (
              <span
                className="ClaimOrders__Copy"
                onClick={copyPayoffInformation}
                data-testid={"CopyIcon"}
              >
                {copied ? "Copied!" : "Copy"}
                <FontAwesomeIcon
                  style={{ marginLeft: ".33em" }}
                  icon={faCopy}
                />
              </span>
            )}

          <FontAwesomeIcon
            style={styles.tableChevron}
            icon={faChevronDown}
            onClick={clickCallback}
            data-testid={"ChevronIcon"}
          />
        </td>
      </tr>
    ];

    if (expandedRows.includes(order.orderId)) {
      orderRows.push(
        <tr
          className={"ClaimOrders__ExpandedRow"}
          key={"row-expanded-" + order.orderId}
        >
          <td>
            <span className="Bold">Created At:</span>{" "}
            <div>{formatDateGMT(order.createdAt)}</div>
            <div>{formatTimeGMT(order.createdAt)} CST</div>
          </td>
          {orderDetailsMiddle()}
          {order.status === "cancelled" && order.type !== "Loan Payoff" && (
            <>{getUnfulfillmentReasons(order)}</>
          )}
        </tr>
      );
      if (showPayoffWarning && order.type === "Loan Payoff") {
        orderRows.push(
          <div className="OrderMessage PayoffWarningMessage">
            These values are preliminary values only. Please wait for the Letter
            of Guarantee before sending payment.
          </div>
        );
      }
      if (
        order.type === "Letter of Guarantee" &&
        order.status === "fulfilled" &&
        showXlogNotification
      ) {
        orderRows.push(
          <div className="OrderMessage XlogMessage">
            *This order was fulfilled via a Certificate of Guarantee (xLOG)
          </div>
        );
      }
      if (
        order.type === "Letter of Guarantee" &&
        order.status === "fulfilled" &&
        showLogAmountNotification
      ) {
        orderRows.push(
          <div className="OrderMessage XlogMessage">
            *Please confirm that these values match the values on the Letter of
            Guarantee
          </div>
        );
      }
    }

    return orderRows;
  };

  let allOrderRows = [];

  if (orders) {
    orders
      .sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1))
      .forEach(order => {
        const perOrderRows = renderOrder(
          order,
          packet?.carrier?.inactiveOrders
        );
        allOrderRows = allOrderRows.concat(perOrderRows);
      });
  }

  return (
    <div>
      {showModifyOrderModal && (
        <ModifyOrderModal
          order={selectedOrder}
          cancelOrder={cancelOrder}
          onCancelOrder={onCancelOrder}
          reorder={reorder}
          onReorder={onReorder}
          modifyOrderComplete={modifyOrderComplete}
          onClose={closeModifyOrderModal}
          refreshPayoffQuote={refreshPayoffQuote}
          requestLoG={requestLoG}
          packet={packet}
          xlogManualAuth={isXlogManualAuth}
          setXlogManualAuth={setXlogManualAuth}
        />
      )}

      {showCancelRequestModal && (
        <CancelQuoteModal
          packet={packet}
          onCancelRequest={onCancelRequest}
          close={closeCancelRequestModal}
        />
      )}

      {showCancelLoGModal && (
        <CancelLoGModal
          close={closeCancelModal}
          onCancelRequest={onCancelLoGRequest}
        />
      )}

      {error && (
        <div className="ClaimOrders__ErrorMessage">
          There was an issue processing your request. Please try again later or
          contact support using the button on the bottom left-hand corner of
          your screen.
        </div>
      )}

      {!packet.archived && autoOnlyCarrier && autoPayoffError && (
        <div className="ClaimOrders__ErrorMessage">
          Payoff could not be automatically retrieved.
        </div>
      )}

      <div
        className="ClaimView__ClaimOrdersContainer"
        data-testid="ClaimOrdersTable"
      >
        <div className="ClaimView__ClaimOrders--Title">Claim Orders</div>
        {orders?.length > 0 ? (
          <table cellSpacing={0} className="ClaimOrders__Table">
            <thead>
              <tr>
                {columns.map((column, index) => (
                  <th key={index}>{column.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>{allOrderRows}</tbody>
          </table>
        ) : (
          <div className="NoOrdersMessage">
            There are no orders for this claim. Please complete the form and
            submit an order on the right side of this screen otherwise no action
            will be made.
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimOrdersTable;
