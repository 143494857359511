import { useState, useEffect } from "react";
import { getAccountInfo } from "../services/users";

const useUserAccountInfo = () => {
  const [account, setAccount] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    getAccountInfo().then(acc => {
      if (isCancelled) return;

      setAccount(acc);
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  return [account, setAccount];
};

export default useUserAccountInfo;
