import React from "react";

import { matchPath, Route, Router } from "react-router-dom";

import SessionValidation from "./components/SessionValidation";

import CacheBuster from "./components/CacheBuster";
import CornerSupport from "./components/CornerSupport";
import Login from "./components/Login";
import ViewUsers from "./components/ViewUsers";
import OrderManagement from "./components/OrderManagement";
import PasswordChangeRequired from "./components/PasswordChangeRequired";
import AccountPage from "./components/AccountPage";
import ForgotPassword from "./components/ForgotPassword";
import ForgotUsername from "./components/ForgotUsername";
import PasswordReset from "./components/PasswordReset";
import ClaimView from "./components/ClaimView";
import SSOSuccess from "./components/SSOSuccess";
import LogoutPage from "./components/LogoutPage";
import DataAndReportingPage from "./components/DataAndReportingPage";
import DisclaimerPage from "./components/DisclaimerPage";
import Debug from "./components/Debug";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { createBrowserHistory } from "history";

import { websocket } from "./services/websocket";
import { getAccountInfo } from "./services/users";

import "@fastlane-llc/lossexpress-ui-kit/dist/index.css";

import "./App.css";
import ErrorBoundary from "./components/ErrorBoundary";
import Dashboard from "./components/Dashboard";
import SSOFailure from "./components/SSOFailure/SSOFailure";
import DirectMessageIframe from "./components/DirectMessageForm/DirectMessageIframe/";
import OTPLogin from "./components/OTPLogin";
import PayoffPortal from "./components/PayoffPortal";

const history = createBrowserHistory();
const routes = [
  { path: "/" },
  { path: "/login" },
  { path: "/disclaimer" },
  { path: "/admin/users" },
  { path: "/admin/data" },
  { path: "/home" },
  { path: "/validate-device" },
  { path: "/password-change-required" },
  { path: "/account" },
  { path: "/forgot-password" },
  { path: "/reset-password/:resetPasswordId" },
  { path: "/packet/:packetId" },
  { path: "/packet/:packetId/:type" },
  { path: "/packets/:packetId" },
  { path: "/claims/:packetId" },
  { path: "/sso-success" },
  { path: "/logout" },
  { path: "/direct-messages/:packetId/:oauthToken" },
  { path: "/otp/:packetId" }
];

Sentry.init({
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(
        history,
        routes,
        matchPath
      ),
      tracingOrigins: ["localhost", "api.lossexpress.com"]
    })
  ],
  dsn: "https://ae739f0f0c604a10b896b682f0ebe0f1@sentry.io/4382634",
  environment: `${process.env.REACT_APP_ENV_NAME}`,
  release: `lossexpress-carrier-interface@${global.appVersion}`,
  tracesSampleRate: 1.0
});

Sentry.configureScope(scope => {
  scope.setTag("app_version", global.appVersion);
});

const App = () => {
  const setUserForSocket = async () => {
    try {
      const u = await getAccountInfo();
      if (u) {
        websocket.emit("setUserId", u.userId);
      }
    } catch {}
  };

  websocket.on("connected", setUserForSocket);

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;

        if (!isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
          <ErrorBoundary>
            <Router history={history}>
              <div>
                {!isLatestVersion && (
                  <div className="AppReloadBanner">
                    A new version of LossExpress is now available.{" "}
                    <span onClick={refreshCacheAndReload}>
                      Click here to reload the app.
                    </span>
                  </div>
                )}

                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/disclaimer" component={DisclaimerPage} />

                <Route
                  path="/admin/users"
                  component={SessionValidation(ViewUsers)}
                />
                <Route
                  path="/admin/orders"
                  component={SessionValidation(OrderManagement)}
                />
                <Route
                  path="/admin/data"
                  component={SessionValidation(DataAndReportingPage)}
                />
                {/*<Route path="/home" component={SessionValidation(HomeScreen)} />*/}
                <Route path="/home" component={SessionValidation(Dashboard)} />
                <Route
                  path="/password-change-required"
                  component={PasswordChangeRequired}
                />
                <Route
                  path="/account"
                  component={SessionValidation(AccountPage)}
                />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/forgot-username" component={ForgotUsername} />
                <Route
                  path="/reset-password/:resetPasswordId"
                  component={PasswordReset}
                />
                <Route
                  path="/packet/:packetId"
                  component={SessionValidation(ClaimView)}
                  exact
                />
                <Route
                  path="/packet/:packetId/:type"
                  component={SessionValidation(ClaimView)}
                />
                <Route
                  path="/packets/:packetId"
                  component={SessionValidation(ClaimView)}
                />

                <Route
                  path="/claims/:packetId"
                  component={SessionValidation(ClaimView)}
                />

                <Route path="/sso-success" component={SSOSuccess} />
                <Route path="/sso-failure" component={SSOFailure} />

                <Route
                  path="/direct-messages/:packetId/:oauthToken"
                  component={DirectMessageIframe}
                />

                <Route path="/logout" component={LogoutPage} />

                <Route exact path="/debug/info" component={Debug} />
                <Route
                  path={"/otp-payoff-form/:packetId"}
                  component={PayoffPortal}
                />
                <Route path={"/otp/:packetId"} component={OTPLogin} />

                <CornerSupport />
              </div>
            </Router>
          </ErrorBoundary>
        );
      }}
    </CacheBuster>
  );
};

export default Sentry.withProfiler(App);
