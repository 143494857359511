import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { parseDomain, fromUrl } from "parse-domain";

import "./Layout.css";

import Header from "../Header";
import SideContainer from "../SideContainer";

import { getVendorByDomain } from "../../services/vendors";
import { fetchCurrentNotices } from "../../services/notices";

import {
  saveVendorId,
  saveCarriers,
  removeCarrierUserFromLS,
  fetchHiddenNotices,
  saveHiddenNotices,
  removeNoticesFromLS,
  saveVendorSSOSettings,
  persistItemToLS,
  storeCountriesAndStates
} from "../../services/local-storage";
import { fetchCountriesAndStates } from "../../services/locations";
import { logout } from "../../services/auth-request";
import Footer from "../Footer/Footer";
import NoticeBanner from "../NoticeBanner";
import { getAccountInfo } from "../../services/users";
import { Tooltip } from "react-tooltip";

export const formatSubdomain = () => {
  const { subDomains, domain } = parseDomain(fromUrl(window.location.href));
  let subDomain = subDomains?.length > 0 ? subDomains[0] : domain;
  if (
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "staging"
  )
    subDomain = "";
  return subDomain;
};

const Layout = ({
  children,
  workPacket,
  setPacket = () => {},
  updatingClaim,
  cancelUpdating = () => {},
  refreshPacket = () => {},
  uploadingDocuments,
  setUploadingDocuments = () => {},
  showOrderingMenu,
  setShowOrderingMenu = () => {},
  setDisabled = () => {},
  loading
}) => {
  const [redirectToLogout, setRedirectToLogout] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [notices, setNotices] = useState(null);
  const [hiddenNotices, setHiddenNotices] = useState([]);
  const [carrier, setCarrier] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    (async function() {
      try {
        const subDomain = formatSubdomain();
        const vendor = await getVendorByDomain(subDomain);
        const user = await getAccountInfo();
        setCurrentUser(user);
        persistItemToLS("user", JSON.stringify(user));

        const userRequestingCompany = vendor?.carriers.find(
          c => c.requestingCompanyId === user.requestingCompanyId
        );
        setCarrier(userRequestingCompany || vendor.carriers[0]);

        saveVendorId(vendor.vendorId);
        saveVendorSSOSettings({
          userProvisioning: vendor.supportsUserProvisioning
        });
        saveCarriers(vendor.carriers);

        const validNotices = await fetchCurrentNotices();
        setNotices(validNotices);
        if (validNotices.length === 0) {
          removeNoticesFromLS();
        }

        const locations = await fetchCountriesAndStates();
        storeCountriesAndStates(locations);
      } catch (ex) {
        console.log(ex);
        // await logout();
        // removeCarrierUserFromLS("user");
      }
    })();

    setHiddenNotices(fetchHiddenNotices());
  }, []);

  useEffect(() => {
    if (notices) {
      const validHiddenIds = hiddenNotices.filter(
        hN => notices.findIndex(n => n.noticeId === hN) > -1
      );

      saveHiddenNotices(validHiddenIds);
    }
  }, [hiddenNotices, notices]);

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  const signOut = async () => {
    await logout();
    removeCarrierUserFromLS("user");
    setRedirectToLogout(true);
  };

  const toggleNotice = noticeId => {
    setHiddenNotices(hidden => {
      hidden.push(noticeId);
      return [].concat(hidden);
    });
  };

  return (
    <div className="LayoutWrapper">
      {redirectToLogout && <Redirect to="/logout" />}

      <Header
        userMenuOpen={showUserMenu}
        toggleUserMenu={toggleUserMenu}
        signOut={signOut}
        carrier={carrier}
        currentUser={currentUser}
      />
      <div className="BannersContainer">
        {notices
          ? notices
              .filter(n => hiddenNotices.indexOf(n.noticeId) === -1)
              .map(notice => (
                <NoticeBanner
                  notice={notice}
                  key={notice.noticeId}
                  toggleNotice={toggleNotice}
                />
              ))
          : null}
      </div>
      <div className="LayoutContainer">{children}</div>
      {!workPacket?.carrier?.routeOneOnly && (
        <SideContainer
          workPacket={workPacket}
          setPacket={setPacket}
          updatingClaim={updatingClaim}
          cancelUpdating={cancelUpdating}
          refreshPacket={refreshPacket}
          uploadingDocuments={uploadingDocuments}
          setUploadingDocuments={setUploadingDocuments}
          showOrderingMenu={showOrderingMenu}
          setShowOrderingMenu={setShowOrderingMenu}
          setDisabled={setDisabled}
          loading={loading}
        />
      )}
      <Footer />
      <Tooltip
        id="lender-alias-tt"
        effect="solid"
        place={"left"}
        z-index={100000}
        opacity={1}
      />
    </div>
  );
};

export default Layout;
