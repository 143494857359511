import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";
import download from "downloadjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import {
  fetchPacket,
  archivePacket,
  reassignPacketToUser,
  downloadReceivedDocument,
  downloadUploadedDocument,
  setNotificationPreferenceForPacket,
  restorePacket
} from "../../services/packets";
import { getAccountInfo } from "../../services/users";
import Layout from "../Layout";
import Loading from "../Loading";
import ReassignModal from "../ReassignModal";
import CounterOfferModal from "../CounterOfferModal";
import "./ClaimView.css";
import { styles } from "./IconStyles";
import CollapsibleInfoBarList from "./CollapsibleInfoBarList";
import ClaimOrdersTable from "./ClaimOrdersTable";
import { formatMoney } from "../../utils";

const ClaimView = () => {
  const [loading, setLoading] = useState(true);
  const [packet, setPacket] = useState({});
  const [updatingClaim, setUpdatingClaim] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [showAccountNumberOrSSN, setShowAccountNumberOrSSN] = useState(false);
  const [downloading, setDownloading] = useState([]);
  const [showReassignModal, setShowReassignModal] = useState(false);
  const [isArchivable, setIsArchivable] = useState(false);
  const [counterId, setCounterId] = useState("");
  const [closeModal, setCloseModal] = useState(false);
  const [uploadingDocuments, setUploadingDocuments] = useState(false);
  const [showOrderingMenu, setShowOrderingMenu] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { packetId } = useParams();

  const refreshPacket = useCallback(async () => {
    let p = await fetchPacket(packetId);
    const vehicleType = `${p.year ? p.year : ""} ${p.make ? p.make : ""} ${
      p.model ? p.model : ""
    }`;
    p = { ...p, vehicleType };
    setPacket(p);
    setShowAccountNumberOrSSN(false);
    setLoading(false);
  }, [packetId]);

  useEffect(() => {
    (async function() {
      await refreshPacket();

      const currentUser = await getAccountInfo();
      setCurrentUserId(currentUser.userId);
    })();
  }, [packetId, refreshPacket]);

  useEffect(() => {
    let archive;

    const pendingOrders =
      packet.orders?.length > 0 &&
      packet.orders.some(order => order.status === "pending");

    if (
      packet.payoffQuote?.status !== "pending" &&
      packet.letterOfGuaranteeRequest?.status !== "pending" &&
      !pendingOrders
    ) {
      archive = true;
    }

    if (packet.archived) {
      archive = false;
    }

    setIsArchivable(archive);
  }, [packet]);

  const downloadDoc = doc => async () => {
    if (!downloading.includes(doc.documentId)) {
      setDownloading(downloading => downloading.concat(doc.documentId));
      const blob = await downloadUploadedDocument(
        packet.packetId,
        doc.documentId
      );
      download(blob, doc.name);
      setDownloading(downloading =>
        downloading.filter(d => d !== doc.documentId)
      );
    }
  };

  const downloadReceivedDoc = doc => async () => {
    const blob = await downloadReceivedDocument(
      packet.packetId,
      doc.receivedFaxId
    );
    download(blob, doc.name);
  };

  const openReassignModal = () => {
    setShowReassignModal(true);
  };

  const closeReassignModal = () => {
    setShowReassignModal(false);
  };

  const onArchivePacket = async () => {
    setLoading(true);
    await archivePacket(packetId);
    const p = await fetchPacket(packetId);
    setPacket(p);
    setLoading(false);
  };

  const onDearchivePacket = async () => {
    setLoading(true);
    await restorePacket(packetId);
    const p = await fetchPacket(packetId);
    setPacket(p);
    setLoading(false);
  };

  const onReassignPacket = async (user, stopNotifications) => {
    setLoading(true);

    await reassignPacketToUser(packet.packetId, user.userId);

    if (stopNotifications) {
      await setNotificationPreferenceForPacket(
        packet.packetId,
        false,
        packet.userId
      );
    }

    closeReassignModal();

    const p = await fetchPacket(packet.packetId);
    setPacket(p);
    setLoading(false);
  };

  const handleCloseModal = async () => {
    await refreshPacket();
    setCloseModal(!closeModal);
  };

  const onEditInfo = () => {
    setShowOrderingMenu(false);
    setUploadingDocuments(false);
    setUpdatingClaim(true);
  };

  return (
    <Layout
      workPacket={packet}
      setPacket={setPacket}
      updatingClaim={updatingClaim}
      cancelUpdating={() => setUpdatingClaim(false)}
      refreshPacket={refreshPacket}
      uploadingDocuments={uploadingDocuments}
      setUploadingDocuments={setUploadingDocuments}
      showOrderingMenu={showOrderingMenu}
      setShowOrderingMenu={setShowOrderingMenu}
      setDisabled={setDisabled}
      loading={loading}
    >
      <Loading show={loading} />
      {showReassignModal && !packet.carrier.hybridDisplay && (
        <ReassignModal
          packet={packet}
          reassignPacket={onReassignPacket}
          close={closeReassignModal}
        />
      )}

      {closeModal && (
        <CounterOfferModal
          packet={packet}
          handleCloseModal={handleCloseModal}
          downloadDoc={downloadDoc}
          downloadReceivedDoc={downloadReceivedDoc}
          counterId={counterId}
          formatMoney={formatMoney}
          refreshPacket={refreshPacket}
        />
      )}

      {!loading && (
        <div>
          <div className="ClaimView__Header">
            {!packet.carrier.routeOneOnly ? (
              <h2 className="ClaimView__Header--Number">
                Claim #{packet.claimNumber}
              </h2>
            ) : (
              <div className="ClaimView__Header--NoNumber" />
            )}
            <div className="ClaimView__Header--VIN" translate="no">
              {packet.vin ? "VIN:" : ""} {packet.vin}
            </div>
            <div className="ClaimView__HeaderButtonsContainer">
              {(currentUserId === packet.userId ||
                packet.carrier.usersCanEditAnyClaim) &&
                packet.payoffQuote &&
                !packet.carrier.hybridDisplay && (
                  <Button invert size="small" onClick={onEditInfo}>
                    Edit Info
                  </Button>
                )}

              {isArchivable && !packet.carrier.hybridDisplay && (
                <div className="ClaimView__Header--Button">
                  <Button
                    invert
                    size="small"
                    color="red"
                    onClick={onArchivePacket}
                  >
                    Archive
                  </Button>
                </div>
              )}

              {packet.archived && !packet.carrier.hybridDisplay && (
                <div className="ClaimView__Header--Button">
                  <Button invert size="small" onClick={onDearchivePacket}>
                    Restore
                  </Button>
                </div>
              )}
            </div>
          </div>
          {!packet.carrier.hybridDisplay && (
            <div className="ClaimView__UserName">
              <FontAwesomeIcon style={styles.headerIcon} icon={faUserCircle} />
              <span onClick={openReassignModal} className="UserName">
                {packet.user?.name}{" "}
              </span>
            </div>
          )}
          {packet.carrier.hybridDisplay && (
            <div className="ClaimView__UserNameNoLink">
              <FontAwesomeIcon style={styles.headerIcon} icon={faUserCircle} />
              <span className="UserNameNoLink">{packet.user?.name} </span>
            </div>
          )}
          <div className="ClaimView__Content">
            <CollapsibleInfoBarList
              packet={packet}
              setCounterId={setCounterId}
              setCloseModal={setCloseModal}
              currentUserId={currentUserId}
              refreshPacket={refreshPacket}
              packetId={packetId}
              setShowAccountNumberOrSSN={setShowAccountNumberOrSSN}
              showAccountNumberOrSSN={showAccountNumberOrSSN}
              setPacket={setPacket}
              downloadDoc={downloadDoc}
              downloading={downloading}
              setUploadingDocuments={setUploadingDocuments}
              setShowOrderingMenu={setShowOrderingMenu}
              setUpdatingClaim={setUpdatingClaim}
              disabled={disabled}
              setDisabled={setDisabled}
            />
            <ClaimOrdersTable
              packet={packet}
              setPacket={setPacket}
              setLoading={setLoading}
              downloading={downloading}
              setDownloading={setDownloading}
              refreshPacket={refreshPacket}
              downloadReceivedDoc={downloadReceivedDoc}
              downloadDoc={downloadDoc}
            />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default ClaimView;
