import React from "react";
import { Link } from "react-router-dom";

import Error from "../Error";
import XLienSideWrapper from "../XLienSideWrapper";

const SSOFailure = () => {
  return (
    <XLienSideWrapper>
      <div>
        <div className="LoginBox">
          <h2>SSO Failure Encountered</h2>
          <Error>Unable to sign in via single-sign on.</Error>

          <Link to="/">Click to Try Again</Link>
        </div>
      </div>
    </XLienSideWrapper>
  );
};

export default SSOFailure;
