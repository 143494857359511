import { flFetch as fetch, validateStatus } from "./fetch";

export const resendOTPEmail = packetId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/email-otp`,
    {
      method: "POST",
      body: JSON.stringify({ packetId })
    }
  ).then(validateStatus);
};

export const submitOtp = (otp, packetId) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/validate-otp`,
    {
      method: "POST",
      body: JSON.stringify({ otp })
    }
  ).then(validateStatus);
};

export const submitPayoffFromOTP = (otp, packetId, payoffData) => {
  payoffData.otp = otp;
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/otp-payoff-quote`,
    {
      method: "PUT",
      body: JSON.stringify(payoffData)
    }
  ).then(validateStatus);
};

export const getCarrierLogoImage = carrierId => {
  return `${process.env.REACT_APP_API_URL}/info/carrier-logo/${carrierId}`;
};
