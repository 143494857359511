import React from "react";

import "./BasicClaimView.css";
import { formatDate } from "@fastlane-llc/lossexpress-ui-kit/helpers/dates";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";

const BasicClaimView = ({ packet }) => {
  return (
    <>
      <h1 className={"BasicClaimInfoHeader"}>Claim Information</h1>
      <div className={"BasicClaimInfoContainer"}>
        <p>Claim #: {packet?.claimNumber}</p>
        <p>Settlement Amount: ${packet?.settlementAmount}</p>
        <p>Owner Name: {packet?.ownersName}</p>
        <p>Owner Address: {packet?.ownersStreetAddress}</p>
        <p>Account #: {packet?.accountNumber}</p>
        <p>VIN: {packet?.vin}</p>
        <p>
          Year, Make, Model: {packet?.year}, {packet?.make}, {packet?.model}
        </p>
        <p>Date of Loss: {formatDate(packet?.dateOfLoss)}</p>
        <p>Title Remittance Address: {packet?.titleRemittanceAddress}</p>
        <div>Supporting Documents:</div>
        <ul style={{ listStyle: "None" }}>
          {packet?.documents?.map(document => {
            return (
              <li>
                <p>
                  <a
                    style={{ color: "#00a3fe" }}
                    href={document.signedRequest}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faLink} /> {document.type}
                  </a>{" "}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default BasicClaimView;
