import React from "react";
import Modal from "../../../Modal";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";
import "./ModifyOrderModal.css";
import Checkbox from "../../../Checkbox";

const ModifyOrderModal = ({
  onClose,
  order,
  onCancelOrder,
  modifyOrderComplete,
  reorder,
  onReorder,
  cancelOrder,
  refreshPayoffQuote,
  requestLoG,
  packet = null,
  xlogManualAuth,
  setXlogManualAuth
}) => {
  const handleXlogManualAuth = isSelected => {
    setXlogManualAuth(isSelected);
  };

  return (
    <Modal
      onClose={onClose}
      noCloseButton
      style={{ width: "30em", top: "20em" }}
    >
      {cancelOrder && !modifyOrderComplete && (
        <>
          <div className="ModifyOrder__Message">
            You're about to cancel an Order for{" "}
            <div className="ModifyOrder__OrderType">
              {" "}
              {order?.type === "Copy of Title" ? "Title Image" : order.type}
            </div>
          </div>
          <div className="ModifyOrder__ButtonContainer">
            <Button
              style={{ width: "10em" }}
              size="full-width"
              color="gray"
              onClick={onClose}
            >
              Never mind
            </Button>
            <Button
              style={{ width: "10em" }}
              size="full-width"
              onClick={() => onCancelOrder(order)}
            >
              Agree
            </Button>
          </div>
        </>
      )}
      {reorder && !modifyOrderComplete && (
        <>
          <div className="ModifyOrder__Message">
            You're about to place a Re-Order for
            <div className="ModifyOrder__OrderType">
              {" "}
              {order?.type === "Copy of Title" ? "Title Image" : order.type}
            </div>
            {order?.type === "Letter of Guarantee" &&
              packet.carrier?.xlogEnabled &&
              packet.carrier?.xlogRequiresManualAuth && (
                <Checkbox
                  isSelected={xlogManualAuth}
                  label={"xLoG Manual Auth?"}
                  onSelect={handleXlogManualAuth}
                  name={"xlogManualAuth"}
                />
              )}
          </div>

          <div className="ModifyOrder__ButtonContainer">
            <Button
              style={{ width: "10em" }}
              size="full-width"
              color="gray"
              onClick={onClose}
            >
              Cancel
            </Button>

            {!order?.payoffDataId && order?.type !== "Letter of Guarantee" && (
              <Button
                style={{ width: "10em" }}
                size="full-width"
                onClick={() => onReorder(order?.type, order?.packetId)}
              >
                Confirm
              </Button>
            )}

            {order?.type === "Letter of Guarantee" && (
              <Button
                style={{ width: "10em" }}
                size="full-width"
                onClick={() => requestLoG(order?.packetId)}
              >
                Confirm
              </Button>
            )}

            {order?.payoffDataId && (
              <Button
                style={{ width: "10em" }}
                size="full-width"
                onClick={() => refreshPayoffQuote(order?.packetId)}
              >
                Confirm
              </Button>
            )}
          </div>
        </>
      )}

      {modifyOrderComplete && (
        <>
          <div className="ModifyOrder__Message">
            <div>Thank you!</div>
            <div>Our team has been notified.</div>
          </div>
          <div className="ModifyOrder__CloseButton">
            <Button size="full-width" onClick={onClose}>
              Close
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default ModifyOrderModal;
