import { flFetch as fetch, validateStatus } from "./fetch";
import { removeFromLS } from "./local-storage";

export const login = (username, password) => {
  removeFromLS();

  return fetch(`${process.env.REACT_APP_API_URL}/payoff/login`, {
    method: "GET",
    headers: {
      Authorization: btoa(`${username}:${password}`)
    }
  }).then(validateStatus);
};

export const changePassword = (currentPassword, newPassword) => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/auth/password-change`, {
    method: "POST",
    body: JSON.stringify({
      currentPassword,
      newPassword
    })
  }).then(validateStatus);
};

export const resetPassword = (passwordResetId, password) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/payoff/auth/reset-password/${passwordResetId}`,
    {
      method: "POST",
      body: JSON.stringify({
        password
      })
    }
  ).then(validateStatus);
};

export const performSsoHandshake = sessionId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/payoff/auth/sso-handshake/${sessionId}`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const validSessionCheck = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/auth/valid-session`, {
    method: "GET"
  }).then(validateStatus);
};

export const logout = () => {
  return fetch(`${process.env.REACT_APP_API_URL}/payoff/auth/logout`, {
    method: "DELETE"
  }).then(validateStatus);
};
