import React, { useEffect, useState } from "react";
import {
  Button,
  FormInput,
  SearchSelect
} from "@fastlane-llc/lossexpress-ui-kit";
import "./PayoffInputForm.css";
import { useForm } from "react-hook-form";
import { submitPayoffFromOTP } from "../../../services/otp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { faPlusSquare } from "@fortawesome/free-solid-svg-icons";

const formatAddress = address => {
  if (address?.value === "create") return address.label;
  if (address?.streetAddress === "") return "";
  return (
    <>
      <span>
        {address?.streetAddress},
        {address?.streetAddress2 ? ` ${address.streetAddress2},` : ""}{" "}
        {address?.city}, {address?.state} {address?.zipCode}
      </span>
    </>
  );
};

const PayoffInputForm = ({ otp, packet, setIsCompleted, setIsError }) => {
  const [states, setStates] = useState(null);

  const [regularAddress, setRegularAddress] = useState(null);
  const [regularAddresses, setRegularAddresses] = useState(null);
  const [tempRegularAddress, setTempRegularAddress] = useState(null);
  const [regularLocked, setRegularLocked] = useState(false);

  const [overnightAddress, setOvernightAddress] = useState(null);
  const [editingRegular, setEditingRegular] = useState(false);
  const [editingOvernight, setEditingOvernight] = useState(false);
  const [overnightAddresses, setOvernightAddresses] = useState(null);
  const [tempOvernightAddress, setTempOvernightAddress] = useState(null);
  const [overnightLocked, setOvernightLocked] = useState(false);

  const [regularOptions, setRegularOptions] = useState(null);
  const [overnightOptions, setOvernightOptions] = useState(null);
  const {
    register,
    unregister,
    errors,
    handleSubmit,
    setValue,
    watch,
    control
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const regularAddressForm = watch("regularAddress");
  const overnightAddressForm = watch("overnightAddress");

  register("regularAddress.state");
  register("overnightAddress.state");

  useEffect(() => {
    (async function() {
      const states = packet.states.map(s => {
        return {
          label: s.stateName,
          value: s.stateInitials,
          stateId: s.stateId
        };
      });
      setStates(states);
    })();
  }, [packet.states]);

  useEffect(() => {
    if (packet && states) {
      const addresses = packet.lender.addresses.map(a => {
        return {
          ...a,
          state: states.find(s => s.stateId === a.stateId)?.label
        };
      });
      const ra = addresses.filter(a => a.isOvernight === false);
      const oa = addresses.filter(a => a.isOvernight === true);
      if (ra.length < 1) {
        const nullAddress = {
          streetAddress: "",
          streetAddress2: "",
          city: "",
          state: "",
          zipCode: ""
        };
        setRegularAddress(nullAddress);
        setRegularLocked(true);
        setTempRegularAddress(nullAddress);
        setEditingRegular(true);
      } else {
        ra.push({
          lenderAddressId: "create"
        });
      }
      if (oa.length < 1) {
        const nullAddress = {
          streetAddress: "",
          streetAddress2: "",
          city: "",
          state: "",
          zipCode: ""
        };
        setOvernightAddress(nullAddress);
        setOvernightLocked(true);
        setTempOvernightAddress(nullAddress);
        setEditingOvernight(true);
      } else {
        oa.push({
          lenderAddressId: "create"
        });
      }
      setRegularAddresses(ra);
      setRegularOptions(
        ra.map(a => {
          return {
            label:
              a.lenderAddressId === "create" ? (
                <>
                  <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
                  <span> Add a New Regular Address</span>
                </>
              ) : (
                formatAddress(a)
              ),
            value: a.lenderAddressId
          };
        })
      );

      setOvernightAddresses(oa);
      setOvernightOptions(
        oa.map(a => {
          return {
            label:
              a.lenderAddressId === "create" ? (
                <>
                  <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
                  <span> Add a New Overnight Address</span>
                </>
              ) : (
                formatAddress(a)
              ),
            value: a.lenderAddressId || "create"
          };
        })
      );

      if (ra?.length > 0) {
        setRegularAddress(ra[0]);
        setTempRegularAddress(ra[0]);
      }
      if (oa?.length > 0) {
        setOvernightAddress(oa[0]);
        setTempOvernightAddress(oa[0]);
      }
    }
  }, [packet, states, setEditingOvernight, setEditingRegular]);

  useEffect(() => {
    setValue("regularAddress", regularAddress);
    setValue("overnightAddress", overnightAddress);
  }, [setValue, regularAddress, overnightAddress]);

  const setEditRegular = () => {
    if (!regularLocked) {
      setEditingRegular(!editingRegular);
      setRegularLocked(!regularLocked);
    }
  };

  const setEditOvernight = () => {
    if (!overnightLocked) {
      setEditingOvernight(!editingOvernight);
      setOvernightLocked(!overnightLocked);
    }
  };

  const onSubmit = async formData => {
    const {
      comments,
      ownersName,
      payoffAmount: rawPayoffAmount,
      perDiem: rawPerDiem,
      expectedDeliveryDate,
      validThroughDate
    } = formData;
    const payoffAmount = parseFloat(rawPayoffAmount?.replace(/[^0-9.]/gm, ""));
    const perDiem = parseFloat(rawPerDiem?.replace(/[^0-9.]/gm, ""));
    const payoffData = {
      ownersName,
      payoffAmount,
      perDiem,
      expectedDeliveryDate,
      validThroughDate,
      regularAddress: {
        lenderAddressId:
          regularAddress.lenderAddressId === "new"
            ? null
            : regularAddress.lenderAddressId,
        streetAddress: regularAddress.streetAddress,
        streetAddress2: regularAddress.streetAddress2,
        city: regularAddress.city,
        state: regularAddress.state,
        zipCode: regularAddress.zipCode
      },
      overnightAddress: {
        lenderAddressId:
          overnightAddress.lenderAddressId === "new"
            ? null
            : overnightAddress.lenderAddressId,
        streetAddress: overnightAddress.streetAddress,
        streetAddress2: overnightAddress.streetAddress2,
        city: overnightAddress.city,
        state: overnightAddress.state,
        zipCode: overnightAddress.zipCode
      }
    };
    if (comments && comments.length > 0) {
      payoffData.comments = comments;
    }
    submitPayoffFromOTP(otp, packet.packetId, payoffData)
      .then(() => {
        unregister("regularAddress.state");
        setIsCompleted(true);
      })
      .catch(() => {
        setIsError(true);
      });
  };

  const onRegularAddressSelectChange = async selected => {
    console.log(selected);
    if (selected?.value === "create") {
      const nullAddress = {
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        zipCode: "",
        lenderAddressId: "create"
      };
      setRegularAddress(nullAddress);
      setRegularLocked(true);
      setEditRegular(true);
    } else {
      const address = regularAddresses.find(
        a => a.lenderAddressId === selected?.value
      );
      const selectedAddress = {
        streetAddress: address?.streetAddress,
        streetAddress2: address?.streetAddress2,
        city: address?.city,
        state: address?.state,
        zipCode: address?.zipCode,
        lenderAddressId: address?.lenderAddressId
      };
      setRegularAddress(selectedAddress);
      setValue("regularAddress", selectedAddress);
      setTempRegularAddress(selectedAddress);
    }
  };

  const onOvernightAddressSelectChange = selected => {
    if (selected?.value === "create") {
      const nullAddress = {
        streetAddress: "",
        streetAddress2: "",
        city: "",
        state: "",
        zipCode: "",
        lenderAddressId: "create"
      };
      setOvernightAddress(nullAddress);
      setOvernightLocked(true);
      setEditOvernight(true);
    } else {
      const address = overnightAddresses.find(
        a => a.lenderAddressId === selected?.value
      );
      const selectedAddress = {
        streetAddress: address?.streetAddress,
        streetAddress2: address?.streetAddress2,
        city: address?.city,
        state: address?.state,
        zipCode: address?.zipCode,
        lenderAddressId: address?.lenderAddressId
      };
      setOvernightAddress(selectedAddress);
      setValue("overnightAddress", selectedAddress);
      setTempOvernightAddress(selectedAddress);
    }
  };

  const onSelectStateChangeRegular = selected => {
    setRegularAddress(address => {
      address.state = selected.label;
      address.stateId = selected.stateId;
      return address;
    });
    setValue("regularAddress.state", selected.label);
    watch("regularAddress.state");
  };

  const onSelectStateChangeOvernight = selected => {
    setOvernightAddress(address => {
      address.state = selected.label;
      address.stateId = selected.stateId;
      return address;
    });
    setValue("overnightAddress.state", selected.label);
    watch("overnightAddress.state");
  };

  const updateAddress = (field, value, regular = true) => {
    if (regular) {
      const newAddress = { ...regularAddress, [field]: value };
      setRegularAddress(newAddress);
    } else {
      const newAddress = { ...overnightAddress, [field]: value };
      setOvernightAddress(newAddress);
    }
  };

  const saveNewRegularAddress = () => {
    const index = regularAddresses.findIndex(
      a => a.lenderAddressId === regularAddress.lenderAddressId
    );
    const regularAddressesCopy = [...regularAddresses];
    regularAddressesCopy[index] = regularAddress;
    console.log(regularAddress, regularAddressesCopy);
    regularAddressesCopy[index].lenderAddressId =
      regularAddress.lenderAddressId === "create"
        ? "new"
        : regularAddress.lenderAddressId;
    setRegularAddresses(regularAddressesCopy);
    setRegularOptions(
      regularAddressesCopy.map(a => {
        return {
          label: formatAddress(a),
          value: a.lenderAddressId === "create" ? "new" : a.lenderAddressId
        };
      })
    );
    setRegularLocked(false);
    setEditingRegular(false);
  };

  const saveNewOvernightAddress = () => {
    const index = overnightAddresses.findIndex(
      a => a.lenderAddressId === overnightAddress.lenderAddressId
    );
    const overnightAddressesCopy = [...overnightAddresses];
    overnightAddressesCopy[index] = overnightAddress;
    overnightAddressesCopy[index].lenderAddressId =
      overnightAddress.lenderAddressId === "create"
        ? "new"
        : overnightAddress.lenderAddressId;
    setOvernightAddresses(overnightAddressesCopy);
    setOvernightOptions(
      overnightAddressesCopy.map(a => {
        return {
          label: formatAddress(a),
          value: !a.lenderAddressId ? "new" : a.lenderAddressId
        };
      })
    );
    setOvernightLocked(false);
    setEditingOvernight(false);
  };

  const cancelRegularAddressEdit = () => {
    setRegularLocked(false);
    setRegularAddress(tempRegularAddress);
    setEditingRegular(!editingRegular);
  };
  const cancelOvernightAddressEdit = () => {
    setOvernightLocked(false);
    setOvernightAddress(tempOvernightAddress);
    setEditingOvernight(!editingOvernight);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} data-testid="payoffOTPForm">
      <div className={"PayoffInputFormRow"}>
        <div className={"PayoffInputFormLabel"}>
          <span>Your Name:</span>
        </div>
        <div className={"PayoffInputFormValue"}>
          <FormInput
            name={"ownersName"}
            placeholder={"Your Name"}
            unregister={unregister}
            register={register}
            required
            error={errors.ownersName}
          ></FormInput>
        </div>
      </div>

      <div className={"PayoffInputFormRow"}>
        <div className={"PayoffInputFormLabel"}>
          <span>Payoff Amount:</span>
        </div>
        <div className={"PayoffInputFormValue"}>
          <FormInput
            name={"payoffAmount"}
            format="currency"
            register={register}
            unregister={unregister}
            control={control}
            required
            defaultValue={""}
            error={errors.payoffAmount}
          ></FormInput>
        </div>
      </div>

      <div className={"PayoffInputFormRow"}>
        <div className={"PayoffInputFormLabel"}>
          <span>Per Diem:</span>
        </div>
        <div className={"PayoffInputFormValue"}>
          <FormInput
            format="currency"
            name={"perDiem"}
            register={register}
            unregister={unregister}
            control={control}
            required
            defaultValue={""}
            error={errors.perDiem}
          ></FormInput>
        </div>
      </div>

      <div className={"PayoffInputFormRow"}>
        <div className={"PayoffInputFormLabel"}>
          <span>
            Expected Delivery Date
            <br />
            (Letter of Guarantee):
          </span>
        </div>
        <div className={"PayoffInputFormValue"}>
          <FormInput
            label={"Expected Delivery Date LoG"}
            name={"expectedDeliveryDate"}
            type={"date"}
            disabledDates={["past"]}
            // required
            register={register}
            unregister={unregister}
            onChange={setValue}
            error={errors.expectedDeliveryDate}
          ></FormInput>
        </div>
      </div>

      <div className={"PayoffInputFormRow"}>
        <div className={"PayoffInputFormLabel"}>
          <span>Valid Through Date:</span>
        </div>
        <div className={"PayoffInputFormValue"}>
          <FormInput
            label={"Valid Through Date"}
            name={"validThroughDate"}
            type={"date"}
            disabledDates={["past"]}
            required
            register={register}
            unregister={unregister}
            onChange={setValue}
            error={errors.validThroughDate}
          ></FormInput>
        </div>
      </div>

      {regularOptions && (
        <>
          <div className={"PayoffInputFormRow"}>
            <div className={"PayoffInputFormLabel"}>
              <span>Regular Address: </span>
            </div>
            <div
              className={`editAddressIcon ${
                regularLocked ? "disabledEditIcon" : ""
              }`}
            >
              <FontAwesomeIcon
                icon={faEdit}
                onClick={setEditRegular}
              ></FontAwesomeIcon>
            </div>
            <div className={`PayoffInputFormValue AddressSelect`}>
              <SearchSelect
                placeholder="Regular Address"
                name="regularAddress"
                value={
                  regularAddress
                    ? {
                        label: formatAddress(regularAddress),
                        value: regularAddress.lenderAddressId
                      }
                    : null
                }
                defaultValue={
                  regularAddress
                    ? {
                        label: formatAddress(regularAddress),
                        value: regularAddress.lenderAddressId
                      }
                    : null
                }
                error={errors.regularAddress}
                onSelect={() => onRegularAddressSelectChange}
                creatable={false}
                register={register}
                unregister={unregister}
                options={regularOptions}
                disabled={regularLocked}
              />
            </div>
          </div>
          {editingRegular && (
            <div className={"PayoffInputFormRow"}>
              <div className={"PayoffInputFormLabel"}>
                {regularLocked && (
                  <>
                    <Button
                      onClick={saveNewRegularAddress}
                      disabled={
                        !(
                          regularAddressForm.streetAddress &&
                          regularAddressForm.city &&
                          regularAddressForm.state &&
                          regularAddressForm.zipCode
                        )
                      }
                    >
                      Save
                    </Button>
                    <Button onClick={cancelRegularAddressEdit}>Cancel</Button>
                  </>
                )}
              </div>
              <div className={"PayoffInputFormValue"}>
                <div className={`AddressInput`}>
                  <div className="FullRow">
                    <FormInput
                      name={"regularAddress.streetAddress"}
                      placeholder={"Regular Street Address"}
                      unregister={unregister}
                      register={register}
                      required
                      error={errors["regularAddress.streetAddress"]}
                      onChange={e =>
                        updateAddress("streetAddress", e.target.value)
                      }
                      defaultValue={regularAddress?.streetAddress}
                    ></FormInput>
                  </div>
                  <div className="HalfRow">
                    <FormInput
                      name={"regularAddress.streetAddress2"}
                      placeholder={"Regular Address 2"}
                      unregister={unregister}
                      register={register}
                      error={errors["regularAddress.streetAddress2"]}
                      onChange={e =>
                        updateAddress("streetAddress2", e.target.value)
                      }
                      defaultValue={regularAddress?.streetAddress2}
                    ></FormInput>
                    <FormInput
                      name={"regularAddress.city"}
                      placeholder={"Regular City"}
                      unregister={unregister}
                      register={register}
                      required
                      error={errors["regularAddress.city"]}
                      onChange={e => updateAddress("city", e.target.value)}
                      defaultValue={regularAddress?.city}
                    ></FormInput>
                  </div>
                  <div className="HalfRow">
                    <SearchSelect
                      label="Regular State"
                      name="regularAddress.state"
                      placeholder={"Regular State"}
                      error={errors.regularAddress}
                      onSelect={() => onSelectStateChangeRegular}
                      defaultValue={
                        regularAddress?.state
                          ? {
                              label: regularAddress?.state,
                              value: regularAddress?.state
                            }
                          : null
                      }
                      creatable={false}
                      options={states}
                    ></SearchSelect>
                    <FormInput
                      name={"regularAddress.zipCode"}
                      placeholder={"Regular Zip Code"}
                      onChange={e => updateAddress("zipCode", e.target.value)}
                      unregister={unregister}
                      register={register}
                      required
                      error={errors["regularAddress.zipCode"]}
                      defaultValue={regularAddress?.zipCode}
                    ></FormInput>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {overnightOptions && (
        <>
          <div className={"PayoffInputFormRow"}>
            <div className={"PayoffInputFormLabel"}>
              <span>Overnight Address: </span>
            </div>
            <div
              className={`editAddressIcon ${
                overnightLocked ? "disabledEditIcon" : ""
              }`}
            >
              <FontAwesomeIcon
                icon={faEdit}
                onClick={setEditOvernight}
              ></FontAwesomeIcon>
            </div>
            <div className={`PayoffInputFormValue AddressSelect`}>
              <SearchSelect
                placeholder="Overnight Address"
                name="overnightAddress"
                value={
                  overnightAddress
                    ? {
                        label: formatAddress(overnightAddress),
                        value: overnightAddress.lenderAddressId
                      }
                    : null
                }
                defaultValue={
                  overnightAddress
                    ? {
                        label: formatAddress(overnightAddress),
                        value: overnightAddress.lenderAddressId
                      }
                    : null
                }
                error={errors.overnightAddress}
                onSelect={() => onOvernightAddressSelectChange}
                creatable={false}
                register={register}
                unregister={unregister}
                options={overnightOptions}
                disabled={overnightLocked}
              />
            </div>
          </div>
          {editingOvernight && (
            <div className={"PayoffInputFormRow"}>
              <div className={"PayoffInputFormLabel"}>
                {overnightLocked && (
                  <>
                    <Button
                      onClick={saveNewOvernightAddress}
                      disabled={
                        !(
                          overnightAddressForm.streetAddress &&
                          overnightAddressForm.city &&
                          overnightAddressForm.state &&
                          overnightAddressForm.zipCode
                        )
                      }
                    >
                      Save
                    </Button>
                    <Button onClick={cancelOvernightAddressEdit}>Cancel</Button>
                  </>
                )}
              </div>
              <div className={"PayoffInputFormValue"}>
                <div className={`AddressInput`}>
                  <div className="FullRow">
                    <FormInput
                      name={"overnightAddress.streetAddress"}
                      placeholder={"Overnight Street Address"}
                      unregister={unregister}
                      register={register}
                      required
                      error={errors["overnightAddress.streetAddress"]}
                      onChange={e =>
                        updateAddress("streetAddress", e.target.value, false)
                      }
                      defaultValue={overnightAddress?.streetAddress}
                    ></FormInput>
                  </div>
                  <div className="HalfRow">
                    <FormInput
                      name={"overnightAddress.streetAddress2"}
                      placeholder={"Overnight Address 2"}
                      unregister={unregister}
                      register={register}
                      error={errors["overnightAddress.streetAddress2"]}
                      onChange={e =>
                        updateAddress("streetAddress2", e.target.value, false)
                      }
                      defaultValue={overnightAddress?.streetAddress2}
                    ></FormInput>
                    <FormInput
                      name={"overnightAddress.city"}
                      placeholder={"Overnight City"}
                      unregister={unregister}
                      register={register}
                      required
                      error={errors["overnightAddress.city"]}
                      onChange={e =>
                        updateAddress("city", e.target.value, false)
                      }
                      defaultValue={overnightAddress?.city}
                    ></FormInput>
                  </div>
                  <div className="HalfRow">
                    <SearchSelect
                      label="Overnight State"
                      name="overnightAddress.state"
                      placeholder={"Overnight State"}
                      error={errors.overnightAddress}
                      onSelect={() => onSelectStateChangeOvernight}
                      defaultValue={
                        overnightAddress?.state
                          ? {
                              label: overnightAddress?.state,
                              value: overnightAddress?.state
                            }
                          : null
                      }
                      creatable={false}
                      register={register}
                      options={states}
                    ></SearchSelect>
                    <FormInput
                      name={"overnightAddress.zipCode"}
                      placeholder={"Overnight Zip Code"}
                      onChange={e =>
                        updateAddress("zipCode", e.target.value, false)
                      }
                      unregister={unregister}
                      register={register}
                      required
                      error={errors["overnightAddress.zipCode"]}
                      defaultValue={overnightAddress?.zipCode}
                    ></FormInput>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <div className={"PayoffInputFormRow"}>
        <div className={"PayoffInputFormLabel"}>
          <span>Notes/Comments:</span>
        </div>
        <div className={"PayoffInputFormValue"}>
          <FormInput
            name={"comments"}
            type={"textarea"}
            register={register}
            unregister={unregister}
            label={"Notes/Comments"}
          ></FormInput>
        </div>
      </div>
      <Button>Submit</Button>
    </form>
  );
};

export default PayoffInputForm;
