import React, { useState, useEffect } from "react";
import DayPickerInput from "react-day-picker/DayPickerInput";
import format from "date-fns/format";

import "react-day-picker/lib/style.css";
import "./FormInput.css";

const validations = {
  vehicleLocation: {
    label: "Vehicle Location",
    regex: /^.*$/,
    message: "Vehicle address is required"
  },
  titleRemittanceAddress: {
    label: "Title Remittance Address",
    regex: /^.*$/,
    message: "Title remittance address is required"
  },
  lenderName: {
    label: "Lender Name",
    regex: /^[A-Z0-9\-\s'\\/]{0,}$/i,
    message:
      "Lender name can only contain the following characters: A-Z, 0-9, -, ', \\, /."
  },
  accountNumber: {
    label: "Account Number",
    regex: /^[A-Z0-9\-\s'\\/_#]{0,}$/i,
    message:
      "Account number can only contain the following characters: A-Z, 0-9, -, ', \\, /, _, #."
  },
  claimNumber: {
    label: "Claim Number",
    regex: /^[A-Z0-9\-\s'\\/_#]+$/i,
    message:
      "Claim numbers can only contain the following characters: A-Z, 0-9, -, ', \\, /, _, #."
  },
  lotNumber: {
    label: "Lot Number",
    regex: /^[A-Z0-9\-\s'\\/_#]+$/i,
    message:
      "Lot numbers can only contain the following characters: A-Z, 0-9, -, ', \\, /, _, #."
  },
  vin: {
    label: "VIN",
    regex: /^[a-hj-npr-z0-9]{8}[0-9xX][a-hj-npr-z0-9]{8}$|^$/i,
    message: "VIN is invalid"
  },
  settlementAmount: {
    label: "Settlement Amount",
    regex: /^[0-9$,.]+$/,
    message:
      "Settlement amount must be a valid dollar amount (and can contain $, commas, and periods)."
  },
  amountPaidToLender: {
    label: "Amount Paid To Lender",
    regex: /^[0-9]{0,}\.?[0-9]{0,2}$/,
    message:
      "Amount paid to lender must be a valid number, rounded to the nearest cent."
  },
  deductible: {
    label: "Deductible",
    regex: /^[0-9]{0,}\.?[0-9]{0,2}$/,
    message: "Deductible must be a valid number, rounded to the nearest cent."
  },
  ownersPhoneNumber: {
    label: "Owner's Phone Number",
    regex: /^\(?[0-9]{3}\)?\s?[0-9]{3}-?\s?[0-9]{4}$/,
    message: "Phone number must be 10 numbers long."
  },
  emailAddress: {
    label: "Email Address",
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "Not a valid email address"
  },
  odometer: {
    label: "Odometer",
    regex: /^[0-9,]*$/i,
    message: "Not a valid odometer reading"
  },
  ownersName: {
    label: "Owner's Name",
    regex: /^[A-Z0-9\-\s'\\/_#]+$/i,
    message: "Owner's Name is invalid"
  },
  ownersEmailAddress: {
    label: "Owner's Email Address",
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "Not a valid email address"
  },
  ownersStreetAddress: {
    label: "Owner's Street Address",
    regex: /^.*$/i,
    message: "Owner's Street Address is invalid"
  },
  ownersCity: {
    label: "Owner's City",
    regex: /^[A-Z0-9\-\s'\\/_#]+$/i,
    message: "Owner's City is invalid"
  },
  ownersState: {
    label: "Owner's State",
    regex: /^[A-Z0-9\-\s'\\/_#]+$/i,
    message: "Owner's State is invalid"
  },
  ownersZipCode: {
    label: "Owner's ZIP Code",
    regex: /^[A-Z0-9\-\s'\\/_#]+$/i,
    message: "Owner's ZIP Code is invalid"
  },
  adjusterName: {
    label: "Adjuster's Name",
    regex: /^[A-Z0-9\-\s'\\/_#]+$/i,
    message: "Adjuster's name is invalid"
  },
  adjusterPhoneNumber: {
    label: "Adjuster Phone Number",
    regex: /^\(?[0-9]{3}\)?\s?[0-9]{3}-?\s?[0-9]{4}$/,
    message: "Phone number must be 10 numbers long."
  },
  adjusterEmailAddress: {
    label: "Adjuster Email Address",
    regex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
    message: "Not a valid email address"
  },
  cancellationExplanation: {
    label: "Provide additional explanations for the cancellation",
    regex: /.*/i,
    message: ""
  },
  dateOfLoss: {
    label: "Date of Loss"
  },
  validThroughDate: {
    label: "Valid Through Date"
  },
  payoffAmount: {
    label: "Payoff Amount",
    regex: /^[0-9$,.]+$/,
    message:
      "Payoff amount must be a valid dollar amount (and can contain $, commas, and periods)."
  },
  perDiem: {
    label: "Per Diem",
    regex: /^[0-9$,.]+$/,
    message:
      "Per diem must be a valid dollar amount (and can contain $, commas, and periods)."
  },
  username: {
    label: "Username",
    regex: /^[a-z0-9@._-]+$/i,
    message:
      "Usernames can only contain letters and numbers, @ signs, periods (.) and underscores."
  },
  password: {
    label: "Password",
    regex: /^.{12,}$/,
    message: "Password must be at least 12 characters long"
  },
  firstName: {
    label: "First Name",
    regex: /^.*$/,
    message: ""
  },
  lastName: {
    label: "Last Name",
    regex: /^.*$/,
    message: ""
  },
  subject: {
    label: "Issue Subject",
    regex: /^.*$/,
    message: ""
  },
  description: {
    label: "Issue Description",
    regex: /^.*$/,
    message: ""
  }
};

const formatDate = date => {
  if (date !== "") {
    return format(date, "MM-DD-YYYY");
  }

  return null;
};

const FormInput = ({
  label,
  name,
  register = () => {},
  required = false,
  onDateChange = () => {},
  disabled,
  onChange,
  value,
  error,
  defaultValue,
  validationRegex,
  validationMessage,
  type = "text"
}) => {
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    let v = value;
    if (!v && defaultValue) {
      v = defaultValue;
    }

    if (v !== "" && v !== null && typeof v !== "undefined") {
      setTyping(true);
    }
  }, [value, defaultValue]);

  const onFocus = () => {
    setTyping(true);
  };

  const onBlur = async e => {
    let v = e.currentTarget.value;

    if (v === "" || v === null || typeof v === "undefined") {
      setTyping(false);
    }
  };

  const pattern = validations[name] || {
    regex: /^.*$/i,
    message: ""
  };

  switch (name) {
    case "logCancellationReason":
      return (
        <fieldset className="FormInputRadioButtonWrapper">
          <label className="FormInputRadioButtonGroup">
            Reason for Cancellation?
          </label>

          <label tabIndex={0} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="logCancellationReason"
              name="logCancellationReason"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Lender sent letter of guarantee directly"
              checked={
                value
                  ? value === "Lender sent letter of guarantee directly"
                  : defaultValue === "Lender sent letter of guarantee directly"
              }
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">
              Lender sent letter of guarantee directly
            </span>
          </label>

          <label tabIndex={1} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="logCancellationReason"
              name="logCancellationReason"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Letter of guarantee not needed"
              checked={
                value
                  ? value === "Letter of guarantee not needed"
                  : defaultValue === "Letter of guarantee not needed"
              }
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">
              Letter of guarantee not needed
            </span>
          </label>

          <label tabIndex={2} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="logCancellationReason"
              name="logCancellationReason"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Other"
              checked={value ? value === "Other" : defaultValue === "Other"}
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">Other</span>
          </label>
        </fieldset>
      );
    case "cancellationReason":
      return (
        <fieldset className="FormInputRadioButtonWrapper">
          <label className="FormInputRadioButtonGroup">
            Reason for Cancellation?
          </label>

          <label tabIndex={0} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="cancellationReason"
              name="cancellationReason"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Lender reached out and gave payoff directly"
              checked={
                value
                  ? value === "Lender reached out and gave payoff directly"
                  : defaultValue ===
                    "Lender reached out and gave payoff directly"
              }
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">
              Lender reached out and gave payoff directly
            </span>
          </label>

          <label tabIndex={1} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="cancellationReason"
              name="cancellationReason"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Customer gave payoff information"
              checked={
                value
                  ? value === "Customer gave payoff information"
                  : defaultValue === "Customer gave payoff information"
              }
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">
              Customer gave payoff information
            </span>
          </label>

          <label tabIndex={2} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="cancellationReason"
              name="cancellationReason"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Other"
              checked={value ? value === "Other" : defaultValue === "Other"}
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">Other</span>
          </label>
        </fieldset>
      );
    case "issueType":
      return (
        <fieldset className="FormInputRadioButtonWrapper">
          <label className="FormInputRadioButtonGroup">Issue Type</label>

          <label tabIndex={0} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="issueType"
              name="issueType"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Login Issue"
              checked={
                value ? value === "Login Issue" : defaultValue === "Login Issue"
              }
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">Login Issue</span>
          </label>

          <label tabIndex={1} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="issueType"
              name="issueType"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Validation Code Not Received"
              checked={
                value
                  ? value === "Validation Code Not Received"
                  : defaultValue === "Validation Code Not Received"
              }
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">
              Validation Code Not Received
            </span>
          </label>

          <label tabIndex={2} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="issueType"
              name="issueType"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Forgotten Password"
              checked={
                value
                  ? value === "Forgotten Password"
                  : defaultValue === "Forgotten Password"
              }
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">
              Forgotten Password
            </span>
          </label>
        </fieldset>
      );
    case "financeType":
      return (
        <fieldset className="FormInputRadioButtonWrapper">
          <label className="FormInputRadioButtonGroup">Finance Type</label>

          <label tabIndex={0} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="financeTypeRetail"
              name="financeType"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Retail"
              checked={value ? value === "Retail" : defaultValue === "Retail"}
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">Retail</span>
          </label>

          <label tabIndex={1} className="FormInputRadioButtonOption">
            <input
              className="FormInputRadioButtonInput"
              id="financeTypeLease"
              name="financeType"
              onChange={onChange}
              ref={register({ required })}
              type="radio"
              value="Lease"
              checked={value ? value === "Lease" : defaultValue === "Lease"}
            />

            <span className="FormInputRadioButtonStyled">
              <span className="FormInputRadioButtonStyledChecked" />
            </span>

            <span className="FormInputRadioButtonLabel">Lease</span>
          </label>
        </fieldset>
      );
    case "dateOfLoss":
    case "dateOfBirth":
    case "validThroughDate":
      return (
        <div className={`FormDayPicker FormInput ${error && " FormError"}`}>
          <label className="active" htmlFor={name}>
            {label ? label : pattern.label}
          </label>
          <DayPickerInput
            value={value ? formatDate(value) : null}
            formatDate={formatDate}
            onDayChange={onDateChange(name)}
            dayPickerProps={{
              selectedDays: value,
              disabledDays: [
                {
                  after: new Date()
                }
              ],
              showOutsideDays: true
            }}
            placeholder={"MM/DD/YYYY"}
            onBlur={onBlur}
            onFocus={onFocus}
          />
          {error && <span className="FormErrorMessage">{error.message}</span>}
        </div>
      );
    case "bigSearchBar":
      return (
        <div className={`FormInput FormBigSearchBar ${error && " FormError"}`}>
          <label className={typing ? "active" : ""} htmlFor={name}>
            {label ? label : pattern.label}
          </label>
          <input
            disabled={disabled}
            id={name}
            name={name}
            ref={register}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={defaultValue}
          />
        </div>
      );
    case "cancellationExplanation":
      return (
        <div className={`FormInput ${error && " FormError"}`}>
          <label className={typing ? "active" : ""} htmlFor={name}>
            {label ? label : pattern.label}
          </label>
          <textarea
            disabled={disabled}
            id={name}
            name={name}
            ref={register({
              required,
              pattern: {
                value: !validationRegex ? pattern.regex : validationRegex,
                message: !validationMessage
                  ? pattern.message
                  : validationMessage
              }
            })}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={defaultValue}
          />

          {error && <span className="FormErrorMessage">{error.message}</span>}
        </div>
      );
    case "description":
      return (
        <div className={`FormInput ${error && " FormError"}`}>
          <label className={typing ? "active" : ""} htmlFor={name}>
            {label ? label : pattern.label}
          </label>
          <textarea
            disabled={disabled}
            id={name}
            name={name}
            ref={register({
              required,
              pattern: {
                value: !validationRegex ? pattern.regex : validationRegex,
                message: !validationMessage
                  ? pattern.message
                  : validationMessage
              }
            })}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={defaultValue}
          />

          {error && <span className="FormErrorMessage">{error.message}</span>}
        </div>
      );
    default:
      return (
        <div className={`FormInput ${error && " FormError"}`}>
          <label className={typing ? "active" : ""} htmlFor={name}>
            {label ? label : pattern.label}
          </label>
          <input
            disabled={disabled}
            id={name}
            name={name}
            ref={register({
              required,
              pattern: {
                value: !validationRegex ? pattern.regex : validationRegex,
                message: !validationMessage
                  ? pattern.message
                  : validationMessage
              }
            })}
            onFocus={onFocus}
            onBlur={onBlur}
            defaultValue={defaultValue}
            type={type}
          />

          {error && <span className="FormErrorMessage">{error.message}</span>}
        </div>
      );
  }
};

export default FormInput;
