import React, { useState, useEffect } from "react";
import CategoryWrapper from "../CategoryWrapper";
import TitleRemittanceInput from "../TitleRemittanceInput";
import DuplicateClaims from "../DuplicateClaims";
import { FormInput, SearchSelect } from "@fastlane-llc/lossexpress-ui-kit";
import useDebounce from "../../../hooks/use-debounce";
import { formatDateGMT } from "../../../services/dates";
import { getLenders } from "../../../services/lenders";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import "./ClaimInformation.css";
import {
  faExclamationCircle,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

const formatCreateLabel = option => {
  return <span>{option}</span>;
};

const lossOptions = [
  {
    value: "Single-Vehicle Collision",
    label: "Single-Vehicle Collision"
  },
  {
    value: "Multi-Vehicle Collision",
    label: "Multi-Vehicle Collision"
  },
  { value: "Wind/Hail", label: "Wind/Hail" },
  { value: "Fire", label: "Fire" },
  { value: "Flood", label: "Flood" },
  { value: "Vandalism", label: "Vandalism" },
  { value: "Theft", label: "Theft" },
  { value: "Other", label: "Other" }
];

const customerAuthorizationRequiredLenderNotification =
  "Please be aware: This lender typically requires customer authorization requests to release payoff information.";

const ClaimInformation = ({
  register,
  carrier = {},
  packet = {},
  errors = {},
  watch = () => {},
  setValue = () => {},
  unregister = () => {},
  triggerValidation = () => {},
  control,
  onLenderSelect = () => {},
  isVehicleLeased,
  setError,
  clearError
}) => {
  const [lender, setLender] = useState({});
  const [input, setInput] = useState(null);
  const [verifiedAlias, setVerifiedAlias] = useState(null);
  const [isNewLender, setIsNewLender] = useState(false);

  const lenderName = watch("claimInfo.lenderName");
  const watchedClaimInfo = watch("claimInfo");
  const claimNumber = watch("claimInfo.claimNumber");
  const ownerRetainedField = watch("claimInfo.ownerRetained");
  const insurerTypeField = watch("claimInfo.insurerType");
  const vin = watch("claimInfo.vin");

  const encodedClaimNumber = encodeURIComponent(claimNumber);
  const debouncedClaimNumber = useDebounce(encodedClaimNumber, 300);

  const claimErrors = errors?.claimInfo;

  const onCheckboxChange = (name, bool) => {
    setValue(name, bool);
  };

  useEffect(() => {
    if (carrier && !carrier.onlyAutomatedLenders) {
      register({ name: "claimInfo.dateOfLoss" }, { required: true });
      register({ name: "claimInfo.causeOfLoss" }, { required: true });
    }
  }, [carrier, register]);

  useEffect(() => {
    if (carrier && carrier.onlyAutomatedLenders) {
      unregister("claimInfo.dateOfLoss");
      unregister("claimInfo.causeOfLoss");
      unregister("claimInfo.insurerType");
      unregister("claimInfo.ownerRetained");
      unregister("claimInfo.claimNumber");
    }
  }, [carrier, unregister]);

  useEffect(() => {
    if (packet && packet.dateOfLoss) {
      setValue(
        "claimInfo.dateOfLoss",
        new Date(formatDateGMT(packet.dateOfLoss))
      );
    }
    if (packet && packet.causeOfLoss) {
      setValue("claimInfo.causeOfLoss", packet.causeOfLoss);
    }
  }, [packet, setValue]);

  const dateOfLoss = watch("claimInfo.dateOfLoss");

  //lender select
  useEffect(() => {
    register({ name: "claimInfo.lenderName" }, { required: true });
  }, [register]);

  useEffect(() => {
    onLenderSelect(lender);
  }, [lender, onLenderSelect]);

  useEffect(() => {
    if (lenderName === "" || lenderName == null) {
      setLender({ lenderName: "", lenderId: null });
      setVerifiedAlias(null);
    }
  }, [lenderName]);

  useEffect(() => {
    if (packet && packet.lender) {
      setLender(packet.lender);
      setValue("claimInfo.lenderName", packet.lender.lenderName);
    } else if (packet && packet.lenderName) {
      setLender({ lenderName: packet.lenderName, lenderId: null });
      setValue("claimInfo.lenderName", packet.lenderName);
    }
  }, [packet, setValue]);

  const onSelectChange = name => async obj => {
    if (obj) {
      await setValue(name, obj.label);

      if (obj.__isNew__) {
        const ls = await getLenders(obj.value);
        setLender({ lenderName: obj.value, lenderId: null });
        setVerifiedAlias(null);
        setIsNewLender(true);
        for (const l of ls) {
          if (l.lenderName.toLowerCase() === obj.value.toLowerCase()) {
            setLender(l);
          } else if (
            l.aliases &&
            l.aliases
              .map(a => a.toLowerCase())
              .indexOf(obj.value.toLowerCase()) > -1
          ) {
            setLender(l);
            setVerifiedAlias(obj.value);
          }
        }
      } else {
        setIsNewLender(false);
        if (obj.raw.aliases) {
          if (
            obj.raw.aliases
              .map(a => a.toLowerCase())
              .indexOf(input.toLowerCase()) > -1
          ) {
            setVerifiedAlias(input);
          } else {
            setVerifiedAlias(null);
          }
        }

        setLender(obj.raw);
      }
    } else {
      await setValue(name, null);
      setIsNewLender(false);
      setLender({ lenderName: "", lenderId: null });
      setVerifiedAlias(null);
    }
  };

  const searchLenders = async input => {
    const ls = await getLenders(input?.trim());
    setInput(input);

    return ls.map(l => ({
      value: l.lenderId,
      label:
        l.aliasRank > l.rank ? (
          <>
            <FontAwesomeIcon
              data-tooltip-id={"lender-alias-tt"}
              data-tooltip-content={`Your search matched ${l.alias}, and alias of ${l.lenderName}`}
              icon={faExclamationCircle}
            />{" "}
            {l.lenderName}{" "}
          </>
        ) : (
          l.lenderName
        ),
      automated: l.immediatePayoffAvailable,
      raw: l
    }));
  };

  const selectCauseOfLoss = () => (val, { action }) => {
    if (action === "select-option" || action === "set-value") {
      setValue("claimInfo.causeOfLoss", val.value);
    } else if (action === "clear") {
      setValue("claimInfo.causeOfLoss", null);
    }
  };

  return (
    <div>
      <CategoryWrapper
        name="Claim Information"
        watch={watchedClaimInfo}
        triggerValidation={triggerValidation}
        category="claimInfo"
      >
        {!carrier.onlyAutomatedLenders &&
          lender?.isCustomerAuthorizationRequired && (
            <div className="LenderSelectNotification">
              {customerAuthorizationRequiredLenderNotification}
            </div>
          )}
        <span
          translate="no"
          onPaste={e => {
            e.stopPropagation();
            e.preventDefault();
            setError(
              "claimInfo.lenderName",
              "noPaste",
              "Pasting into this field is not allowed!"
            );
          }}
        >
          {isNewLender && (
            <div className={"newLenderWarning"}>
              <FontAwesomeIcon icon={faExclamationTriangle}></FontAwesomeIcon>{" "}
              Adding a new lender will delay processing of your claim until
              lender information is verified.
            </div>
          )}
          <SearchSelect
            placeholder="Lender Name"
            name="claimInfo.lenderName"
            defaultValue={
              packet && packet.lenderName
                ? { label: packet.lenderName, value: packet.lenderName }
                : null
            }
            error={claimErrors?.lenderName}
            onSearch={() => searchLenders}
            onSelect={onSelectChange}
            creatable={true}
            clearable={true}
            options={null}
            // cacheOptions
            formatCreateLabel={formatCreateLabel}
            showAutomatedSymbol={true}
            reactSelectProps={{
              tabSelectsValue: false,
              openMenuOnFocus: false,
              openMenuOnClick: false,
              noOptionsMessage: () => null,
              onKeyDown: () => {
                if (claimErrors?.lenderName?.type === "noPaste") {
                  clearError("claimInfo.lenderName");
                }
              }
            }}
            testId={"LenderName"}
          />
          {claimErrors?.lenderName &&
            claimErrors?.lenderName.type === "noPaste" && (
              <div style={{ position: "relative", height: "1.5rem" }}>
                <span
                  style={{
                    fontFamily: "Gilroy-ExtraBold",
                    color: "#c23030",
                    fontSize: ".9em",
                    display: "inline-block",
                    position: "absolute",
                    top: "-7px",
                    left: "7px"
                  }}
                  data-testid="lenderName_errorMessage"
                >
                  {claimErrors?.lenderName.message}
                </span>
              </div>
            )}
        </span>
        {verifiedAlias && (
          <div className="VerifiedLenderAlias">
            <FontAwesomeIcon icon={faCheckCircle} style={styles.checkIcon} />
            {verifiedAlias} is a verified alias of {lender.lenderName}.
          </div>
        )}
        {!carrier.onlyAutomatedLenders && (
          <div>
            <FormInput
              placeholder="Claim Number"
              name="claimInfo.claimNumber"
              register={register}
              error={claimErrors?.claimNumber}
              validationRegex={new RegExp(carrier.claimNumberValidationRegex)}
              validationMessage={carrier.claimNumberValidationError}
              defaultValue={
                packet && packet.claimNumber ? packet.claimNumber : null
              }
              required
            />
            <DuplicateClaims
              debouncedClaimNumber={debouncedClaimNumber}
              claimNumber={claimNumber}
              packet={packet}
            />
          </div>
        )}
        {carrier.onlyAutomatedLenders && (
          <div>
            <FormInput
              error={claimErrors?.vin}
              name="claimInfo.vin"
              placeholder={"VIN"}
              register={register}
              unregister={unregister}
              defaultValue={packet && packet.vin ? packet.vin : ""}
              required
            />

            <FormInput
              name="claimInfo.accountNumber"
              placeholder={
                carrier?.ssnAllowed
                  ? "Account Number (or SSN)"
                  : "Account Number"
              }
              type="text"
              defaultValue={
                packet?.accountNumber
                  ? carrier?.ssnAllowed
                    ? "Account/SSN (HIDDEN)"
                    : "Account Number (HIDDEN)"
                  : ""
              }
              register={register}
              unregister={unregister}
              error={claimErrors?.accountNumber}
              required={!(vin !== "" && vin !== null)}
            />
            {lender?.ssnRequired && (
              <FormInput
                name="claimInfo.socialSecurityNumber"
                placeholder="Last Four (SSN)"
                type="text"
                defaultValue={
                  packet?.socialSecurityNumber
                    ? packet.socialSecurityNumber
                    : ""
                }
                register={register}
                unregister={unregister}
                error={claimErrors?.socialSecurityNumber}
                required
                inputProps={{ maxlength: 4 }}
              />
            )}
          </div>
        )}

        {carrier?.ssnAllowed && lender?.fullSocialSecurityNumberRequired && (
          <div data-testid="FullSsnInputForm">
            <FormInput
              name="claimInfo.fullSocialSecurityNumber"
              placeholder="Full Social Security Number"
              type="text"
              defaultValue={
                packet?.fullSocialSecurityNumber
                  ? packet.fullSocialSecurityNumber
                  : ""
              }
              register={register}
              unregister={unregister}
              error={claimErrors?.socialSecurityNumber}
              required
            />
          </div>
        )}

        {!carrier.onlyAutomatedLenders && (
          <div>
            <SearchSelect
              placeholder="Cause of Loss"
              name="claimInfo.causeOfLoss"
              onSelect={selectCauseOfLoss}
              options={lossOptions}
              defaultValue={
                packet?.causeOfLoss
                  ? { label: packet.causeOfLoss, value: packet.causeOfLoss }
                  : null
              }
              error={claimErrors?.causeOfLoss}
              testId={"SelectCauseOfLoss"}
            />

            <FormInput
              type="date"
              placeholder="Date of Loss"
              name="claimInfo.dateOfLoss"
              register={register}
              onChange={setValue}
              error={claimErrors?.dateOfLoss}
              defaultValue={
                packet && packet.dateOfLoss
                  ? new Date(formatDateGMT(packet.dateOfLoss))
                  : null
              }
              value={dateOfLoss}
              disabledDates={["future"]}
              required
            />
            <div data-testid="SettlementAmount">
              <FormInput
                placeholder={
                  isVehicleLeased ? "ACV" : "Settlement Amount (Max Payout)"
                }
                name="claimInfo.settlementAmount"
                error={claimErrors?.settlementAmount}
                required
                format="currency"
                defaultValue={
                  packet && packet.settlementAmount
                    ? packet.settlementAmount
                    : ""
                }
                control={control}
                useFormatPattern={false}
              />
            </div>
            <div data-testid="Deductible">
              <FormInput
                placeholder="Deductible"
                name="claimInfo.deductible"
                error={claimErrors?.deductible}
                required
                format="currency"
                defaultValue={
                  packet && packet.deductible ? packet.deductible : ""
                }
                control={control}
                useFormatPattern={false}
              />
            </div>
            <TitleRemittanceInput
              carrier={carrier}
              register={register}
              unregister={unregister}
              setValue={setValue}
              watch={watch}
              errors={errors}
              packet={packet}
              claimErrors={claimErrors}
            />

            <FormInput
              label="Third-party claim"
              type="checkbox"
              name="claimInfo.insurerType"
              register={register}
              error={claimErrors?.insurerType}
              onChange={onCheckboxChange}
              value={insurerTypeField}
              defaultValue={packet?.insurerType === "Third Party"}
            />

            <FormInput
              label="Owner interested in retaining"
              type="checkbox"
              name="claimInfo.ownerRetained"
              register={register}
              error={claimErrors?.ownerRetained}
              onChange={onCheckboxChange}
              value={ownerRetainedField}
              defaultValue={
                packet && packet.ownerRetained ? packet.ownerRetained : false
              }
            />
          </div>
        )}
      </CategoryWrapper>
    </div>
  );
};

const styles = {
  checkIcon: {
    color: "#008CD5",
    marginRight: ".25em"
  }
};

export default ClaimInformation;
