import React, { useState } from "react";
import Modal from "../Modal";
import "./NoticeBanner.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCalendar,
  faPhoneSquareAlt,
  faExclamationTriangle
} from "@fortawesome/free-solid-svg-icons";

const NoticeBanner = ({ notice = {}, toggleNotice = () => {} }) => {
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  const handleShowDescriptionModal = () => {
    setShowDescriptionModal(true);
  };

  const handleCloseDescriptionModal = () => {
    setShowDescriptionModal(false);
  };

  return (
    <div
      className="BannerContainer"
      style={showDescriptionModal ? { zIndex: "10000" } : { zIndex: "9500" }}
    >
      <div className={`NoticeBanner ${notice.type}`}>
        <div className="NoticeDescription">
          {notice.type === "Outage" && (
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="NoticeIcon"
            />
          )}
          {notice.type === "Downtime" && (
            <FontAwesomeIcon icon={faPhoneSquareAlt} className="NoticeIcon" />
          )}
          {notice.type === "Holiday" && (
            <FontAwesomeIcon icon={faCalendar} className="NoticeIcon" />
          )}{" "}
          <span className="NoticeBold">NOTICE: </span>
          {notice.shortDescription}
        </div>
        <div className="NoticeInfo">
          <div onClick={handleShowDescriptionModal}>
            (click to view more information)
          </div>
        </div>
        <div className="CloseNotice">
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={() => toggleNotice(notice.noticeId)}
            />
          </div>
        </div>
        {showDescriptionModal && (
          <Modal onClose={handleCloseDescriptionModal}>
            <div className="NoticeModal">
              <span className="NoticeBold">Notice: </span>
              {notice.shortDescription}
              <br />
              <br />
              <span className="NoticeBold">Additional Information</span>
              <br />
              <br />
              {notice.description}
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default NoticeBanner;
