import React, { useState, useEffect } from "react";
import {
  Button,
  FormInput,
  SearchSelect
} from "@fastlane-llc/lossexpress-ui-kit";

import { useForm } from "react-hook-form";

import { fetchCurrentUsersList } from "../../services/users";

import Modal from "../Modal";
import "./ReassignModal.css";

const ReassignModal = ({
  packet,
  close = () => {},
  reassignPacket = () => {}
}) => {
  const [users, setUsers] = useState([]);

  const { register, handleSubmit, setValue, watch } = useForm({
    mode: "onBlur"
  });

  const { stopNotifications } = watch();

  useEffect(() => {
    register("user");
  }, [register]);

  useEffect(() => {
    returnFetch();
    setValue("stopNotifications", true);
  }, [setUsers, setValue]);

  const returnFetch = async () => {
    const u = await fetchCurrentUsersList();
    setUsers(u);
  };

  const newAssignment = async data => {
    const userToAssignTo = users.find(user => user.userId === data.user);
    await reassignPacket(userToAssignTo, stopNotifications);
  };

  const onSelectChange = () => obj => {
    setValue("user", obj.value);
  };

  return (
    <Modal onClose={close} title="Re-assign Packet">
      <p>
        This packet is currently managed by:{" "}
        <span className="ManagedByName">{packet.name}</span>. Please select a
        user from the list below to reassign the packet to.
      </p>
      <form onSubmit={handleSubmit(newAssignment)}>
        {users.length > 0 && (
          <SearchSelect
            label="User to Assign"
            name="user"
            defaultValue={{ label: packet.name, value: packet.userId }}
            options={users.map(user => ({
              value: user.userId,
              label: user.name
            }))}
            onSelect={onSelectChange}
            testId="ReassignDropdown"
          />
        )}

        <FormInput
          type="checkbox"
          name="stopNotifications"
          value={stopNotifications}
          onChange={setValue}
          register={register}
          label={`Opt ${packet.name} out of notifications as well`}
        />

        <div className="ModalBottomButtonsLeft">
          <Button>Reassign Packet</Button>
          <Button color="red" invert onClick={close}>
            Cancel
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default ReassignModal;
