import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";

import { resetPassword } from "../../services/auth-request";
import { useParams } from "react-router-dom";

import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import XLienSideWrapper from "../XLienSideWrapper";
import Footer from "../Footer/Footer";

import "./PasswordReset.css";

const PasswordReset = () => {
  const [redirectToIndex, setRedirectToIndex] = useState(false);
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(true);
  const { resetPasswordId } = useParams();

  const { handleSubmit, register, watch, errors } = useForm({
    mode: "onBlur"
  });

  const { newPassword, confirmPassword } = watch();

  useEffect(() => {
    if (newPassword === confirmPassword) {
      setDisabled(false);
      setMessage("");
    } else {
      setDisabled(true);
      setMessage("Passwords must match.");
    }
  }, [newPassword, confirmPassword]);

  const submitPasswordChange = async data => {
    try {
      setDisabled(true);
      await resetPassword(resetPasswordId, data.newPassword);
      setMessage("Password reset successful! Please login to continue.");
      setRedirectToIndex(true);
    } catch (ex) {
      setMessage(
        `Reset failed, please do not use the same password you were using previously. If you feel this is in error please contact technical support. Error Message: ${ex.message}`
      );
    }
  };

  return (
    <XLienSideWrapper>
      {redirectToIndex && <Redirect to={"/"} />}
      <div className="LoginTitle">Reset your Password</div>
      <form
        onSubmit={handleSubmit(submitPasswordChange)}
        data-testid="PasswordResetForm"
      >
        <FormInput
          type="password"
          name="newPassword"
          placeholder="New Password"
          register={register}
          error={errors.newPassword}
          required
        />
        <FormInput
          type="password"
          name="confirmPassword"
          placeholder="Confirm New Password"
          register={register}
          error={errors.confirmPassword}
          required
        />
        {message ? <div className="MatchMessage">{message}</div> : ""}
        <Button disabled={disabled}>Reset Password</Button>
      </form>

      <Footer />
    </XLienSideWrapper>
  );
};

export default PasswordReset;
