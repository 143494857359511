import React from "react";
import "./Error.css";

const Error = ({ children }) => {
  return (
    <div>
      <p className="ErrorText">{children}</p>
    </div>
  );
};

export default Error;
