import { useState, useEffect } from "react";
import { getVendorCompanies } from "../services/vendors";

const useRequestingCompanyInfo = id => {
  const [requestingCompany, setRequestingCompany] = useState(null);

  useEffect(() => {
    let isCancelled = false;

    getVendorCompanies().then(companies => {
      if (isCancelled) return;

      const company = companies.find(r => {
        return r.carrierId === id;
      });

      setRequestingCompany(company);
    });

    return () => {
      isCancelled = true;
    };
  }, [id]);

  return requestingCompany;
};

export default useRequestingCompanyInfo;
