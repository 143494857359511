import React, { useEffect, useState } from "react";

import "./PayoffPortal.css";
import PayoffInputForm from "./PayoffInputForm";
import BasicClaimView from "./BasicClaimView";
import { Redirect } from "react-router-dom";
import { getCarrierLogoImage, resendOTPEmail } from "../../services/otp";
import { Button } from "@fastlane-llc/lossexpress-ui-kit";

const PayoffPortal = ({ packet = null }) => {
  const [isValid, setIsValid] = useState(true);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  useEffect(() => {
    if (!packet) {
      setIsValid(false);
    }
  }, [packet]);

  const handleResendEmail = async () => {
    setButtonDisabled(true);
    await resendOTPEmail(packet.packetId)
      .then(() => {
        setEmailSent(true);
      })
      .catch(() => {
        setEmailSent(true);
        setButtonDisabled(false);
      });
  };

  return (
    <>
      {!isValid && <Redirect to={"/"} />}
      {!isCompleted && (
        <div className={"PayoffInputWrapper"}>
          <div className={"PayoffInputForm"}>
            <h1 className={"PayoffInputFormHeader"}>Payoff Information</h1>
            <p className={"PayoffFormInstructions"}>
              Please fill out the fields and the click 'Submit' when complete.
            </p>
            <PayoffInputForm
              packet={packet}
              setIsCompleted={setIsCompleted}
              setIsError={setIsError}
            />
          </div>
          <div className={"VertBar"} />
          <div className={"ClaimInformation"}>
            <BasicClaimView packet={packet} />
          </div>
        </div>
      )}{" "}
      {isCompleted && !isError && <h1>Thank you!</h1>}
      {isError && !emailSent && (
        <>
          <p>
            Your link has expired, you can request a new e-mail by clicking{" "}
            <i>Resend Email</i> below
          </p>
          <Button onClick={handleResendEmail} disabled={buttonDisabled}>
            Resend Email
          </Button>
        </>
      )}
      {isError && emailSent && (
        <>
          <h1>Thank you</h1>
          <p>
            You should receive an email shortly. You can safely exit this page.
          </p>
        </>
      )}
      <img
        className={"CarrierLogo"}
        src={getCarrierLogoImage(packet?.carrierId)}
        alt="Carrier"
      />
    </>
  );
};

export default PayoffPortal;
