import React, { useEffect, useState } from "react";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";

import Layout from "../Layout";

import {
  fetchUsers as fetchUsersAPI,
  deactivateUser as deactivateUserAPI,
  editUser as editUserAPI
} from "../../services/users";
import { fetchVendorSSOSettings } from "../../services/local-storage";
import Error from "../Error";

import "./ViewUsers.css";
import AddUserModal from "./components/AddUserModal";
import AddBatchUserModal from "./components/AddBatchUserModal";
import Success from "../Success";
import { format } from "date-fns";

import { useForm } from "react-hook-form";

import EditEmailForm from "./components/EditEmailForm";

function ViewUsers() {
  const [activeUsers, setActiveUsers] = useState([]);
  const [activeAdministrators, setActiveAdministrators] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [batchModalOpen, setBatchModalOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [updating, setUpdating] = useState(false);
  const [userProvisioningActive, setUserProvisioningActive] = useState(false);

  const { errors, register, deregister, setValue, handleSubmit } = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    const users = await fetchUsersAPI();
    setActiveUsers(
      users.filter(user => user.currentlyActive && user.userRole === "User")
    );
    setActiveAdministrators(
      users.filter(
        user =>
          user.currentlyActive &&
          (user.userRole === "Administrator" ||
            user.userRole === "System Administrator")
      )
    );

    const ssoSettings = fetchVendorSSOSettings();
    setUserProvisioningActive(ssoSettings.userProvisioning);
  };

  const deactivateUser = user => async () => {
    await deactivateUserAPI(user);
    fetchUsers();
  };

  const closeModal = async () => {
    fetchUsers();
    setModalOpen(false);
    setBatchModalOpen(false);
  };

  const openModal = () => setModalOpen(true);
  const openBatchModal = () => setBatchModalOpen(true);

  const submitEditUser = userId => async data => {
    setUpdating(true);
    const userRole = data[userId].userRole;
    try {
      setSuccess("");
      setError("");
      await editUserAPI(userId, { userRole });
      await fetchUsers();
      setSuccess("Successfully updated user.");
    } catch (ex) {
      setError("User update failed.");
    }
    setUpdating(false);
  };

  return (
    <Layout>
      {modalOpen && (
        <AddUserModal
          onClose={closeModal}
          setError={setError}
          setSuccess={setSuccess}
        />
      )}
      {batchModalOpen && (
        <AddBatchUserModal
          onClose={closeModal}
          setError={setError}
          setSuccess={setSuccess}
        />
      )}

      <div className="ViewUsersWrapper">
        <h1>User Management</h1>

        {!userProvisioningActive && (
          <div className="ViewUsersAddButtons">
            <Button onClick={openModal}>+ Add New User</Button>{" "}
            <Button onClick={openBatchModal}>+ Add Multiple Users</Button>
          </div>
        )}

        {error !== "" && <Error>{error}</Error>}

        {success !== "" && <Success>{success}</Success>}

        <h2 className="PacketsIndexH2">Active Administrators</h2>
        <table cellSpacing="0" className="ViewUsersTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Email Address</th>
              <th>Role</th>
              <th>Account Status</th>
              <th>Last Logged In</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {activeAdministrators.map(u => (
              <tr key={u.userId}>
                <td>{u.name}</td>
                <td>{u.username}</td>
                <td>
                  {u.emailAddress}
                  <br />
                  <EditEmailForm user={u} fetchUsers={fetchUsers} />
                </td>
                <td>
                  <form
                    className="UserForm"
                    onSubmit={handleSubmit(submitEditUser(u.userId))}
                  >
                    <FormInput
                      label="User Role"
                      name={`${u.userId}.userRole`}
                      type="select"
                      defaultValue={u.userRole}
                      register={register}
                      deregister={deregister}
                      options={["Administrator", "User"]}
                      errors={errors.userRole}
                      onChange={setValue}
                    />
                    <Button size="tiny" disabled={updating}>
                      Update
                    </Button>
                  </form>
                </td>
                {u.lastPasswordChange ? (
                  <td>
                    <i className="fa fa-check" /> Active
                  </td>
                ) : (
                  <td>Pending</td>
                )}
                <td>
                  {u.lastLoggedIn ? format(u.lastLoggedIn, "M/DD/YY") : "N/A"}
                </td>
                <td>
                  {!userProvisioningActive && (
                    <Button
                      onClick={deactivateUser(u)}
                      color="red"
                      invert
                      size="tiny"
                    >
                      Deactivate
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <h2 className="PacketsIndexH2">Active Users</h2>
        <table cellSpacing="0" className="ViewUsersTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Email Address</th>
              <th>Role</th>
              <th>Account Status</th>
              <th>Last Logged In</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {activeUsers.map(u => (
              <tr key={u.userId}>
                <td>{u.name}</td>
                <td>{u.username}</td>
                <td>
                  {u.emailAddress}
                  <br />
                  <EditEmailForm user={u} fetchUsers={fetchUsers} />
                </td>
                <td>
                  <form
                    className="UserForm"
                    onSubmit={handleSubmit(submitEditUser(u.userId))}
                  >
                    <FormInput
                      label="User Role"
                      name={`${u.userId}.userRole`}
                      type="select"
                      defaultValue={u.userRole}
                      register={register}
                      deregister={deregister}
                      options={["Administrator", "User"]}
                      errors={errors.userRole}
                      onChange={setValue}
                    />
                    <Button size="tiny" disabled={updating}>
                      Update
                    </Button>
                  </form>
                </td>
                {u.lastPasswordChange ? (
                  <td>
                    <i className="fa fa-check" /> Active
                  </td>
                ) : (
                  <td>Pending</td>
                )}
                <td>
                  {u.lastLoggedIn ? format(u.lastLoggedIn, "MM/DD/YY") : "N/A"}
                </td>
                <td>
                  {!userProvisioningActive && (
                    <Button
                      onClick={deactivateUser(u)}
                      color="red"
                      invert
                      size="tiny"
                    >
                      Deactivate
                    </Button>
                  )}
                  &nbsp;&nbsp;
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  );
}

export default ViewUsers;
