import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import download from "downloadjs";

import "./DataAndReportingPage.css";

import { downloadData } from "../../services/users";
import Layout from "../Layout";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";

import MetabaseDashboard from "../MetabaseDashboard";
import Typography from "../Typography";
import {
  useGenericMetabaseReportUrl,
  useUserAccountInfo,
  useRequestingCompanyInfo,
  useStateFarmMetabaseReportUrl
} from "../../hooks";

const DataAndReportingPage = () => {
  const [downloading, setDownloading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [genericReportUrl, isGenericReportOk] = useGenericMetabaseReportUrl();
  const [
    stateFarmReportUrl,
    isStateFarmReportOk
  ] = useStateFarmMetabaseReportUrl();

  const [user] = useUserAccountInfo();

  const requestingCompany = useRequestingCompanyInfo(user?.requestingCompanyId);

  const dataDownload = useForm({ mode: "onBlur" });

  const dd = dataDownload.watch();

  useEffect(() => {
    let hasSelection = false;
    const values = Object.values(dd);
    if (values.includes(true)) hasSelection = true;
    if (dd?.dates?.from && dd?.dates?.to && hasSelection) {
      setDisabled(false);
    }
    if (!hasSelection || !dd?.dates?.from || !dd?.dates?.to) {
      setDisabled(true);
    }
  }, [dd]);

  const onDownloadSubmit = async data => {
    setDownloading(true);
    const blob = await downloadData(data);
    download(blob, "LossExpress Data Download", "application/zip");
    setDownloading(false);
  };

  const isStateFarm = user?.carrierDisplayName === "State Farm";
  const isSystemAdmin = user?.userRole === "System Administrator";

  if (
    user?.userRole !== "Administrator" &&
    user?.userRole !== "System Administrator"
  ) {
    return (
      <Layout>
        <p>Permission denied. Only accessible to administrators.</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <Typography variant="h1">Data and Reporting</Typography>

      <div className="data-and-reporting__contact__container">
        <p>
          Need billable claim information or specialized reports outside of
          usual reporting cadences?{" "}
          <span className="data-and-reporting__contact">
            Please contact{" "}
            <a href="mailto:success@lossexpress.com">success@lossexpress.com</a>
            .
          </span>
        </p>
      </div>

      {(isStateFarm || isSystemAdmin) && stateFarmReportUrl && (
        <MetabaseDashboard
          className="data-and-reporting__dashboard__container state-farm__container"
          url={stateFarmReportUrl}
          isMetabaseOk={isStateFarmReportOk}
          title="State Farm Report"
          iframeStyle={{
            border: "none"
          }}
          errorStyle={{
            marginRight: "9em"
          }}
        />
      )}

      <Typography variant="h2">
        Claim Snapshot for {requestingCompany?.name}
      </Typography>
      <MetabaseDashboard
        className="data-and-reporting__dashboard__container"
        url={genericReportUrl}
        isMetabaseOk={isGenericReportOk}
        title="Snapshot Report"
        iframeStyle={{
          border: "none"
        }}
        errorStyle={{
          marginRight: "9em"
        }}
      />

      <div className="data-and-reporting__data-downloads__container">
        <Typography variant="h2">Data Downloads</Typography>
        <p>
          As an administrator, you can download data sets (in CSV format)
          related to your company's usage of LossExpress.
        </p>

        <form onSubmit={dataDownload.handleSubmit(onDownloadSubmit)}>
          <FormInput
            name="dates"
            type="date"
            ranged
            label="Include Dates"
            onChange={dataDownload.setValue}
            register={dataDownload.register}
            disabledDates={["future"]}
          />
          {(!isStateFarm || isSystemAdmin) && (
            <>
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includeDirectMessages"
                label="Direct Messages"
                value={dd.includeDirectMessages}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includeEventLogs"
                label="Event Logs"
                value={dd.includeEventLogs}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includeLogins"
                label="Logins"
                value={dd.includeLogins}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includeLoGRequests"
                label="Letter of Guarantee Requests"
                value={dd.includeLoGRequests}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includePackets"
                label="Claims (referenced as Packets in the data)"
                value={dd.includePackets}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includePayoffData"
                label="Payoff Data"
                value={dd.includePayoffData}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includeUsers"
                label="Users"
                value={dd.includeUsers}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includeDedupedStatuses"
                label="Deduped Claim Statuses"
                value={dd.includeDedupedStatuses}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includeSpecializedReport"
                label="Deduped Claims (Additional Info)"
                value={dd.includeSpecializedReport}
              />
            </>
          )}
          {(isStateFarm || isSystemAdmin) && (
            <div>
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includePayoffRequestReport"
                label="Payoff Request Report"
                value={dd.includePayoffRequestReport}
              />
              <FormInput
                type="checkbox"
                register={dataDownload.register}
                onChange={dataDownload.setValue}
                name="includePayoffRequestSummary"
                label="Payoff Request Summary"
                value={dd.includePayoffRequestSummary}
              />
            </div>
          )}

          <Button Button disabled={downloading || disabled}>
            {downloading ? "Downloading..." : "Download Data"}
          </Button>
        </form>
      </div>
    </Layout>
  );
};

export default DataAndReportingPage;
