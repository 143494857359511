import { flFetch as fetch, validateStatus } from "./fetch";

export const fetchUnseenFulfilledPayoffQuotes = () => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/unseen-fulfilled-quotes`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const setPayoffQuoteAsSeen = packetId => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/packets/${packetId}/mark-as-seen`,
    {
      method: "PUT"
    }
  ).then(validateStatus);
};
