let storage = {};

const createOverride = () => {
  const p = "__override__";
  Storage.prototype.setItem = (k, v) => {
    storage[p + k] = v;
  };

  Storage.prototype.getItem = k => {
    return storage[p + k] === undefined ? null : storage[p + k];
  };

  Storage.prototype.removeItem = k => {
    delete storage[p + k];
  };

  Storage.prototype.clear = () => {
    storage = {};
  };
};

export const persistItemToLS = (key, val) => {
  try {
    localStorage.setItem(`fl-carrier-${key}`, val);
  } catch (ex) {
    createOverride();
    persistItemToLS(key, val);
  }
};

export const fetchVendorItemFromLS = key => {
  try {
    let val = localStorage.getItem(`fl-vendor-${key}`);

    return val;
  } catch (ex) {
    createOverride();
    return fetchCarrierItemFromLS(key);
  }
};

export const fetchCarrierItemFromLS = key => {
  try {
    let val = localStorage.getItem(`fl-carrier-${key}`);

    return val;
  } catch (ex) {
    createOverride();
    return fetchCarrierItemFromLS(key);
  }
};

export const removeItemFromLS = key => {
  try {
    localStorage.removeItem(key);
  } catch (ex) {
    createOverride();
    return removeItemFromLS(key);
  }
};

export const removeCarrierUserFromLS = key => {
  try {
    localStorage.removeItem(`fl-carrier-${key}`);
  } catch (ex) {
    createOverride();
    return removeItemFromLS(key);
  }
};

export const parseLSForAuth = () => {
  try {
    let token = localStorage.getItem("fl-auth") || null;

    return token;
  } catch (ex) {
    createOverride();
    parseLSForAuth();

    return null;
  }
};

export const persistToLS = token => {
  try {
    localStorage.setItem("fl-auth", token);
  } catch (ex) {
    createOverride();
    persistToLS(token);
  }
};

export const persistRoleToLS = scope => {
  try {
    localStorage.setItem("fl-scope", scope);
  } catch (ex) {
    createOverride();
    persistRoleToLS(scope);
  }
};

export const removeFromLS = () => {
  try {
    localStorage.removeItem("fl-auth");
  } catch (ex) {
    createOverride();
    removeFromLS();
  }
};

export const saveVendorId = vendorId => {
  try {
    localStorage.setItem("fl-vendor-id", vendorId);
  } catch (ex) {
    createOverride();
    saveVendorId(vendorId);
  }
};

export const saveVendorSSOSettings = data => {
  try {
    localStorage.setItem("fl-sso-settings", JSON.stringify(data));
  } catch (ex) {
    createOverride();
    saveVendorSSOSettings(data);
  }
};

export const saveCarriers = carriers => {
  try {
    localStorage.setItem("fl-vendor-carriers", JSON.stringify(carriers));
  } catch (ex) {
    createOverride();
    saveCarriers(carriers);
  }
};

export const fetchCarriers = () => {
  try {
    const carriers = localStorage.getItem("fl-vendor-carriers") || null;

    if (carriers == null) {
      return null;
    }

    return JSON.parse(carriers);
  } catch (ex) {
    createOverride();
    return fetchCarriers();
  }
};

export const saveHiddenNotices = noticeIds => {
  try {
    localStorage.setItem("le-hidden-notices", JSON.stringify(noticeIds));
  } catch (ex) {
    createOverride();
    saveHiddenNotices(noticeIds);
  }
};

export const fetchHiddenNotices = () => {
  try {
    // always assume it's going to be an array, because it is!
    const notices = localStorage.getItem("le-hidden-notices") || "[]";

    return JSON.parse(notices);
  } catch (ex) {
    createOverride();
    return fetchHiddenNotices();
  }
};

export const removeNoticesFromLS = () => {
  try {
    localStorage.removeItem("le-hidden-notices");
  } catch (ex) {
    createOverride();
    return removeNoticesFromLS();
  }
};

export const fetchVendorId = () => {
  try {
    const vendorId = localStorage.getItem("fl-vendor-id") || null;

    return vendorId;
  } catch (ex) {
    createOverride();

    return fetchVendorId();
  }
};

export const fetchVendorSSOSettings = () => {
  try {
    const ssoSettings = localStorage.getItem("fl-sso-settings") || null;

    return JSON.parse(ssoSettings);
  } catch (ex) {
    createOverride();

    return fetchVendorSSOSettings();
  }
};

export const fetchUserRole = () => {
  try {
    const scope = localStorage.getItem("fl-scope") || null;

    return scope;
  } catch (ex) {
    createOverride();

    return fetchUserRole();
  }
};

export const storeCountriesAndStates = obj => {
  try {
    localStorage.setItem("le-countries-and-states", JSON.stringify(obj));
  } catch (ex) {
    createOverride();
    return storeCountriesAndStates();
  }
};

export const fetchCountriesAndStates = () => {
  try {
    let val = localStorage.getItem("le-countries-and-states") || null;

    if (val) {
      val = JSON.parse(val);
    }

    if (!val) {
      val = {};
    }

    return val;
  } catch (ex) {
    createOverride();
    return fetchCountriesAndStates();
  }
};
