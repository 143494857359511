import React, { useEffect, useRef } from "react";

import { Link } from "react-router-dom";

import "./UserMenu.css";

const UserMenu = ({
  open,
  signOut,
  closeMenu = () => {},
  currentUser = {}
}) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClick = e => {
      if (ref.current.contains(e.target)) {
        return;
      }

      closeMenu();
    };

    document.addEventListener("mousedown", handleClick, false);

    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [closeMenu]);

  if (open) {
    return (
      <div className="UserMenuWrapper" ref={ref}>
        {(currentUser.userRole === "Administrator" ||
          currentUser.userRole === "System Administrator") && (
          <>
            <h3 key="administration-header">Administration</h3>

            <Link to="/admin/users">Manage Users</Link>
            {/* locking this down to LE admins only for now */}
            {currentUser.userRole === "System Administrator" && (
              <Link
                to={{
                  pathname: "/admin/orders",
                  state: { user: currentUser }
                }}
              >
                Manage Orders
              </Link>
            )}
          </>
        )}

        <h3>Account Management</h3>
        <Link to="/account">Account Settings</Link>
        <button onClick={signOut}>Sign Out</button>
      </div>
    );
  }

  return null;
};

export default UserMenu;
