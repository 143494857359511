import React, { useState, useEffect } from "react";
import { FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import RemittanceAddressSelect from "./RemittanceAddressSelect";
import { getAccountInfo } from "../../../services/users";

const TitleRemittanceInput = ({
  carrier = {},
  register,
  setValue,
  watch,
  errors = {},
  packet = {},
  unregister = () => {},
  claimErrors
}) => {
  const [showRemitSelect, setShowRemitSelect] = useState(false);
  const [showTextField, setShowTextField] = useState(false);
  const [userRemitAddress, setUserRemitAddress] = useState("");

  useEffect(() => {
    let userRemittanceAddress;
    if (carrier.userRemittanceAddresses) {
      setShowTextField(true);
      (async function() {
        const user = await getAccountInfo();
        const {
          mailingStreetAddress,
          mailingStreetAddress2,
          mailingCity,
          mailingState,
          mailingZipCode,
          mailingAdditionalInfo
        } = user;

        if (!mailingStreetAddress) {
          userRemittanceAddress = "";
        } else {
          userRemittanceAddress =
            `${mailingStreetAddress} ` +
            `${mailingStreetAddress2 ? `${mailingStreetAddress2} ` : ""}` +
            `${mailingAdditionalInfo ? `${mailingAdditionalInfo} ` : ""}` +
            `${mailingCity}, ${mailingState} ${mailingZipCode}`;
        }

        setUserRemitAddress(userRemittanceAddress);
      })();
    }
  }, [carrier]);

  const hasMultipleRemitAddresses =
    carrier.onlyStoredRemittanceAddresses &&
    carrier.titleRemittanceAddresses &&
    carrier.titleRemittanceAddresses.length > 1;

  const hasOneRemitAddress =
    carrier.onlyStoredRemittanceAddresses &&
    carrier.titleRemittanceAddresses &&
    carrier.titleRemittanceAddresses.length === 1;

  const hasStoredAddress = carrier.onlyStoredRemittanceAddresses;

  useEffect(() => {
    if (hasMultipleRemitAddresses) {
      setShowRemitSelect(true);
      setShowTextField(false);
    }

    if (hasOneRemitAddress) {
      setShowRemitSelect(false);
      register(
        {
          name: "claimInfo.titleRemittanceAddressId"
        },
        { required: true }
      );
      setValue(
        "claimInfo.titleRemittanceAddressId",
        carrier.titleRemittanceAddresses[0].titleRemittanceAddressId
      );
    }

    if (hasStoredAddress === false) {
      setShowRemitSelect(false);
      setShowTextField(true);
    }

    return () => {
      if (hasOneRemitAddress) {
        unregister({ name: "claimInfo.titleRemittanceAddressId" });
      }
    };
  }, [
    carrier,
    register,
    setValue,
    unregister,
    hasMultipleRemitAddresses,
    hasOneRemitAddress,
    hasStoredAddress
  ]);

  return (
    <>
      {showRemitSelect && !showTextField && (
        <RemittanceAddressSelect
          carrier={carrier}
          register={register}
          unregister={unregister}
          setValue={setValue}
          errors={errors}
          claimErrors={claimErrors}
          packet={packet}
          watch={watch}
        />
      )}
      {showTextField && !showRemitSelect && (
        <FormInput
          type="text"
          placeholder="Title Remittance Address"
          name="claimInfo.titleRemittanceAddress"
          register={register}
          unregister={unregister}
          error={claimErrors?.titleRemittanceAddress}
          required
          defaultValue={
            packet && packet.titleRemittanceAddress
              ? packet.titleRemittanceAddress
              : carrier.userRemittanceAddresses
              ? userRemitAddress
              : ""
          }
        />
      )}
    </>
  );
};

export default TitleRemittanceInput;
