import { removeFromLS, removeItemFromLS, fetchVendorId } from "./local-storage";

const IS_SUCCESSFUL = status => status >= 200 && status < 300;
const IS_UNAUTHORIZED = status => status === 401 || status === 403;

export const validateStatus = resp => {
  const secondTry = resp.clone();

  if (IS_UNAUTHORIZED(resp.status)) {
    removeFromLS();
    removeItemFromLS("user");

    return resp.json().then(json => {
      throw new Error(json);
    });
  }

  if (!IS_SUCCESSFUL(resp.status)) {
    return resp.json().then(json => {
      throw new Error(json);
    });
  }

  return resp.json().catch(() => {
    return secondTry.text();
  });
};

export const validateBlobStatus = resp => {
  const secondTry = resp.clone();

  if (IS_UNAUTHORIZED(resp.status)) {
    removeFromLS();
    if (
      window.location.pathname !== "" &&
      window.location.pathname !== "validate-device" &&
      window.location.pathname !== "forgot-password" &&
      window.location.pathname !== "password-change-required"
    ) {
      window.location.href = "/";
    }

    return resp.json().then(json => {
      throw new Error(json);
    });
  }

  if (!IS_SUCCESSFUL(resp.status)) {
    return resp.json().then(json => {
      throw new Error(json);
    });
  }

  return resp.blob().catch(() => {
    return secondTry.text();
  });
};

export const flFetch = (...args) => {
  if (!args[1]) {
    args[1] = {};
  }

  const vendorId = fetchVendorId();

  args[1].headers = Object.assign({}, args[1].headers, {
    "x-payoff-vendor-id": vendorId,
    "Content-Type": "application/json"
  });

  args[1].credentials = "include";

  if (typeof args[1].body !== "string") {
    delete args[1].headers["Content-Type"];
  }

  return fetch(...args);
};

export default flFetch;
