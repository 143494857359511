import { flFetch as fetch, validateStatus } from "./fetch";

export const getLenders = input => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/info/lenders${
      input && input !== "" ? `?name=${encodeURIComponent(input)}` : ""
    }`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};

export const getSmartLenderSearch = query => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/info/lenders/smart-search?query=${query}`,
    {
      method: "GET"
    }
  ).then(validateStatus);
};
