import React from "react";
import { Logo } from "@fastlane-llc/lossexpress-ui-kit";
import "./XLienSideWrapper.css";

const XLienSideWrapper = ({ children }) => {
  return (
    <div className="XLienLoginContainer">
      <div className="XLienBackgroundImage">
        <div className="XLienLogo">
          <Logo type="xlien" />
        </div>
      </div>
      <div className="XLienChildrenWrapper">{children}</div>
    </div>
  );
};

export default XLienSideWrapper;
