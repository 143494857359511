import React, { useEffect, useState, useCallback } from "react";

import { SearchSelect, FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import { fetchCountriesAndStates } from "../../../../services/local-storage";

import "./RemittanceAddressSelect.css";

const RemittanceAddressSelect = ({
  carrier,
  register,
  setValue,
  watch,
  claimErrors,
  errors,
  packet = {},
  unregister
}) => {
  const [remittanceOptions, setRemittanceOptions] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);

  const stateField = watch("claimInfo.newTitleRemittanceAddress.stateId");

  useEffect(() => {
    if (!showNewAddress) {
      unregister("claimInfo.newTitleRemittanceAddress.streetAddress");
      unregister("claimInfo.newTitleRemittanceAddress.streetAddress2");
      unregister("claimInfo.newTitleRemittanceAddress.city");
      unregister("claimInfo.newTitleRemittanceAddress.stateId");
      unregister("claimInfo.newTitleRemittanceAddress.zipCode");
      unregister("claimInfo.newTitleRemittanceAddress");
    } else {
      unregister("claimInfo.titleRemittanceAddressId");
      register(
        { name: "claimInfo.titleRemittanceAddressId" },
        {
          required: false
        }
      );
      register(
        { name: "claimInfo.newTitleRemittanceAddress.streetAddress" },
        { required: true }
      );
      register({ name: "claimInfo.newTitleRemittanceAddress.streetAddress2" });
      register(
        { name: "claimInfo.newTitleRemittanceAddress.city" },
        { required: true }
      );
      register(
        { name: "claimInfo.newTitleRemittanceAddress.stateId" },
        { required: true }
      );
      register(
        { name: "claimInfo.newTitleRemittanceAddress.zipCode" },
        { required: true }
      );
    }
  }, [showNewAddress, unregister, register]);

  useEffect(() => {
    register(
      { name: "claimInfo.titleRemittanceAddressId" },
      { required: true }
    );
  }, [register]);

  useEffect(() => {
    const country = fetchCountriesAndStates().countries?.find(
      country => country.countryName === "United States"
    );
    setSelectedCountry(country?.countryId);
    setStateOptions(
      fetchCountriesAndStates()
        .states?.filter(s => country.countryId === s.countryId)
        .map(state => ({ value: state.stateId, label: state.stateName }))
    );
  }, []);

  const onSelect = useCallback(
    name => option => {
      setValue(name, option.value);
    },
    [setValue]
  );

  useEffect(() => {
    const options = [];

    for (const address of carrier.titleRemittanceAddresses) {
      let label;

      if (address.name) {
        label = `${address.name} - ${address.streetAddress}${
          address.streetAddress2 ? ` ${address.streetAddress2}` : ""
        }, ${address.city}`;
      } else {
        label = `${address.streetAddress} ${
          address.streetAddress2 ? ` ${address.streetAddress2}` : ""
        }, ${address.city}`;
      }

      options.push({
        value: address.titleRemittanceAddressId,
        label
      });
    }

    if (options.length === 1) {
      onSelect("claimInfo.titleRemittanceAddressId")({
        value: options[0].value
      });
    }

    setRemittanceOptions(
      carrier?.canAddNewTitleRemittanceAddress
        ? [...options, { value: null, label: "(+) Add a new address" }]
        : options
    );
  }, [carrier, onSelect]);

  useEffect(() => {
    if (packet && packet.titleRemittanceAddressId) {
      setValue(
        "claimInfo.titleRemittanceAddressId",
        packet.titleRemittanceAddressId
      );
      setSelectedAddress({
        value: packet.titleRemittanceAddressId,
        label: packet.titleRemittanceAddress
      });
    }
  }, [packet, setValue]);

  const onAddressSelect = () => (val, { action }) => {
    if (action === "select-option" || action === "set-value") {
      setSelectedAddress({ value: val.value, label: val.label });
      setValue("claimInfo.titleRemittanceAddressId", val.value);
      setShowNewAddress(!val.value);
    } else if (action === "clear") {
      setSelectedAddress(null);
      setValue("claimInfo.titleRemittanceAddressId", null);
    }
  };

  const selectState = () => (val, { action }) => {
    if (action === "select-option" || action === "set-value") {
      setValue("claimInfo.newTitleRemittanceAddress.stateId", val.value);
    } else if (action === "clear") {
      setValue("claimInfo.newTitleRemittanceAddress.stateId", null);
    }
  };

  const selectCountry = () => (val, { action }) => {
    if (action === "select-option" || action === "set-value") {
      if (selectCountry !== val.value) {
        setValue("claimInfo.newTitleRemittanceAddress.stateId", null);
        setSelectedCountry(val.value);
        const newStateOptions = fetchCountriesAndStates()
          .states.filter(state => state.countryId === val.value)
          .map(state => ({
            value: state.stateId,
            label: state.stateName,
            countryId: state.countryId
          }));

        setStateOptions(newStateOptions);
      }
    }
  };

  const formatOptionLabel = ({ value, label }) => {
    if (value === null && label === "(+) Add a new address")
      return <div className="AddNewAddress">{label}</div>;
    return <div>{label}</div>;
  };

  return (
    <div data-testid="TitleRemittanceAddressId" className="search-select">
      <div className="AddressSelect">
        <SearchSelect
          name="claimInfo.titleRemittanceAddressId"
          options={remittanceOptions}
          error={claimErrors?.titleRemittanceAddressId}
          defaultValue={
            packet &&
            packet.titleRemittanceAddressId &&
            packet.titleRemittanceAddress
              ? {
                  value: packet.titleRemittanceAddressId,
                  label: packet.titleRemittanceAddress
                }
              : remittanceOptions.length === 1
              ? remittanceOptions[0]
              : null
          }
          value={selectedAddress}
          placeholder="Title Remittance Address"
          onSelect={onAddressSelect}
          testId="TitleRemittanceAddressSelect"
          reactSelectProps={{
            formatOptionLabel
          }}
        />
      </div>

      {showNewAddress && (
        <>
          <FormInput
            type="text"
            placeholder="Street Address"
            name="claimInfo.newTitleRemittanceAddress.streetAddress"
            register={register}
            unregister={unregister}
            required
            error={claimErrors?.newTitleRemittanceAddress?.streetAddress}
          />
          <FormInput
            type="text"
            placeholder="Street Address 2"
            name="claimInfo.newTitleRemittanceAddress.streetAddress2"
            register={register}
            unregister={unregister}
            error={claimErrors?.newTitleRemittanceAddress?.streetAddress2}
          />
          <FormInput
            type="text"
            placeholder="City"
            name="claimInfo.newTitleRemittanceAddress.city"
            register={register}
            unregister={unregister}
            required
            error={claimErrors?.newTitleRemittanceAddress?.city}
          />
          <SearchSelect
            name="claimInfo.newTitleRemittanceAddress.stateId"
            label="State"
            onSelect={selectState}
            required
            options={stateOptions}
            testId="State"
            placeholder="Select State"
            register={register}
            value={
              stateField
                ? {
                    value: stateField,
                    label: fetchCountriesAndStates().states?.find(
                      state => state.stateId === stateField
                    )?.stateName
                  }
                : null
            }
            defaultValue={
              selectedAddress && stateOptions
                ? {
                    value: selectedAddress.stateId,
                    label: selectedAddress.state
                  }
                : null
            }
          />
          <SearchSelect
            name="country"
            label="Country"
            onSelect={selectCountry}
            options={fetchCountriesAndStates().countries?.map(country => ({
              value: country.countryId,
              label: country.countryName
            }))}
            value={
              selectedCountry
                ? {
                    value: selectedCountry,
                    label: fetchCountriesAndStates().countries?.find(
                      country => country.countryId === selectedCountry
                    )?.countryName
                  }
                : {
                    value: fetchCountriesAndStates().countries?.find(
                      country => country.countryName === "United States"
                    )?.countryId,
                    label: "United States"
                  }
            }
          />
          <FormInput
            type="text"
            placeholder="Zip Code"
            name="claimInfo.newTitleRemittanceAddress.zipCode"
            register={register}
            unregister={unregister}
            required
            error={claimErrors?.newTitleRemittanceAddress?.zipCode}
          />
        </>
      )}
    </div>
  );
};

export default RemittanceAddressSelect;
