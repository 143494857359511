import React, { useState } from "react";
import { Redirect } from "react-router-dom";

const SearchResultsMenu = ({ search = [], reset = () => {} }) => {
  const [packetRedirect, setPacketRedirect] = useState(null);

  const redirectToPacket = packetId => () => {
    setPacketRedirect(packetId);
    reset();
  };

  return (
    <div className="SearchResultsWrapper">
      {packetRedirect && <Redirect push to={`/claims/${packetRedirect}`} />}
      {search?.map(result => (
        <div key={result.packetId} className="SearchResultRow">
          <div
            className="SearchResultClaimNumber"
            onClick={redirectToPacket(result.packetId)}
          >
            {"#"}
            {result.claimNumber}
          </div>
          <div className="SearchResultVin">{result.vin}</div>
        </div>
      ))}
    </div>
  );
};

export default SearchResultsMenu;
