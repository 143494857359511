import { useState, useEffect } from "react";
import { getGenericMetabaseReportUrl } from "../services/users";

const useGenericMetabaseReportUrl = () => {
  const [genericReportUrl, setGenericReportUrl] = useState("");
  const [isGenericReportOk, setIsGenericReportOk] = useState(true);

  useEffect(() => {
    let isCancelled = false;

    getGenericMetabaseReportUrl().then(data => {
      if (isCancelled) return;

      setGenericReportUrl(data?.dashboardUrl);
      setIsGenericReportOk(data?.isDashboardHealthy);
    });

    return () => {
      isCancelled = true;
    };
  }, []);

  return [genericReportUrl, isGenericReportOk];
};

export default useGenericMetabaseReportUrl;
