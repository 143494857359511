import React, { useState } from "react";
import { Button, FormInput } from "@fastlane-llc/lossexpress-ui-kit";
import { useForm } from "react-hook-form";

import { acknowledgeDisclaimer } from "../../services/users";
import Footer from "../Footer/Footer";

import "./DisclaimerPage.css";
import { Redirect } from "react-router-dom";
import XLienSideWrapper from "../XLienSideWrapper";

const DisclaimerPage = () => {
  const [redirectToLogin, setRedirectToLogin] = useState(false);
  const [redirectToHome, setRedirectToHome] = useState(false);

  const { register, setValue, watch, handleSubmit } = useForm({
    mode: "onBlur"
  });

  const form = watch();

  const submitAcknowledgement = async () => {
    try {
      await acknowledgeDisclaimer();
      setRedirectToHome(true);
    } catch (ex) {
      setRedirectToLogin(true);
    }
  };

  return (
    <XLienSideWrapper>
      {redirectToHome && <Redirect to="/home" />}
      {redirectToLogin && <Redirect to="/login" />}

      <div className="LoginBox DisclaimerPageBox">
        <h1>Permission Disclaimer</h1>

        <p>
          Lien holders cannot disclose any customer's payoff information without
          the customer's consent. When I, the insurance representative, click on
          the button to request such information, I acknowledge and agree that I
          have received the customer's consent to obtain this payoff information
          from the lien holder.
        </p>

        <form onSubmit={handleSubmit(submitAcknowledgement)}>
          <FormInput
            type="checkbox"
            name="disclaimerAcknowledged"
            label="I acknowledge that I have read and agree to the above permission disclaimer."
            register={register}
            value={form.disclaimerAcknowledged}
            onChange={setValue}
          />

          <Button disabled={!form.disclaimerAcknowledged}>
            Submit Acknowledgement
          </Button>
        </form>
      </div>

      <Footer />
    </XLienSideWrapper>
  );
};

export default DisclaimerPage;
