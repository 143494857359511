import React, { useEffect, useState } from "react";
import Modal from "../../../Modal";
import {
  Button,
  FormInput,
  SearchSelect
} from "@fastlane-llc/lossexpress-ui-kit";
import { addUser as addUserAPI } from "../../../../services/users";
import "./AddUserModal.css";
import { getVendorConnectedCompanies } from "../../../../services/vendors";
import { useForm } from "react-hook-form";

const userRoleOptions = [
  { value: "User", label: "User" },
  { value: "Administrator", label: "Administrator" }
];

const removeEmptyKeys = object => {
  for (const key in object) {
    if (typeof object[key] === "object") {
      removeEmptyKeys(object[key]);
    }
    if (object[key] === null || object[key] === "") {
      delete object[key];
    }
  }
};

const AddUserModal = ({ onClose, setError, setSuccess }) => {
  const [carriers, setCarriers] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    errors,
    unregister,
    control
  } = useForm({
    mode: "onBlur"
  });

  useEffect(() => {
    register({ name: "userRole" }, { required: true });
    register({ name: "carrierId" }, { required: true });
  }, [register]);

  useEffect(() => {
    (async function() {
      const carriers = await getVendorConnectedCompanies();
      setCarriers(carriers);

      if (carriers.length === 1) {
        setValue("carrierId", carriers[0].carrierId);
      }
    })();
  }, [setValue]);

  const selectRole = () => (val, { action }) => {
    if (action === "select-option" || action === "set-value") {
      setValue("userRole", val.value);
    } else if (action === "clear") {
      setValue("userRole", null);
    }
  };

  const selectCarrier = () => (val, { action }) => {
    if (action === "select-option" || action === "set-value") {
      setValue("carrierId", val.value);
    } else if (action === "clear") {
      setValue("carrierId", null);
    }
  };

  const onSubmit = async data => {
    removeEmptyKeys(data);
    try {
      await addUserAPI(
        data.username,
        data.emailAddress,
        data.userRole,
        data.name,
        data.mailingStreetAddress,
        data.mailingCity,
        data.mailingState,
        data.mailingZipCode,
        data.mailingAdditionalInfo,
        data.phoneNumber,
        data.carrierId
      );
      setError("");
      setSuccess(
        `Successfully added user: ${data.name} (${data.emailAddress})`
      );
      closeModal();
    } catch (ex) {
      setError(
        "There was an error saving the user. Please try another username."
      );
      setSuccess("");
      closeModal();
    }
  };

  const closeModal = () => {
    onClose();
  };

  return (
    <Modal style={{ width: "30em" }} onClose={closeModal} title="Add User">
      <form onSubmit={handleSubmit(onSubmit)} className="FormWrapper">
        <FormInput
          error={errors.name}
          name="name"
          placeholder={"Name (First and Last)"}
          register={register}
          unregister={unregister}
          required
        />
        <FormInput
          error={errors.username}
          name="username"
          placeholder={"Username"}
          register={register}
          unregister={unregister}
          required
        />
        <FormInput
          error={errors.emailAddress}
          name="emailAddress"
          placeholder="Email Address"
          required
          register={register}
          unregister={unregister}
        />
        <FormInput
          name="phoneNumber"
          placeholder="Phone Number"
          error={errors.phoneNumber}
          required
          format="phone"
          control={control}
          formatOnChange
          useFormatPattern
        />
        <SearchSelect
          placeholder="User Role"
          name="userRole"
          onSelect={selectRole}
          options={userRoleOptions}
          error={errors.userRole}
          testId={"SelectDropdown"}
        />
        {carriers.length > 1 && (
          <SearchSelect
            name="carrierId"
            placeholder="Carrier"
            onSelect={selectCarrier}
            options={carriers.map(carrier => ({
              value: carrier.carrierId,
              label: carrier.name
            }))}
            error={errors.carrierId}
          />
        )}
        <p>
          You can optionally add a specific remittance address for this user if
          your process requires it.
        </p>
        <FormInput
          placeholder="Street Address"
          name="mailingStreetAddress"
          type="text"
          register={register}
          unregister={unregister}
        />
        <FormInput
          placeholder="City"
          name="mailingCity"
          type="text"
          register={register}
          unregister={unregister}
        />
        <FormInput
          placeholder="State"
          name="mailingState"
          type="text"
          register={register}
          unregister={unregister}
        />
        <FormInput
          placeholder="Zip Code"
          name="mailingZipCode"
          type="text"
          register={register}
          unregister={unregister}
        />
        <FormInput
          placeholder="Add'l Remittance Information (Suite, etc.)"
          name="mailingAdditionalInfo"
          type="text"
          register={register}
          unregister={unregister}
        />
        <Button>Add User</Button>
      </form>
    </Modal>
  );
};

export default AddUserModal;
