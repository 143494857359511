import React from "react";

import "./Success.css";

const Success = ({ children }) => (
  <div className="Success">
    <p>
      <i className="fas fa-check" /> {children}
    </p>
  </div>
);

export default Success;
